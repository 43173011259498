import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import {
  Text,
  Flex,
  Group,
  Tabs,
  Paper,
  Col,
  Drawer,
  Space,
  Input,
  Select,
  rem,
  Button,
} from "@mantine/core";
import { CalendarBlank, Dot } from "@phosphor-icons/react";
import TransactionsCard from "./TransactionsCard";
import SeasonsDetailWidget from "./SeasonsDetailWidget";
import { useDisclosure } from "@mantine/hooks";
import {
  DateInput,
  DatePicker,
  DatesProvider,
  type DatesRangeValue,
} from "@mantine/dates";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import { RootState } from "../../connector/ModuleStore";
import { useSelector } from "react-redux";

import {
  fetchFieldInfo,
  fetchSeasonNoteInfo,
  updateChangeSeasonInfo,
} from "../../interactor/FieldDetailInteractor";
import {
  GetFieldDetailInfoResponse,
  SeasonNote,
  Seasons,
} from "../../data/request/FieldDetailRequests";
import { CultivationService } from "../../../myfields/interactor/CultivationInteractor";
import { CultivationRequest } from "../../../myfields/data/request/CultivationRequest";

import { PlantService } from "../../../myfields/interactor/PlantInteractor";
import { PlantRequest } from "../../../myfields/data/request/PlantRequest";
import { PlantVariantService } from "../../../myfields/interactor/PlantVariantInteractor";
import { PlantVariantRequest } from "../../../myfields/data/request/PlantVariantRequest";
import { update } from "lodash";
import { FieldInfoResponse } from "../../data/response/FieldDetailResponses";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

interface AccountProps {
  store?: GetFieldDetailInfoResponse;
  seasonId?: string;
}

function FieldDetailHeader({ store, seasonId }: AccountProps) {
  const dispatch = useAppDispatch();

  const getSeasonCloseState = useAppSelector(
    (state) => state.updateSeasonState.value
  );
  const [queryParams] = useSearchParams();
  const queryParam = queryParams.get("id");

  useEffect(() => {
    if (getSeasonCloseState?.success === true && queryParam !== null) {
      toast.success("Sezon Düzenlendi");
      dispatch(fetchFieldInfo({ id: queryParam }));
    }
  }, [getSeasonCloseState]);

  const [notes, setNotes] = useState<SeasonNote[]>();
  useEffect(() => {
    setNotes(store?.field.seasons.find((x) => x.id === seasonId)?.seasonNotes);
  }, [seasonId]);

  const getFieldInfoState = useAppSelector(
    (state) => state.getFieldInfoState.value?.data
  );

  const getSeasonNameState = useAppSelector(
    (state) =>
      state.getFieldInfoState.value?.data?.field.seasons.at(0)?.seasonName
  );

  const getSeasonHarvestDateState = useAppSelector(
    (state) =>
      state.getFieldInfoState.value?.data?.field.seasons.at(0)?.harvestDate
  );

  const getSeasonPlantDateState = useAppSelector(
    (state) =>
      state.getFieldInfoState.value?.data?.field.seasons.at(0)?.plantingDate
  );

  const cultivationService = useSelector(
    (state: RootState) => state.cultivationState.value
  );

  const plantService = useSelector(
    (state: RootState) => state.plantState.value
  );

  const plantData = plantService?.data?.plants;

  const plantTypeService = useSelector(
    (state: RootState) => state.plantTypeState.value
  );
  const plantTypeData = plantTypeService?.data?.plantVariants;

  const handlePlantTypeName = (value: string | null) => {
    if (plantType != null) {
      const selectedPlantType = plantTypeData?.find(
        (plantVariant) => plantVariant.id === plantType
      );
      if (selectedPlantType) {
        setPlantTypeName(selectedPlantType.name);
      }
    }
  };

  const [cultivationArea, setCultivationArea] = useState<string | null>(null);
  const [cultivationAreaName, setCultivationAreaName] = useState("");

  const [seasonUpdateData, setSeasonUpdateData] = useState<any>();
  const [plantName, setPlantName] = useState("");
  const [plantTypeName, setPlantTypeName] = useState("");

  const cultivationData = cultivationService?.data?.cultivationAreas;
  const [plant, setPlant] = useState<string | null>(null);
  const [plantType, setPlantType] = useState<string | null>(null);
  const [isPlantVisible, setIsPlantVisible] = useState(false);
  const [isPlantTypeVisible, setIsPlantTypeVisible] = useState(false);

  const [fieldName, setFieldName] = useState("");
  const [supplier, setSupplier] = useState<string | null>(null);
  const [agriculturalEngineer, setAgriculturalEngineer] = useState<
    string | null
  >(null);
  const [pH, setpH] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [district, setDistrict] = useState<string | null>(null);
  const [neighbourhood, setNeighbourhood] = useState<string | null>(null);
  const [cultivationAreaId, setCultivationAreaId] = useState<string>();
  const [plantId, setPlantId] = useState<string>();
  const [parcel, setParcel] = useState<number | null>(null);
  const today = new Date();
  const formattedToday = today.toLocaleDateString("tr-TR");
  const [opened, { open, close }] = useDisclosure(false);
  const [dateToPlant, setDateToPlant] = useState<Date | null>(null);
  const [dateToHarvest, setDateToHarvest] = useState<Date | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<null | Date>(null);
  const [editableSeasonName, setEditableSeasonName] = useState(
    store?.field.seasons.find((x) => x.id === seasonId)?.seasonName
  );
  const handleSeasonNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditableSeasonName(event.target.value);
  };

  const handlePlaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableSeasonName(event.target.value);
  };

  const handlePlants = (selectedCultivationId: string | null | undefined) => {
    if (selectedCultivationId)
      dispatch(PlantService(new PlantRequest(parseInt(selectedCultivationId))));
  };

  const handleCultivation = () => {
    setPlant(null);
    setPlantType(null);
    setIsPlantTypeVisible(false);
    setIsPlantVisible(false);
    dispatch(CultivationService(new CultivationRequest()));
  };

  const handlePlantVariants = (value: string | null) => {
    if (value != null) {
      dispatch(PlantVariantService(new PlantVariantRequest(value)));
    }
  };

  const handlePlantName = (value: string | null) => {
    if (value != null) {
      const selectedPlant = plantData?.find((plant) => plant.id === value);
      if (selectedPlant) {
        setPlantName(selectedPlant.name);
      }
    }
  };

  const handleCultivationAreaName = (value: string | null) => {
    if (value != null) {
      const selectedCultivation = cultivationData?.find(
        (cultivation) => cultivation.id === value
      );
      if (selectedCultivation) {
        setCultivationAreaName(selectedCultivation.name);
      }
    }
  };

  const handleSeasonDrawerOpen = () => {
    let seasondrawername = store?.field.seasons.find(
      (x) => x.id === seasonId?.valueOf()
    );
    if (seasondrawername) {
      setSeasonUpdateData(seasondrawername);
      setCultivationAreaId(seasondrawername.cultivationAreaId);
      setPlantId(seasondrawername.plantId);
    }
    open();
  };

  useEffect(() => {
    if (cultivationData && cultivationAreaId)
      handlePlants(
        cultivationData
          ?.find((x) => x.id.toString() === cultivationAreaId)
          ?.areaId.toString() ?? ""
      );
  }, [cultivationData, cultivationAreaId]);

  useEffect(() => {
    if (plantData && plantId)
      handlePlantVariants(
        plantData?.find((x) => x.id.toString() === plantId)?.id.toString() ?? ""
      );
  }, [plantData, plantId]);

  useEffect(() => {
    handleCultivation();
  }, []);

  useEffect(() => {
    if (plantTypeData != null) {
      setIsPlantTypeVisible(true);
    }
  }, [plantTypeData]);

  useEffect(() => {
    if (plant != null) {
      setIsPlantTypeVisible(true);
    }
  }, [plantTypeData]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(false);
  };

  const harvestDate = store?.field.seasons.find(
    (x) => x.id === seasonId
  )?.harvestDate;

  let formattedHarvestDate;
  if (harvestDate) {
    formattedHarvestDate = new Date(harvestDate).toLocaleDateString("tr-TR");
  } else {
    <Dot size={24} />;
  }

  const plantDate = store?.field.seasons.find(
    (x) => x.id === seasonId
  )?.plantingDate;

  let formattedPlantDate;
  if (plantDate) {
    formattedPlantDate = new Date(plantDate).toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else {
    <Dot size={24} />;
  }



  const form = useForm({
    initialValues: {
      productType: "",
      dateToPlant: "",
      dateToHarvest: "",
    },
    validate: {
      productType: (value) => (value ? null : "Ürün tipi seçilmelidir"),
      dateToPlant: (value) => (value ? null : "Ekim tarihi seçilmelidir"),
      dateToHarvest: (value) => (value ? null : "Hasat tarihi seçilmelidir"),
    },
  });

  const handleAcceptCloseSeason = () => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Kapat!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleCloseSeason();
      }
    });
  };

  const handleCloseSeason = () => {
    let seasonForClose = store?.field.seasons.find((x) => x.id === seasonId);
    dispatch(updateChangeSeasonInfo({ ...seasonForClose, seasonClosed: true }));
  };

  const handleEditSeasonWarningOpen = () => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Güncelle!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleEditSeason(false);
      }
    });
  };

  const handleEditSeasonWarningClose = () => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Düzenle!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleEditSeason(true);
      }
    });
  };

  const handleEditSeason = (isClosed: boolean) => {
    dispatch(
      updateChangeSeasonInfo({ ...seasonUpdateData, seasonClosed: isClosed })
    );
    close();
  };

  // const handleDelete = (id: string) => {
  //   dispatch(DeleteEmployeeService(id)).then((response) => {
  //     if (response !== undefined) {
  //       const payload = response.payload as DeleteEmployeeResponse | undefined;
  //       if (payload?.success) {
  //         toast.success("Kullanıcı Silindi");
  //         dispatch(EmployeeService());
  //         setIsDeleteFetching(false);
  //       } else {
  //         toast.error(payload?.userMessage || "Kullanıcı Silinemedi");
  //         setIsDeleteFetching(false);
  //       }
  //     }
  //   });
  // };

  return (
    <Paper
      shadow="md"
      p={20}
      h={rem("125%")}
      radius={rem(24)}
      className="widgets"
      bg="white"
      style={{
        borderTopLeftRadius: "23px",
        borderTopRightRadius: "23px",
      }}
    >
      <Flex direction="column" gap="md">
        <Flex justify="space-between">
          <Group>
            <Text
              fs="xl"
              style={{
                fontSize: "24px",
                fontFamily: "Poppins-Light",
                color: "#000000",
              }}
            >
              {store?.field.seasons.find((x) => x.id === seasonId)?.seasonName}
            </Text>
          </Group>
          <Group>
            {store?.field.seasons.find((x) => x.id === seasonId)
              ?.seasonClosed === false ? (
              <Button
                onClick={handleAcceptCloseSeason}
                style={{
                  paddingBottom: "11.5px",
                  paddingTop: "11.5px",
                  paddingLeft: "32.5px",
                  paddingRight: "32.5px",
                  borderRadius: 32,
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  border: "0.5px solid #000000",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
              >
                Sezonu Kapat
              </Button>
            ) : null}

            <Drawer.Root opened={opened} onClose={close} position="right">
              <Drawer.Overlay />
              <Drawer.Content
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  width: 1000,
                }}
              >
                <Drawer.Header>
                  <Drawer.Title
                    style={{ font: "Poppins-Light", fontSize: "28px" }}
                  >
                    Sezon Düzenle
                  </Drawer.Title>
                  <Drawer.CloseButton />
                </Drawer.Header>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Sezon İsmi
                  </Text>
                  <Input
                    placeholder="Sezon adını girin"
                    value={seasonUpdateData?.seasonName}
                    onChange={(e) => {
                      setSeasonUpdateData({
                        ...seasonUpdateData,
                        seasonName: e.currentTarget.value,
                      });
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Yetiştirilme Yeri
                  </Text>
                  <Select
                    placeholder="Yetiştirilme Alanını Seçin"
                    data={
                      cultivationData?.map((cultivation) => ({
                        value: cultivation.areaId.toString(),
                        label: cultivation.name,
                      })) || []
                    }
                    {...form.getInputProps("place")}
                    onChange={(value) => {
                      handlePlants(value);
                      setSeasonUpdateData({
                        ...seasonUpdateData,
                        cultivationAreaId:
                          cultivationData?.find(
                            (x) => x.areaId.toString() === value
                          )?.id ?? "",
                        plantId: undefined,
                      });
                    }}
                    value={
                      cultivationData
                        ?.find(
                          (x) => x.id === seasonUpdateData?.cultivationAreaId
                        )
                        ?.areaId.toString() ?? ""
                    }
                    disabled
                  />
                </Col>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Ürün
                  </Text>
                  <Select
                    placeholder="Ürün Seçin"
                    disabled
                    data={
                      plantData?.map((plant) => ({
                        value: plant.id,
                        label: plant.name,
                      })) || []
                    }
                    {...form.getInputProps("product")}
                    onChange={(value) => {
                      handlePlantVariants(value);
                      setSeasonUpdateData({
                        ...seasonUpdateData,
                        plantId: value,
                        plantVariantId: undefined,
                      });
                    }}
                    value={seasonUpdateData?.plantId}
                  />
                </Col>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Ürün Çeşidi
                  </Text>
                  <Select
                    placeholder="Ürün Tipini Seçin"
                    disabled
                    data={
                      plantTypeData?.map((plantType) => ({
                        value: plantType.id,
                        label: plantType.name,
                      })) || []
                    }
                    {...form.getInputProps("productType")}
                    onChange={(value) => {
                      setSeasonUpdateData({
                        ...seasonUpdateData,

                        plantVariantId: value ?? "",
                      });
                    }}
                    value={seasonUpdateData?.plantVariantId}
                  />
                </Col>

                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    Ekim Dikim Tarihi
                  </Text>
                  <DatesProvider
                    settings={{
                      locale: dayjs.locale("tr"),
                      firstDayOfWeek: 1,
                      weekendDays: [0, 6],
                    }}
                  >
                    <DateInput
                      minDate={dayjs().subtract(100, "year").toDate()}
                      maxDate={dayjs().add(1, "year").toDate()}
                      valueFormat="DD.MM.YYYY"
                      rightSection={<CalendarBlank size={16} color="#6c757d" />}
                      placeholder="Ekim Dikim Tarihi Girin"
                      value={
                        seasonUpdateData?.plantingDate
                          ? new Date(seasonUpdateData?.plantingDate)
                          : null
                      }
                      onChange={(e) => {
                        const dateValue = e
                          ? dayjs(e).add(3, "hour").toDate()
                          : new Date();
                        const minDate = dayjs().subtract(100, "year").toDate();
                        const maxDate = dayjs().add(1, "year").toDate();

                        if (dateValue) {
                          if (dateValue >= minDate && dateValue <= maxDate) {
                            setDateToPlant(e);
                            form.getInputProps("plantingDate").onChange(e);
                            setSeasonUpdateData({
                              ...seasonUpdateData,
                              plantingDate: dateValue,
                            });
                          }
                        }
                      }}
                    />
                  </DatesProvider>
                </Col>

                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Hasat Tarihi
                  </Text>
                  <DatesProvider
                    settings={{
                      locale: dayjs.locale("tr"),
                      firstDayOfWeek: 1,
                      weekendDays: [0, 6],
                    }}
                  >
                    <DateInput
                      valueFormat="DD.MM.YYYY"
                      rightSection={<CalendarBlank size={16} color="#6c757d" />}
                      placeholder="Hasat Tarihi Girin"
                      minDate={
                        dayjs(dateToPlant).diff(dayjs(), "day") >= 0
                          ? new Date(dateToPlant ? dateToPlant : "")
                          : dayjs().toDate()
                      }
                      maxDate={
                        dayjs(dateToPlant).isAfter(dayjs())
                          ? dayjs(dateToPlant).add(1, "year").toDate()
                          : dayjs().add(1, "year").toDate()
                      }
                      value={
                        seasonUpdateData?.harvestDate
                          ? new Date(seasonUpdateData?.harvestDate)
                          : null
                      }
                      onChange={(e) => {
                        const newDate = dayjs(e).add(3, "hour").toDate();
                        setSeasonUpdateData({
                          ...seasonUpdateData,
                          harvestDate: newDate,
                        });
                      }}
                    />
                  </DatesProvider>
                  {isDatePickerOpen && (
                    <DatePicker
                      value={selectedDate}
                      onChange={handleDateChange}
                      maxDate={new Date()}
                    />
                  )}

                  <Space h="20px" />
                  <Col>
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "100%",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={handleEditSeasonWarningOpen}
                    >
                      Güncelle
                    </Button>

                    <Space h="20px" />

                    <Button
                      onClick={handleEditSeasonWarningClose}
                      style={{
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "100%",
                        borderRadius: 32,
                        backgroundColor: "#FFFFFF",
                        color: "#000000",
                        border: "0.5px solid #000000",
                        fontFamily: "Poppins-Light",
                        fontSize: "12px",
                      }}
                    >
                      Sezonu Kapat
                    </Button>
                  </Col>
                </Col>
              </Drawer.Content>
            </Drawer.Root>

            {store?.field.seasons.find((x) => x.id === seasonId)
              ?.seasonClosed === false ? (
              <Button
                onClick={handleSeasonDrawerOpen}
                style={{
                  paddingBottom: "11.5px",
                  paddingTop: "11.5px",
                  paddingLeft: "32.5px",
                  paddingRight: "32.5px",
                  borderRadius: 32,
                  backgroundColor: "#0097c4",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
              >
                Sezonu Düzenle
              </Button>
            ) : null}
          </Group>
        </Flex>

        <Flex direction={{ base: "column", md: "row" }} wrap="wrap">
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Medium",
              color: "#136583",
              marginLeft: "4px",
            }}
          >
            Yetiştirilme Alanı:
          </Text>
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Light",
              marginLeft: "4px",
            }}
          >
            {
              store?.field.seasons.find((x) => x.id === seasonId)
                ?.cultivationAreaName
            }
          </Text>
          <Dot size={24} />
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Medium",
              color: "#136583",
              marginLeft: "4px",
            }}
          >
            Ürün:
          </Text>
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Light",
              marginLeft: "4px",
            }}
          >
            {store?.field.seasons.find((x) => x.id === seasonId)?.plantName}
          </Text>
          <Dot size={24} />
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Medium",
              color: "#136583",
              marginLeft: "4px",
            }}
          >
            Ürün Tipi:
          </Text>
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Light",
              marginLeft: "4px",
            }}
          >
            {
              store?.field.seasons.find((x) => x.id === seasonId)
                ?.plantVariantName
            }
          </Text>
          <Dot size={24} />
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Medium",
              color: "#136583",
              marginLeft: "4px",
            }}
          >
            Ekim ve Dikim Tarihi:
          </Text>

          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Light",
              marginLeft: "4px",
            }}
          >
            {formattedPlantDate}
          </Text>
          <Dot size={24} />
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Medium",
              color: "#136583",
              marginLeft: "4px",
            }}
          >
            Hasat Tarihi:
          </Text>
          <Text
            style={{
              fontSize: "14px",
              fontFamily: "Poppins-Light",
              marginLeft: "4px",
            }}
          >
            {formattedHarvestDate}
          </Text>
          <Dot size={24} />
        </Flex>

        <Tabs
          color="#eef0f8"
          variant="pills"
          defaultValue="first"
          className="custom-tabs"
          style={{ overflow: "none" }}
        >
          <Tabs.List
            style={{
              backgroundColor: "#eef0f8",
              padding: "10px 20px",
              borderRadius: rem(32),
            }}
            grow
            position="apart"
          >
            <Tabs.Tab value="first">İşlemler</Tabs.Tab>
            <Tabs.Tab value="second">Toprak Analiz Bilgileri</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="first">
            <TransactionsCard store={getFieldInfoState} seasonId={seasonId} />
          </Tabs.Panel>
          <Tabs.Panel value="second">
            <SeasonsDetailWidget
              store={getFieldInfoState}
              seasonId={seasonId}
            />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Paper>
  );
}

export default FieldDetailHeader;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { CityResponse } from "../data/response/CityResponse";

export const CityService = createAsyncThunk("cities/getCities", async () => {
  try {
    const response = await AxiosHelper.getInstance().get<CityResponse>(
      "parameter/cities"
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

const initialState: ServiceState<CityResponse> = {};

const CitiesSlice = createSlice({
  name: "cities",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CityService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CityService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CityService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default CitiesSlice.reducer;

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import CorporateDasboard from "../presentation/CorporateDashboard";

export default function CorporateDashboardRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/corporatedashboard" element={<CorporateDasboard />} />
      </Routes>
    </Provider>
  );
}

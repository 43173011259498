import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import { ServiceState } from "../../common/data/ServiceState";
import { NotificationRequest } from "../data/request/NotificationRequest";
import { NotificationResponse } from "../data/response/NotificationResponse";
import { NotificationUpdateRequest } from "../data/request/NotificationUpdateRequest";

// Async Thunk action creator
export const notificationIsReadUpdate = createAsyncThunk(
    "corporate/inboxUpate",
  async (request: NotificationUpdateRequest, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<NotificationResponse>(
          `corporate/inbox/` + request.id + "?CorporateInboxId=" + request.id + "&CorporateInboxStatus=" + request.corporateInboxStatus
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<NotificationResponse> = {};

export const getNotificationsUpdateSlice = createSlice({
  name: "corporate/inboxUpate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notificationIsReadUpdate.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(notificationIsReadUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(notificationIsReadUpdate.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default getNotificationsUpdateSlice.reducer;

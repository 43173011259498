import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import MyFieldsDetail from "../presentation/view/MyFieldsDetail";

export default function FieldDetailRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/myFields/detail" element={<MyFieldsDetail />} />
      </Routes>
    </Provider>
  );
}
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LoginInteractor from "../interactor/LoginInteractor";
import OTPInteractor from "../interactor/OTPInteractor";
import { ModuleMiddleware } from "./ModuleMiddleware";
import RefreshTokenInteractor from "../interactor/RefreshTokenInteractor";
import { AgreementFetchSlice, AgreementAcceptSlice } from "../interactor/AgreementInteractor";

const rootReducer = combineReducers({
  loginState: LoginInteractor,
  otpState: OTPInteractor,
  refreshTokenState: RefreshTokenInteractor,
  agreementFetchState: AgreementFetchSlice.reducer,
  agreementAcceptState: AgreementAcceptSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;

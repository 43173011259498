import React, { useEffect, useState } from "react";
import { Tabs, Paper, rem, Button, Box } from "@mantine/core";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useNavigate, useParams } from "react-router-dom";
import AddTaskCard from "./AddTaskCard";
import { IconArrowLeft } from "@tabler/icons-react";

const AddTaskForm: React.FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    if (id) {
      console.log(id);
      setShouldUpdate(true);
    }
  }, [id]);

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Görev Listesi", href: "/task/list" },
        { title: shouldUpdate ? "Görev Düzenle" : "Görev Ekle", href: "#" },
      ]}
      title={shouldUpdate ? "Görev Düzenle" : "Görev Ekle"}
    >
      <>
        <Box
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="outline"
            color="#000000"
            fullWidth
            style={{
              color: "#FFF",
              borderColor: "#000000",
              borderRadius: "2rem",
              fontWeight: 300,
              width: "100px",
              backgroundColor: "red",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconArrowLeft /> Geri
          </Button>
        </Box>
        <Paper
          shadow="md"
          p={20}
          className="widgets"
          bg="white"
          style={{
            borderTopLeftRadius: "23px",
            borderTopRightRadius: "23px",
          }}
        >
          <Tabs color="#eef0f8" variant="pills" defaultValue="first">
            <Tabs.List
              style={{
                backgroundColor: "#eef0f8",
                paddingTop: "10px",
                paddingBottom: "10px",
                height: "60px",
                display: "flex",
                borderRadius: "24px",
                justifyContent: "space-around",
              }}
            >
              <Tabs.Tab
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  paddingLeft: "101px",
                  paddingRight: "101px",
                  borderRadius: "24px",
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  // Seçili tabın arka plan rengi
                }}
                value="first"
              >
                {shouldUpdate ? "Görev Düzenle" : "Görev Ekle"}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
              <AddTaskCard />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </>
    </MainWrapper>
  );
};

export default AddTaskForm;

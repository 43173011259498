import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import {
	fetchAgreement,
	acceptAgreement,
} from "../../interactor/AgreementInteractor";
import {
	AgreementFetchRequest,
	AgreementAcceptRequest,
} from "../../data/request/AgreementRequest";
import {
	Container,
	Button,
	Text,
	Loader,
	Flex,
	rem,
	Paper,
} from "@mantine/core";
import { useNavigate } from "react-router";
import { RootState } from "../../connector/ModuleStore";
import MainWrapperContract from "./MainWrapperContract";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const AgreementPage: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [numPages, setNumPages] = useState<number>();
	const fetchState = useAppSelector(
		(state: RootState) => state.agreementFetchState
	);
	const acceptState = useAppSelector(
		(state: RootState) => state.agreementAcceptState
	);
	const language = "tr";

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}

	useEffect(() => {
		dispatch(fetchAgreement(new AgreementFetchRequest(language)));
	}, [dispatch, language]);

	const handleAccept = () => {
		if (fetchState.value?.data?.id) {
			const agreementId = fetchState.value.data.id;
			dispatch(acceptAgreement(new AgreementAcceptRequest(agreementId)));
		}
	};

	useEffect(() => {
		if (acceptState.value?.success) {
			navigate("/myFields");
		}
	}, [acceptState, navigate]);

	if (fetchState.isLoading || acceptState.isLoading) {
		return (
			<Container
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<Loader />
			</Container>
		);
	}

	if (fetchState.error) {
		return (
			<Container style={{ padding: "0rem", textAlign: "center" }}>
				<Text color="red" size="lg">
					{(fetchState.error as any)?.message ||
						"Anlaşma getirilemedi."}
				</Text>
				<Button
					mt="md"
					style={{ backgroundColor: "#0097c4" }}
					onClick={() =>
						dispatch(
							fetchAgreement(new AgreementFetchRequest(language))
						)
					}
				>
					Yeniden Dene
				</Button>
			</Container>
		);
	}

	return (
		<MainWrapperContract breadCrumbs={[]} title="Sözleşme Onayı">
			<Container>
				{fetchState.value?.success && fetchState.value.data && (
					<Flex direction="column" align="center">
						<Document
							file={`data:application/pdf;base64,${fetchState.value.data.text}`}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									renderTextLayer={false}
									renderAnnotationLayer={false}
									scale={1.75}
								/>
							))}
						</Document>

						<Paper
							shadow="md"
							p="lg"
							radius="md"
							withBorder
							onClick={handleAccept}
							style={{
								position: "fixed",
								bottom: "5%",
								left: "55%",
								transform: "translateX(-50%)",
								width: "100%",
								maxWidth: rem(800),
								backgroundColor: "#0097c4",
								zIndex: 10,
								padding: rem(20),
								cursor: "pointer",
							}}
						>
							<Text
								align="center"
								color="white"
								size="lg"
								style={{ fontWeight: 500 }}
							>
								{acceptState.isLoading
									? "Kabul Ediliyor..."
									: "Sözleşmeyi okudum ve onaylıyorum"}
							</Text>
						</Paper>
					</Flex>
				)}

				{acceptState.error && (
					<Text color="red" size="lg" mt="md" align="center">
						{(acceptState.error as any)?.message ||
							"Anlaşma kabul edilemedi."}
					</Text>
				)}
			</Container>
		</MainWrapperContract>
	);
};

export default AgreementPage;

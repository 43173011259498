import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import { ServiceState } from "../../common/data/ServiceState";
import { LoginResponse } from "../data/response/LoginResponses";
import { LoginRequest, M2MRequest } from "../data/request/LoginRequests";

export const LoginService = createAsyncThunk(
  "login/service/login",
  async (request: LoginRequest, thunkAPI) => {
    try {
      var m2mRequest = new M2MRequest();
      const m2mResponse = await AxiosHelper.getInstance(
        true
      ).post<LoginResponse>("corporate/auth/m2m", m2mRequest);
      localStorage.setItem(
        "token",
        m2mResponse.data?.data?.token?.accessToken ?? ""
      );

      const response = await AxiosHelper.getInstance().post<LoginResponse>(
        "corporate/auth/login",
        request
      );
      localStorage.setItem(
        "token",
        response.data?.data?.token?.accessToken ?? ""
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<LoginResponse> = {};

const LoginSlice = createSlice({
  name: "login/login",
  initialState: initialState,
  reducers: {
    resetLogin: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(LoginService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(LoginService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const { resetLogin } = LoginSlice.actions;

export default LoginSlice.reducer;

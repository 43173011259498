import { LoadingOverlay } from "@mantine/core";
import React from "react";

const CustomLoadingOverlay: React.FC = () => {
  return (
    <LoadingOverlay
      loaderProps={{ size: "xl", color: "#0097C4", variant: "dots" }}
      overlayOpacity={0.8}
      visible
    />
  );
};

export default CustomLoadingOverlay;

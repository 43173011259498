import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  MediaQuery,
  Paper,
  Space,
  Switch,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import React, { Suspense, useEffect, useState } from "react";

import FieldModelDto from "../../../../app/common/data/dto/FieldModelDto";
import { fetchWeatherAccuWeatherData } from "../../interactor/WeatherInteractor";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import WeatherDetailCardDays from "./WeatherDetailCardDays";
import WeatherDetailDailyForecastCard from "./WeatherDetailDailyForecastCard";
import WeatherForecastHourDetailCard from "./WeatherForecastHourDetailCard";
import WeatherHourliesDetailCard from "./WeatherHourliesDetailCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import { fetchFieldInfo } from "../../../fielddetail/interactor/FieldDetailInteractor";

const FieldInfoCard = React.lazy(
  () => import("../../../fielddetail/presentation/view/FieldInfoCard")
);
const WeatherDetailCard = React.lazy(() => import("./WeatherDetailCard"));
const MainWrapper = React.lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);

const WeatherDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useMantineTheme();
  const [location, setLocation] = useState({ lat: 0, long: 0 });

  const [isModalOpenPlant, setIsModalOpenPlant] = useState(false);

  const [isDaily, setIsDaily] = useState(false);
  const [isHourly, setIsHourly] = useState(false);

  const [fav, setFav] = useState(localStorage.getItem("weatherFav") ?? "");

  const fieldState = useAppSelector(
    (state) => state.myFieldState.value?.data?.fields
  );

  const [queryParams] = useSearchParams();

  const queryParam = queryParams.get("fieldId");

  const [fetching, setFetching] = useState(true);
  useEffect(() => {
    const field = fieldState?.find((x) => x.id === queryParam);

    setLocation({
      lat: field?.address?.latitude ?? 0,
      long: field?.address?.longitude ?? 0,
    });
  }, [fieldState]);

  useEffect(() => {
    dispatch(MyFieldService()).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setFetching(false);
      }
    });
  }, []);

  const [opened, setOpened] = useState(false);
  const weatherAccuState = useAppSelector(
    (state) => state.weatherAccuState.value?.data?.days
  );
  const weatherApiState = useAppSelector(
    (state) => state.weatherApiState.value?.data?.days
  );
  const weatherBitState = useAppSelector(
    (state) => state.weatherBitState.value?.data?.weatherForecasts
  );
  const weatherTarlaIOOState = useAppSelector(
    (state) => state.weatherTarlaIOOState.value?.data?.dailyWeatherForecast
  );
  const weatherVisualCrossState = useAppSelector(
    (state) => state.weatherVisualCrossState.value?.data?.weatherForecasts
  );
  const isLoadingweatherAccuState = useAppSelector(
    (state) => state.weatherAccuState.isLoading
  );
  const isLoadingweatherApiState = useAppSelector(
    (state) => state.weatherApiState.isLoading
  );
  const isLoadingweatherBitState = useAppSelector(
    (state) => state.weatherBitState.isLoading
  );
  const isLoadingweatherTarlaIOOState = useAppSelector(
    (state) => state.weatherTarlaIOOState.isLoading
  );
  const isLoadingweatherVisualCrossState = useAppSelector(
    (state) => state.weatherVisualCrossState.isLoading
  );

  const weatherHourlyAccuState = useAppSelector(
    (state) => state.weatherHourlyAccuState.value?.data?.hourlies
  );
  const weatherHourlyApiState = useAppSelector(
    (state) => state.weatherHourlyApiState.value?.data?.weatherForecasts
  );
  const weatherHourlyBitState = useAppSelector(
    (state) => state.weatherHourlyBitState.value?.data?.weatherForecasts
  );
  const weatherHourlyTarlaIOOState = useAppSelector(
    (state) => state.weatherHourlyTarlaIOOState.value?.data?.weatherForecasts
  );
  const weatherHourlyVisualCrossState = useAppSelector(
    (state) => state.weatherHourlyVisualCrossState.value?.data?.weatherForecasts
  );
  const isLoadingweatherHourlyAccuState = useAppSelector(
    (state) => state.weatherHourlyAccuState.isLoading
  );
  const isLoadingweatherHourlyApiState = useAppSelector(
    (state) => state.weatherHourlyApiState.isLoading
  );
  const isLoadingweatherHourlyBitState = useAppSelector(
    (state) => state.weatherHourlyBitState.isLoading
  );
  const isLoadingweatherHourlyTarlaIOOState = useAppSelector(
    (state) => state.weatherHourlyTarlaIOOState.isLoading
  );
  const isLoadingweatherHourlyVisualCrossState = useAppSelector(
    (state) => state.weatherHourlyVisualCrossState.isLoading
  );

  const handleClick = () => {
    if (queryParam) {
      dispatch(fetchFieldInfo({ id: queryParam })).then(() => {
        navigate(`/myfields/detail?id=${queryParam}`);
      });
    } else {
      navigate(`/myfields/detail?id=${queryParam}`);
    }
  };

  const handleSave = () => {};

  // useEffect(() => {
  //   dispatch(
  //     fetchWeatherAccuWeatherData({ lat: 39, long: 32 })
  //   );
  // }, [isDaily]);

  const navigate = useNavigate();

  const handleFieldSelect = (value: string) => {
    navigate(`/myfields/detail?id=${queryParam}`);
  };

  useEffect(() => {
    if (queryParam) {
      dispatch(fetchFieldInfo({ id: queryParam }));
    }
  }, []);

  const getFieldInfoState = useAppSelector(
    (state) => state.getFieldInfoState.value?.data
  );

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[
          { title: "Tarlalarım", href: "/myFields" },
          {
            title: "Tarlalarım Detay",
            href: `/myFields/detail?id=${queryParam}`,
          },
          { title: "Hava Durumu Detay", href: "#" },
        ]}
        title="Hava Durumu"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Container fluid>
            <Grid>
              <Grid.Col sm={12} md={2} offsetMd={10}>
                <Button
                  onClick={handleClick}
                  style={{
                    backgroundColor: "#0097c4",
                    borderRadius: "32px",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "Poppins-Light",
                    marginTop: rem(40),
                  }}
                >
                  Tarla Detay
                </Button>
              </Grid.Col>
            </Grid>
          </Container>

          <Container fluid>
            <Grid>
              <Grid.Col span={12}>
                <FieldInfoCard store={getFieldInfoState} />
              </Grid.Col>
            </Grid>
          </Container>

          <Paper
            style={{ backgroundColor: "transparent" }}
            p="xl"
            className="profile-paper"
          >
            <Group
              p={"20px"}
              sx={{
                borderTopLeftRadius: "36px",
                borderTopRightRadius: "36px",
                backgroundColor: "#F8F8F8",
                display: "flex",
                justifyContent: "flex-end",
                // Diğer stiller
              }}
            >
              <Switch
                onChange={(e) => setIsDaily(e.currentTarget.checked)}
                checked={isDaily}
                style={{
                  borderRadius: 32,
                  backgroundColor: "#0097c4",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
              >
                Sezonu Düzenle
              </Switch>
              <Text
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-Light",
                  width: rem(200),
                  textAlign: "center",
                }}
                c={"#000000"}
              >
                Saatlik Hava Durumunu Göster
              </Text>

              <Space w="20px" />
            </Group>
            {isDaily ? (
              <>
                <WeatherForecastHourDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  sourceType="tarlaio"
                  store={weatherHourlyTarlaIOOState}
                  isLoading={isLoadingweatherHourlyTarlaIOOState}
                  lat={location.lat}
                  long={location.long}
                  fav={fav}
                />

                <WeatherForecastHourDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  sourceType="weatherapi"
                  store={weatherHourlyApiState}
                  isLoading={isLoadingweatherHourlyApiState}
                  lat={location.lat}
                  long={location.long}
                  fav={fav}
                />

                <WeatherForecastHourDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  sourceType="weatherbit"
                  store={weatherHourlyBitState}
                  isLoading={isLoadingweatherHourlyBitState}
                  lat={location.lat}
                  long={location.long}
                  fav={fav}
                />

                <WeatherForecastHourDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  sourceType="visualcross"
                  store={weatherHourlyVisualCrossState}
                  isLoading={isLoadingweatherHourlyVisualCrossState}
                  lat={location.lat}
                  long={location.long}
                  fav={fav}
                />

                <WeatherHourliesDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  sourceType="accuweather"
                  store={weatherHourlyAccuState}
                  isLoading={isLoadingweatherHourlyAccuState}
                  lat={location.lat}
                  long={location.long}
                  fav={fav}
                />
              </>
            ) : (
              <>
                <WeatherDetailDailyForecastCard
                  onFavChange={(fav: string) => setFav(fav)}
                  fav={fav}
                  sourceType="tarlaio"
                  store={weatherTarlaIOOState}
                  isLoading={isLoadingweatherTarlaIOOState}
                  lat={location.lat}
                  long={location.long}
                />

                <WeatherDetailCardDays
                  onFavChange={(fav: string) => setFav(fav)}
                  fav={fav}
                  sourceType="weatherapi"
                  store={weatherApiState}
                  isLoading={isLoadingweatherApiState}
                  lat={location.lat}
                  long={location.long}
                />

                <WeatherDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  fav={fav}
                  sourceType="weatherbit"
                  store={weatherBitState}
                  isLoading={isLoadingweatherBitState}
                  lat={location.lat}
                  long={location.long}
                />

                <WeatherDetailCard
                  onFavChange={(fav: string) => setFav(fav)}
                  fav={fav}
                  sourceType="visualcross"
                  store={weatherVisualCrossState}
                  isLoading={isLoadingweatherVisualCrossState}
                  lat={location.lat}
                  long={location.long}
                />

                <WeatherDetailCardDays
                  onFavChange={(fav: string) => setFav(fav)}
                  fav={fav}
                  sourceType="accuweather"
                  store={weatherAccuState}
                  isLoading={isLoadingweatherAccuState}
                  lat={location.lat}
                  long={location.long}
                />
              </>
            )}

            <Group
              p={"20px"}
              sx={{
                borderBottomRightRadius: "36px",
                borderBottomLeftRadius: "36px",
                backgroundColor: "#F8F8F8",
                display: "flex",
                justifyContent: "flex-end",
                // Diğer stiller
              }}
            ></Group>
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default WeatherDetail;

import { BaseRequest } from "../../../common/data/BaseRequest";

export class DistrictRequest extends BaseRequest {
  CityId: string;
  CacheKey: string | "";

  constructor(cityId: string, cacheKey: string) {
    super();
    this.CityId = cityId;
    this.CacheKey = cacheKey;
  }
}

import { Global } from "@mantine/core";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Poppins-Regular",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Regular.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Black",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Black.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-BlackItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-BlackItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Bold",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Bold.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-ExtraBoldItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-ExtraLight",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-ExtraLight.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-ExtraLightItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-ExtraLightItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Italic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Italic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Light",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Light.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-LightItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-LightItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Medium",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Medium.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-MediumItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-MediumItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Regular",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Regular.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-SemiBold",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-SemiBold.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-SemiBoldItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-SemiBoldItalic.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-Thin",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-Thin.ttf') format("openType")`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Poppins-ThinItalic",
            src: `url('${process.env.PUBLIC_URL}/assets/fonts/poppins/Poppins-ThinItalic.ttf') format("openType")`,
          },
        },
      ]}
    />
  );
}

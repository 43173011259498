import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ModuleMiddleware } from "./ModuleMiddleware";
import { GetCorporateDashboardSlice } from "../interactor/CorporateDashboardInteractor";
import CitiesSlice from "../../myfields/interactor/CityInteractor";
import DistrictsSlice from "../../myfields/interactor/DistrictInteractor";

const rootReducer = combineReducers({
  GetCorporateDashboardState: GetCorporateDashboardSlice.reducer,
  GetCitiesState: CitiesSlice,
  GetDistrictState: DistrictsSlice
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;

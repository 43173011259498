import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { AddFieldRequests } from "../data/request/AddFieldRequests";
import { AddFieldResponse } from "../data/response/AddFieldResponse";

export const AddFieldService = createAsyncThunk(
  "fields/addMyFields",
  async (request: AddFieldRequests) => {
    try {
      const response = await AxiosHelper.getInstance().post<AddFieldResponse>(
        "corporate/fields",
        request
      );
      return response.data;
    } catch (error) {
      console.error(error || "An unknown error occurred.");
    }
  }
);

const initialState: ServiceState<AddFieldResponse> = {};

const AddFieldSlice = createSlice({
  name: "addFields",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddFieldService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AddFieldService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AddFieldService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default AddFieldSlice.reducer;

import React, { useState } from "react";
import {
  Input,
  Text,
  Stack,
  Paper,
  Select,
  Grid,
  Flex,
  Group,
  Col,
  rem,
  Space,
  Button,
} from "@mantine/core";
import * as XLSX from "xlsx";
import { Info, MicrosoftExcelLogo, FileArrowDown } from "phosphor-react";
import { MyFieldsRequest } from "../../../../../../modules/fields/data/request/MyFieldsRequest";
import FieldModelDto from "../../../../data/dto/FieldModelDto";
import { UserInfo } from "../../../../../../modules/dashboard/user/presentation/view/makeUserData";
import MainWrapper from "../mainWrapper";

interface TransactionsProps {
  selectedAccount?: FieldModelDto;
}

const MultiAddFieldsCard: React.FC<TransactionsProps> = () => {
  
  

  const [data] = useState<UserInfo[]>([]);
  const [isData, setIsData] = useState(false);

  const [tableData, setTableData] = useState<UserInfo[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const workbook = XLSX.read(e?.target?.result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const excelData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          ) as UserInfo[];

          // Update the table data with the new Excel data
          setTableData(excelData);
        } catch (error) {
          console.error("Error reading Excel file:", error);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleExportToExcel = () => {
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  return (
    <Paper
      p={20}
      h={rem(500)}
      className="widgets"
      radius={rem(12)}
      bg="white"
    >
      <Stack dir="column" p={20}>
        <Flex justify="space-between" align="center">
          <Group>
            <Info size={18} />
            <Text
              fs="xl"
              style={{
                color: "#878787",
                fontSize: "12px",
                fontFamily: "Poppins-Light",
              }}
            >
              Lütfen öncelikle sağdaki buton yardımı ile excel formatını
              indirin.
            </Text>
          </Group>
          <Button
            style={{
              paddingBottom: "11.5px",
              paddingTop: "11.5px",
              paddingLeft: "100px",
              paddingRight: "100px",
              borderRadius: 32,
              backgroundColor: "#FFFFFF",
              color: "#000000",
              border: "0.5px solid #619628",
              fontFamily: "Poppins-Light",
              fontSize: "12px",
            }}
            onClick={handleExportToExcel}
          >
            <Flex align="center">
              <MicrosoftExcelLogo size={16} color="#000000" />
              <Space w="xs" />
              <Text
                style={{
                  color: "#000000",
                  fontSize: "12px",
                  fontFamily: "Poppins-Light",
                }}
              >
                Excel Formatı İndirin
              </Text>
            </Flex>
          </Button>
        </Flex>

        <Grid>
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
              height: "355px",
              border: "2px dashed #D8DAE2", // Kesik çizgili border stilini istediğiniz gibi özelleştirin
              padding: "20px", // İçeriğin kenarlardan uzaklığını ayarlayın
            }}
          >
            <FileArrowDown size={64} color="#000000" />

            <label
              htmlFor="fileInput"
              style={{
                marginTop: "20px",
                paddingBottom: "11.5px",
                paddingTop: "11.5px",
                paddingLeft: "32.5px",
                paddingRight: "32.5px",
                width: "335px",
                borderRadius: "32px",
                backgroundColor: "#0097c4",
                color: "#FFFFFF",
                fontFamily: "Poppins-Light",
                fontSize: "12px",
                textAlign: "center",
                cursor: "pointer", // Cursor'u pointer olarak ayarlayarak butona tıklanabilir olduğunu belirtiyoruz
              }}
            >
              Dosya Seç
              <Input
                id="fileInput"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                style={{
                  display: "none", // Dosya seçme butonunu gizliyoruz
                }}
              />
            </label>

            <Space h="md" />
            <Text
              fs="xl"
              style={{
                color: "#FF0000",
                fontSize: "12px",
                fontFamily: "Poppins-Light",
                textAlign: "center",
              }}
            >
              Lütfen öncelikle sağdaki buton yardımı ile excel formatını
              indirin.
            </Text>
          </Col>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default MultiAddFieldsCard;

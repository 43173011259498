import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { MyFieldResponse } from "../data/response/MyFieldResponses";

export const MyFieldService = createAsyncThunk(
  "fields/getMyFields",
  async () => {
    try {
      const response = await AxiosHelper.getInstance().get<MyFieldResponse>(
        "corporate/fields"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<MyFieldResponse> = {};

export const MyFieldSlice = createSlice({
  name: "myfields",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(MyFieldService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(MyFieldService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(MyFieldService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default MyFieldSlice.reducer;

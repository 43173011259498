import React, { useEffect, useState } from "react";
import {
  Tabs,
  Paper,
  rem,
  Stack,
  Grid,
  Col,
  Space,
  LoadingOverlay,
  Table,
  Button,
  Flex,
  Box,
} from "@mantine/core";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useNavigate, useParams } from "react-router-dom";
import AddTaskCard from "./AddTaskCard";
import {
  AssingFormService,
  CompleteFormTaskService,
  DeleteFormFieldEmployeeImageService,
  DetailFormTaskService,
  FormTaskLocationCheckingService,
} from "../../interractor/SurveyInterractor";
import { useAppDispatch } from "../../connector/ModuleHook";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import moment from "moment";
import { IconArrowLeft, IconSquareX } from "@tabler/icons-react";
import { FormTaskLocationCheckingRequest } from "../../data/request/FormTaskLocationCheckingRequest";
import Swal from "sweetalert2";
import { FormTaskLocationCheckingResponse } from "../../data/response/FormTaskLocationCheckingResponse";
import AxiosHelper from "../../../common/util/AxiosHelper";
import { toast } from "react-toastify";
import { CompleteFormTaskResponse } from "../../data/response/CompleteFormTaskResponse";
import { Field } from "../../../notifications/presentation/types/field";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import { DeleteFormFieldEmployeeImageRequest } from "../../data/request/DeleteFormFieldEmployeeImageRequest";

const fileTypes = ["JPG", "PNG", "GIF"];

const DetailMyTaskForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const [fileUrl1, setFileUrl1] = useState(null);
  const [fileUrl2, setFileUrl2] = useState(null);
  const [fileUrl3, setFileUrl3] = useState(null);
  const [fetching, setFetching] = useState(false);

  const [field, setField] = useState<any>(null);

  useEffect(() => {
    setFetching(true);
    if (id) {
      dispatch(AssingFormService(id));
    }
  }, [id]);

  const assingFormService = useSelector(
    (state: RootState) => state.AssingFormDetailState.value
  );

  const assingFormData = assingFormService?.data?.assingDto;

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  useEffect(() => {
    if (myFieldData && assingFormData) {
      setField(myFieldData.find((x) => x.id === assingFormData.fieldId));
    }
    if (!myFieldData) {
      dispatch(MyFieldService());
    }
  }, [myFieldData, assingFormData]);

  useEffect(() => {
    if (assingFormData) {
      setFetching(false);
    }
  }, [assingFormData]);

  useEffect(() => {
    if (fileUrl1) {
      fileUploadHandler(fileUrl1, 0);
    }
  }, [fileUrl1]);
  useEffect(() => {
    if (fileUrl2) {
      fileUploadHandler(fileUrl2, 1);
    }
  }, [fileUrl2]);
  useEffect(() => {
    if (fileUrl3) {
      fileUploadHandler(fileUrl3, 2);
    }
  }, [fileUrl3]);

  const fileUploadHandler = async (fileContent: any, seo: number) => {
    const formData = new FormData();
    formData.append("Media", fileContent);
    formData.append("Id", id ?? "");
    formData.append("Seo", seo.toString());
    console.log(seo);
    const response = await AxiosHelper.getInstance()
      .post<any>(`form/formtask/formTaskImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response !== undefined) {
          console.log(response);
          let responsePayload = response.data as any;
          if (responsePayload?.success) {
            toast.success("Dosya başarı ile yüklendi.");
            dispatch(AssingFormService(id ?? ""));
          } else {
            toast.error(responsePayload?.userMessage);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const locationCheckin = () => {
    let lat: number, long: number;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          lat = position.coords.latitude;
          long = position.coords.longitude;
          console.log(lat, long);

          let formTaskLocationCheckingRequest: FormTaskLocationCheckingRequest =
            {
              formFieldEmployeeId: id ?? "",
              latitude: lat!,
              longitude: long!,
            };
          setFetching(true);
          dispatch(
            FormTaskLocationCheckingService(formTaskLocationCheckingRequest)
          ).then((response) => {
            if (response !== undefined) {
              let responsePayload = response.payload as
                | FormTaskLocationCheckingResponse
                | undefined;
              if (responsePayload?.success) {
                Swal.fire({
                  title: "Konum kontrolü başarı ile gerçekleştirilmiştir.",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#0097c4",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Tamam",
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(AssingFormService(id ?? ""));
                  }
                });
                setFetching(false);
              } else {
                Swal.fire({
                  title: responsePayload?.userMessage,
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#0097c4",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Tamam",
                });
                setFetching(false);
              }
            }
          });
        },
        (error) => {
          Swal.fire({
            title: "Konum izni verilmedi veya konum alınamadı.",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#0097c4",
            cancelButtonColor: "#d33",
            confirmButtonText: "Tamam",
          });
          return;
        }
      );
    } else {
      Swal.fire({
        title: "Tarayıcınız konum hizmetlerini desteklemiyor.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#0097c4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tamam",
      });
      return;
    }
  };

  const completeTask = () => {
    setFetching(true);
    dispatch(CompleteFormTaskService({ formFieldEmployeeId: id ?? "" })).then(
      (response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as
            | CompleteFormTaskResponse
            | undefined;
          if (responsePayload?.success) {
            Swal.fire({
              title: "Görev başarı ile tamamlanmıştır.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#0097c4",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(AssingFormService(id ?? ""));
              }
            });
            setFetching(false);
          } else {
            Swal.fire({
              title: responsePayload?.userMessage,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#0097c4",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
            });
            setFetching(false);
          }
        }
      }
    );
  };

  const deleteImage = (imageId: string) => {
    Swal.fire({
      title: "Görsel'i silmek istediğinize emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteImageeHandler(imageId);
      }
    });
  };

  const deleteImageeHandler = async (imageId: string) => {
    let response = await dispatch(
      DeleteFormFieldEmployeeImageService({
        formFieldEmployeeImageId: imageId,
      } as DeleteFormFieldEmployeeImageRequest)
    ).unwrap();
    if (response) {
      if (response?.success) {
        Swal.fire({
          title: "Görsel başarı ile silindi.",
          icon: "success",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(AssingFormService(id ?? ""));
          }
        });
      } else {
        Swal.fire({
          title: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
          icon: "warning",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      }
    }
  };

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Görevlerim", href: "/mytask/list" },
        {
          title: "Görev Listesi",
          href: `/mytask/detail/list/${assingFormData?.formTaskId}`,
        },
        { title: "Görev Detay", href: "#" },
      ]}
      title={"Görev Detay"}
    >
      <Paper
        shadow="md"
        p={20}
        className="widgets"
        bg="white"
        style={{
          borderTopLeftRadius: "23px",
          borderTopRightRadius: "23px",
        }}
      >
        <Tabs color="#eef0f8" variant="pills" defaultValue="first">
          <Tabs.List
            style={{
              backgroundColor: "#eef0f8",
              paddingTop: "10px",
              paddingBottom: "10px",
              height: "60px",
              display: "flex",
              borderRadius: "24px",
              justifyContent: "space-around",
            }}
          >
            <Tabs.Tab
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                paddingLeft: "101px",
                paddingRight: "101px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
                // Seçili tabın arka plan rengi
              }}
              value="first"
            >
              Görev Detay
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="first">
            <Paper p={0} className="widgets" radius={rem(12)} bg="white">
              <Space h={"30px"} />

              <Stack
                dir="column"
                p={20}
                justify={"flex-start"}
                align={"flex-start"}
                pos={"relative"}
              >
                <LoadingOverlay
                  visible={fetching}
                  overlayBlur={2}
                  loaderProps={{
                    size: "md",
                    color: "#0097c4",
                    variant: "oval",
                  }}
                  pos={"absolute"}
                />

                <Grid>
                  <Grid.Col sm={12} md={12}>
                    <Col span={12}>
                      {assingFormData && (
                        <Table>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Görev Adı:</b>
                            </td>
                            <td>{assingFormData.name}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Açıklama:</b>
                            </td>
                            <td>
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: assingFormData.description ?? "",
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Başlangıç Tarihi:</b>
                            </td>
                            <td>
                              {moment(assingFormData.startDate).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Bitiş Tarihi:</b>
                            </td>
                            <td>
                              {moment(assingFormData.endDate).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Form Adı:</b>
                            </td>
                            <td>{assingFormData.formName}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Atanma Tarihi:</b>
                            </td>
                            <td>
                              {moment(assingFormData.createdDate).format(
                                "DD.MM.YYYY"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>Tarla Adı:</b>
                            </td>
                            <td>{field && `${field?.fieldName}`}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ width: "200px", verticalAlign: "top" }}
                            >
                              <b>İl / İlçe / Mahalle:</b>
                            </td>
                            <td>
                              {field &&
                                `${field?.address?.city?.name} / ${field?.address?.district?.name} / ${field?.address?.neighbourhood?.name}`}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              style={{ borderBottom: "1px solid #ccc" }}
                            >
                              <Space h={40}></Space>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Space h={40}></Space>
                            </td>
                          </tr>
                          {(assingFormData.formFieldEmployeeState === 1 ||
                            assingFormData.formFieldEmployeeState === 0) && (
                            <tr>
                              <td>
                                <b>Form:</b>
                              </td>
                              <td>
                                {" "}
                                <Button
                                  variant="outline"
                                  color="#000000"
                                  fullWidth
                                  style={{
                                    color: "#FFF",
                                    borderColor: "#000000",
                                    borderRadius: "2rem",
                                    fontWeight: 300,
                                    width: "200px",
                                    backgroundColor: "#7169d2",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/survey/display/1/${
                                        assingFormData.formId
                                      }?formFieldEmplyoeeId=${
                                        id ?? ""
                                      }&fieldId=${assingFormData.fieldId}`
                                    );
                                  }}
                                >
                                  Cevapla
                                </Button>
                              </td>
                            </tr>
                          )}
                          {assingFormData.formFieldEmployeeState === 2 && (
                            <tr>
                              <td>
                                <b>Form:</b>
                              </td>
                              <td>
                                <Button
                                  variant="outline"
                                  color="#000000"
                                  fullWidth
                                  style={{
                                    color: "#FFF",
                                    borderColor: "#000000",
                                    borderRadius: "2rem",
                                    fontWeight: 300,
                                    width: "200px",
                                    backgroundColor: "#7169d2",
                                  }}
                                  onClick={() => {
                                    navigate(
                                      `/survey/display/1/${
                                        assingFormData.formId
                                      }?formFieldEmplyoeeId=${
                                        id ?? ""
                                      }&fieldId=${assingFormData.fieldId}`
                                    );
                                  }}
                                >
                                  Görüntüle
                                </Button>
                              </td>
                            </tr>
                          )}
                          {assingFormData.formFieldEmployeeState === 3 && (
                            <tr>
                              <td>
                                <b>Form:</b>
                              </td>
                              <td>İptal Edilmiş</td>
                            </tr>
                          )}

                          {assingFormData.locationChecking &&
                            !assingFormData.locationChecked && (
                              <tr>
                                <td>
                                  <b>Konum Kontrolü?:</b>
                                </td>
                                <td>
                                  {" "}
                                  <Button
                                    variant="outline"
                                    color="#000000"
                                    fullWidth
                                    style={{
                                      color: "#FFF",
                                      borderColor: "#000000",
                                      borderRadius: "2rem",
                                      fontWeight: 300,
                                      width: "200px",
                                      backgroundColor: "#7169d2",
                                    }}
                                    onClick={() => {
                                      locationCheckin();
                                    }}
                                  >
                                    Konum Kontrolü Yap
                                  </Button>
                                </td>
                              </tr>
                            )}
                          {assingFormData.locationChecking &&
                            assingFormData.locationChecked && (
                              <tr>
                                <td>
                                  <b>Konum Kontrolü?:</b>
                                </td>
                                <td> Tamamlanmış</td>
                              </tr>
                            )}
                          <tr>
                            <td colSpan={2}>
                              <Space h={20}></Space>
                            </td>
                          </tr>
                          {true && (
                            <tr>
                              <td>
                                <b>Görsel Yükleme:</b>
                              </td>
                              <td>
                                <Flex>
                                  <div>
                                    <input
                                      type="file"
                                      onChange={(event) => {
                                        if (event.target?.files?.length) {
                                          setFileUrl1(
                                            event.target.files[0] as any
                                          );
                                        }
                                      }}
                                      style={{ display: "none" }}
                                      disabled={
                                        (assingFormData.images &&
                                          assingFormData.images?.some(
                                            (item: any) => item.seo === 0
                                          )) ||
                                        false
                                      }
                                      id="file-upload-1"
                                    />
                                    <label
                                      htmlFor="file-upload-1"
                                      style={{
                                        cursor: "pointer",
                                        border: "1px dashed #ccc",
                                        padding: "10px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {assingFormData.images &&
                                      assingFormData.images?.some(
                                        (item: any) => item.seo === 0
                                      ) ? (
                                        <Box style={{ position: "relative" }}>
                                          <IconSquareX
                                            style={{
                                              position: "absolute",
                                              right: "-20px",
                                              top: "-20px",
                                            }}
                                            onClick={() =>
                                              deleteImage(
                                                assingFormData.images?.find(
                                                  (item: any) => item.seo === 0
                                                )?.id ?? ""
                                              )
                                            }
                                          />
                                          <img
                                            src={
                                              assingFormData.images?.find(
                                                (item: any) => item.seo === 0
                                              )?.url
                                            }
                                            alt="Uploaded"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        "Dosya Seç"
                                      )}
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div>
                                    <input
                                      type="file"
                                      onChange={(event) => {
                                        if (event.target?.files?.length) {
                                          setFileUrl2(
                                            event.target.files[0] as any
                                          );
                                        }
                                      }}
                                      style={{ display: "none" }}
                                      disabled={
                                        (assingFormData.images &&
                                          assingFormData.images?.some(
                                            (item: any) => item.seo === 1
                                          )) ||
                                        false
                                      }
                                      id="file-upload-2"
                                    />
                                    <label
                                      htmlFor="file-upload-2"
                                      style={{
                                        cursor: "pointer",
                                        border: "1px dashed #ccc",
                                        padding: "10px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {assingFormData.images &&
                                      assingFormData.images?.some(
                                        (item: any) => item.seo === 1
                                      ) ? (
                                        <Box style={{ position: "relative" }}>
                                          <IconSquareX
                                            style={{
                                              position: "absolute",
                                              right: "-20px",
                                              top: "-20px",
                                            }}
                                            onClick={() =>
                                              deleteImage(
                                                assingFormData.images?.find(
                                                  (item: any) => item.seo === 1
                                                )?.id ?? ""
                                              )
                                            }
                                          />
                                          <img
                                            src={
                                              assingFormData.images?.find(
                                                (item: any) => item.seo === 1
                                              )?.url
                                            }
                                            alt="Uploaded"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        "Dosya Seç"
                                      )}
                                    </label>
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div>
                                    <input
                                      type="file"
                                      onChange={(event) => {
                                        if (event.target?.files?.length) {
                                          setFileUrl3(
                                            event.target.files[0] as any
                                          );
                                        }
                                      }}
                                      style={{ display: "none" }}
                                      disabled={
                                        (assingFormData.images &&
                                          assingFormData.images?.some(
                                            (item: any) => item.seo === 2
                                          )) ||
                                        false
                                      }
                                      id="file-upload-3"
                                    />
                                    <label
                                      htmlFor="file-upload-3"
                                      style={{
                                        cursor: "pointer",
                                        border: "1px dashed #ccc",
                                        padding: "10px",
                                        display: "inline-block",
                                      }}
                                    >
                                      {assingFormData.images &&
                                      assingFormData.images?.some(
                                        (item: any) => item.seo === 2
                                      ) ? (
                                        <Box style={{ position: "relative" }}>
                                          <IconSquareX
                                            style={{
                                              position: "absolute",
                                              right: "-20px",
                                              top: "-20px",
                                            }}
                                            onClick={() =>
                                              deleteImage(
                                                assingFormData.images?.find(
                                                  (item: any) => item.seo === 2
                                                )?.id ?? ""
                                              )
                                            }
                                          />
                                          <img
                                            src={
                                              assingFormData.images?.find(
                                                (item: any) => item.seo === 2
                                              )?.url
                                            }
                                            alt="Uploaded"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        "Dosya Seç"
                                      )}
                                    </label>
                                  </div>
                                </Flex>
                              </td>
                            </tr>
                          )}
                          {/* {!assingFormData.additionalPhoto && (
                            <tr>
                              <td>
                                <b>Görsel Yükleme:</b>
                              </td>
                              <td> Tanımlanmamış</td>
                            </tr>
                          )} */}
                        </Table>
                      )}
                    </Col>
                  </Grid.Col>

                  <Grid.Col sm={12} md={6}>
                    <Col span={12}></Col>
                  </Grid.Col>
                </Grid>

                <Flex>
                  <Button
                    variant="outline"
                    color="#000000"
                    fullWidth
                    style={{
                      color: "#FFF",
                      borderColor: "#000000",
                      borderRadius: "2rem",
                      fontWeight: 300,
                      width: "200px",
                      backgroundColor: "red",
                    }}
                    onClick={() => {
                      navigate(
                        `/mytask/detail/list/${assingFormData?.formTaskId}`
                      );
                    }}
                  >
                    <IconArrowLeft /> Geri
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  {assingFormData &&
                    assingFormData.taskFieldEmployeeState === 0 && (
                      <Button
                        variant="outline"
                        color="#000000"
                        fullWidth
                        style={{
                          color: "#FFF",
                          borderColor: "#000000",
                          borderRadius: "2rem",
                          fontWeight: 300,
                          width: "200px",
                          backgroundColor: "#7169d2",
                        }}
                        onClick={() => {
                          completeTask();
                        }}
                      >
                        Kaydet
                      </Button>
                    )}
                  {assingFormData &&
                    assingFormData.taskFieldEmployeeState !== 0 && (
                      <Button
                        variant="outline"
                        color="#000000"
                        fullWidth
                        style={{
                          color: "#FFF",
                          borderColor: "#000000",
                          borderRadius: "2rem",
                          fontWeight: 300,
                          width: "200px",
                          backgroundColor: "green",
                        }}
                      >
                        Tamamlanmış
                      </Button>
                    )}
                </Flex>
              </Stack>
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </MainWrapper>
  );
};

export default DetailMyTaskForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { UpdateEmployeeRequest } from "../data/request/UpdateEmployeeRequest";
import { UpdateEmployeeResponse } from "../data/response/UpdateEmployeeResponse";

export const UpdateEmployeeService = createAsyncThunk(
  "corporate/updateEmployee",
  async (request: UpdateEmployeeRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<UpdateEmployeeResponse>(
          "/corporate/employees",
          request
        );
      return response.data;
    } catch (error) {
      console.error(error || "An unknown error occurred.");
    }
  }
);

const initialState: ServiceState<UpdateEmployeeResponse> = {};

const UpdateEmployeeSlice = createSlice({
  name: "updateEmployee",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateEmployeeService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(UpdateEmployeeService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(UpdateEmployeeService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default UpdateEmployeeSlice.reducer;

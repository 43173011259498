import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Group,
  Switch,
  Image,
  Paper,
  Space,
  Text,
  rem,
  Loader,
  Skeleton,
  Grid,
  Button,
  UnstyledButton,
} from "@mantine/core";
import { useAppDispatch } from "../../../dashboard/store/dashboardHook";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "@mantine/hooks";
import {
  fetchWeatherAccuWeatherData,
  fetchWeatherTarlaIOData,
  fetchWeatherWeatherApiData,
} from "../../interactor/WeatherInteractor";
import { Days } from "../../data/request/WeatherRequests";
import { CaretLeft, CaretRight } from "phosphor-react";
import { AnyAction } from "@reduxjs/toolkit";

const accuweather = process.env.PUBLIC_URL + "/assets/images/accuweather.png";
const weatherApi =
  process.env.PUBLIC_URL + "/assets/images/weatherapi_logo.png";

const tarlaio = process.env.PUBLIC_URL + "/assets/images/left_menu_logo.png";

interface AccountProps {
  sourceType: string;
  store?: Days[];
  isLoading?: boolean;
  lat?: number;
  long?: number;
  onFavChange: (fav: string) => void;
  fav?: string;
}

function WeatherDetailCardDays({
  sourceType,
  store,
  isLoading,
  lat,
  long,
  onFavChange,
  fav,
}: AccountProps) {
  const dispatch = useAppDispatch();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isSmallerThanMd = useMediaQuery("(max-width: 62em)");
  const isSmallerThanSm = useMediaQuery("(max-width: 48em)");

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const left = isSmallerThanMd ? (isSmallerThanSm ? -150 : -250) : -280;
      scrollContainerRef.current.scrollBy({ left, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const left = isSmallerThanMd ? (isSmallerThanSm ? 150 : 250) : 280;
      scrollContainerRef.current.scrollBy({ left, behavior: "smooth" });
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftDrag, setScrollLeftDrag] = useState(0);
  useEffect(() => {
    const onMouseUp = () => {
      setIsDragging(false);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.style.cursor = "grab";
      }
    };

    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);
  const onMouseDown = (e: any) => {
    setIsDragging(true);
    if (!scrollContainerRef.current) return;
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeftDrag(scrollContainerRef.current.scrollLeft);
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const onMouseEnd = () => {
    setIsDragging(false);
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.style.cursor = "grap";
  };

  const onMouseMove = (e: any) => {
    if (!isDragging) return;
    e.preventDefault();
    if (!scrollContainerRef.current) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeftDrag - walk;
  };

  useEffect(() => {
    if (lat && long && lat > 0 && long > 0) {
      switch (sourceType) {
        case "tarlaio":
          dispatch(
            fetchWeatherTarlaIOData({
              lat: lat,
              long: long,
              sourceType: sourceType,
            })
          );

          break;
      }
    }
  }, [sourceType, lat, long]);

  return (
    <Paper
      className="widgets"
      bg="#F8F8F8"
      style={{ marginBottom: sourceType === "accuweather" ? 0 : "4px" }}
    >
      <Flex
        style={{ paddingLeft: "40px", paddingRight: rem(40) }}
        pt={rem(30)}
        direction="column"
        gap="md"
      >
        <Space h="10px" />
        <Grid align="center">
          <Grid.Col sm={12} md={7}>
            <Flex justify={{ base: "left", sm: "center", md: "left" }}>
              <LazyLoadImage
                src={tarlaio}
                rel="preload"
                placeholder={<Skeleton />}
                effect="blur"
                height={40}
                style={{ objectFit: "contain" }}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col sm={12} md={5}>
            <Group
              position="right"
              p={"20px"}
              sx={{
                borderTopLeftRadius: "36px",
                borderTopRightRadius: "36px",
                backgroundColor: "#F8F8F8",
              }}
            >
              <Switch
                onChange={(e) => {
                  onFavChange(e.currentTarget.value);
                  localStorage.setItem("weatherFav", e.currentTarget.value);
                }}
                value={sourceType}
                checked={fav === sourceType}
                style={{
                  borderRadius: 32,
                  backgroundColor: "#0097c4",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
              >
                Sezonu Düzenle
              </Switch>
              <Text
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-Light",
                  width: rem(100),
                  textAlign: "center",
                }}
                c={"#000000"}
              >
                Favorilere Ekle
              </Text>
            </Group>
          </Grid.Col>
        </Grid>

        <Flex justify="space-between" align="center">
          <UnstyledButton onClick={scrollLeft} pr={10}>
            <CaretLeft size={40} />
          </UnstyledButton>
          <Flex
            ref={scrollContainerRef}
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseEnd}
            onMouseUp={onMouseEnd}
            onMouseMove={onMouseMove}
            style={{
              overflowX: "scroll",
              display: "flex",
              flexWrap: "nowrap",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              cursor: "grab",
            }}
            px={10}
          >
            {isLoading ? (
              <Flex justify="center" bg="transparent">
                <Loader size={30} mb={20} />
              </Flex>
            ) : (
              store
                ?.filter(
                  (x) =>
                    new Date(x.time).getTime() >=
                    new Date().setHours(0, 0, 0, 0)
                )
                .map((x, index) => (
                  <>
                    <Flex
                      style={{
                        paddingTop: "20px",
                        height: "auto",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                      direction="column"
                      align={"space-even"}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: rem(24),
                        color: "#d9d9d9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",

                        height: "100%",
                        gap: 10,
                      }}
                    >
                      <Flex direction="column" align={"center"}>
                        <Space h="3px" />

                        <Image src={x.iconUri} width={24} height={24} />

                        <Space h="3px" />
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Bold",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#000000"}
                        >
                          {`${x.temperatureMax}°C / ${x.temperatureMin}°C`}
                        </Text>

                        <Text
                          style={{
                            fontSize: "12px",
                            fontFamily: "Poppins-Regular",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#878787"}
                        >
                          {new Date(x.time).toLocaleDateString("tr-TR", {
                            weekday: "long",
                          })}
                        </Text>

                        <Text
                          style={{
                            fontSize: "12px",
                            fontFamily: "Poppins-Medium",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#000000"}
                        >
                          {new Date(x.time).toLocaleDateString("tr-TR", {
                            day: "numeric",
                            month: "long",
                          })}
                        </Text>
                      </Flex>
                      <Space h="30px" />

                      <Flex
                        direction="column"
                        align={"center"}
                        sx={{
                          paddingLeft: "20px",
                          ...(isSmallerThanSm ? { paddingRight: "20px" } : {}),

                          width: "100%",
                          backgroundColor: "white",
                          borderRadius: rem(24),
                          color: "#d9d9d9",
                        }}
                      >
                        <Flex
                          direction={"column"}
                          gap={rem(4)}
                          w={
                            isSmallerThanMd
                              ? isSmallerThanSm
                                ? rem(150)
                                : rem(250)
                              : rem(280)
                          }
                          justify={"space-between"}
                        >
                          {x.weatherInfo.map(
                            (wi, i) =>
                              i !== 9 && (
                                <Flex direction={"row"} key={i}>
                                  <Text
                                    w={isSmallerThanSm ? rem(100) : rem(150)}
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "Poppins-Bold",
                                      textAlign: "left",
                                    }}
                                    c={"#136583"}
                                  >
                                    {wi.label}
                                  </Text>
                                  <Text
                                    w={isSmallerThanSm ? rem(10) : rem(20)}
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "Poppins-Bold",
                                      textAlign: "center",
                                    }}
                                    c={"#136583"}
                                  >
                                    :
                                  </Text>
                                  <Space w="5px" />
                                  <Text
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "Poppins-MediumItalic",
                                      textAlign: "center",
                                      color: "#000000",
                                      height: "16px",
                                    }}
                                  >
                                    {i === 0 || i === 1
                                      ? `${
                                          wi.key === "PrecipitationProbability"
                                            ? `% ${wi.value}`
                                            : wi.value
                                        }`
                                      : i === 2 || i === 3
                                      ? `${wi.value} °C`
                                      : i === 4
                                      ? `${wi.value} °C`
                                      : i === 5 && wi.key === "Humidity"
                                      ? `% ${wi.value}`
                                      : i === 5 && wi.key === "TemperatureMin"
                                      ? `${wi.value} °C`
                                      : i === 6
                                      ? `${wi.value}°`
                                      : i === 8
                                      ? `${wi.value} m/s`
                                      : wi.value}
                                  </Text>
                                </Flex>
                              )
                          )}
                        </Flex>

                        <Space h="20px" />
                      </Flex>
                    </Flex>

                    <Space w="20px" />
                  </>
                ))
            )}
          </Flex>
          <UnstyledButton onClick={scrollRight} pl={10}>
            <CaretRight size={40} />
          </UnstyledButton>
        </Flex>
        <Space h="20px" />
      </Flex>
    </Paper>
  );
}

export default WeatherDetailCardDays;

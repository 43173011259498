import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import { ServiceState } from "../../common/data/ServiceState";
import { GetProfileRequests } from "../data/request/ProfileRequests";
import { ProfileInfoResponse } from "../data/response/ProfileResponses";
import { ProfileInfoResponses } from "../../userprofile/data/response/UserProfileResponses";
import { MyFieldResponse } from "../../myfields/data/response/MyFieldResponses";

// Async Thunk action creators
export const fetchProfileInfo = createAsyncThunk(
  "field/fetchProfileInfo",
  async () => {
    try {
      const response = await AxiosHelper.getInstance().get<ProfileInfoResponse>(
        `corporate/profile`
      );
      localStorage.setItem("subscriptionType", response.data.data?.subscriptionType.toString() ?? "")
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const MyFieldService = createAsyncThunk(
  "fields/getMyFields",
  async () => {
    try {
      const response = await AxiosHelper.getInstance().get<MyFieldResponse>(
        "corporate/fields"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchUserProfileInfo = createAsyncThunk(
  "field/fetchUserProfileInfo",
  async () => {
    try {
      const response =
        await AxiosHelper.getInstance().get<ProfileInfoResponses>(
          `corporate/employees/profile`
        );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

// Initial states
const initialProfileState: ServiceState<ProfileInfoResponse> = {};
const initialUserProfileState: ServiceState<ProfileInfoResponses> = {};
const initialFieldState: ServiceState<MyFieldResponse> = {};

// Profile slice
export const profileSlice = createSlice({
  name: "profile/get",
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchProfileInfo.fulfilled, (state, action) => {
        state.isLoading = false;

        state.value = action.payload;
      })
      .addCase(fetchProfileInfo.rejected, (state, action) => {
        state.isLoading = false;

        // TODO : error
      })
      .addCase(fetchProfileInfo.typePrefix, (state, action) => {
        state.isLoading = false;

        // TODO : error
      })
      .addDefaultCase((state, action) => {});
  },
});

// User profile slice
export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialUserProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfileInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchUserProfileInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchUserProfileInfo.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const MyFieldSlice = createSlice({
  name: "myfields",
  initialState: initialFieldState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(MyFieldService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(MyFieldService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(MyFieldService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});




import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { AddEmployeeRequest } from "../data/request/AddEmployeeRequest";
import { AddEmployeeResponse } from "../data/response/AddEmployeeResponse";
import { error } from "console";

export const AddEmployeeService = createAsyncThunk(
  "corporate/addEmployee",
  async (request: AddEmployeeRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<AddEmployeeResponse>(
          "/corporate/employees",
          request
        );
      return response.data;
    } catch (error) {
      console.error(error || "An unknown error occurred.");
    }
  }
);

const initialState: ServiceState<AddEmployeeResponse> = {};

const AddEmployeeSlice = createSlice({
  name: "addEmployee",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddEmployeeService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AddEmployeeService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AddEmployeeService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default AddEmployeeSlice.reducer;

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { useState } from "react";
import { ModuleStore } from "./ModuleStore";
import AddMyFieldsTabs from "../../../app/common/presentation/view/component/Widgets/AddMyFieldsTabs";
import MyFieldsTable from "../presentation/view/MyFieldsTable";
import SatelliteAllFields from "../presentation/view/SatelliteAllFields";
import NavigationFields from "../presentation/view/NavigationFields";

export default function MyFieldsRoutes() {
  const [isModalOpenPlant, setIsModalOpenPlant] = useState(false);
  const closeModal = () => setIsModalOpenPlant(false);
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/myfields" element={<MyFieldsTable />} />
        <Route path="/myfields/add" element={<AddMyFieldsTabs />} />
        <Route path="/myFields/mapview" element={<SatelliteAllFields />} />
        <Route path="/myFields/navigation" element={<NavigationFields />} />
      </Routes>
    </Provider>
  );
}

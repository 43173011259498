// eslint-disable-next-line
import {
  Button,
  Col,
  Drawer,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  rem,
  Select,
  Space,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { IconSearch, IconSortDescending } from "@tabler/icons-react";
import { isEmpty, isNull } from "lodash";
import * as XLSX from "xlsx";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { Info, MicrosoftExcelLogo, MapPin } from "phosphor-react";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { RootState } from "../../../myfields/connector/ModuleStore";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch } from "../../connector/ModuleHook";
import { DeleteFieldResponse } from "../../data/response/DeleteFieldResponse";
import { MyFieldResponse } from "../../data/response/MyFieldResponses";
import { DeleteFieldService } from "../../interactor/DeleteFieldInteractor";
import { MyFieldService } from "../../interactor/MyFieldInteractor";
import { MyFieldsDto } from "../../../fields/data/dto/MyFieldsDto";
import { Field } from "../../../notifications/presentation/types/field";
import { NavLink } from "react-router-dom";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);
const MyFieldsModal = lazy(() => import("./MyFieldsModal"));

const profile = {
  authorityType: parseInt(localStorage.getItem("authorityType") ?? "4"),
};

interface MyFieldTable {
  fieldName: string;
  farmer: string | undefined;
  product: string;
  city: string;
  province: string;
  street: string;
  agricultureEngineer: string;
  averageNDVI: string;
  maxNDVI: string;
  minNDVI: string;
  agriwarning: string;
  lightningWarning: string;
  diseaseWarning: string;
  satelliteNotification: string;
  noteCount: string;
  isRead: boolean;
  id: string;
  createDate: string;
  updateDate: string;
}

const MyFieldsTable: React.FC = () => {
  const [fieldNameQuery, setFieldNameQuery] = useState("");
  const [debouncedFieldNameQuery] = useDebouncedValue(fieldNameQuery, 200);
  const cities = ["İstanbul", "Ankara", "Antalya"];
  const provinces = ["Beşiktaş", "Çankaya", "Kaş"];

  const streets = ["Atatürk", "Gazi", "Cumhuriyet"];

  const loginUser = {
    corporateName: localStorage.getItem("corporateName"),
    employeeName: localStorage.getItem("employeeName"),
    authorityType: parseInt(localStorage.getItem("authorityType") ?? "1"),
  };

  const [updatedFieldName, setUpdatedFieldName] = useState("");
  const [updatedCity, setUpdatedCity] = useState<string | null>(null);
  const [updatedProvince, setUpdatedProvince] = useState<string | null>(null);
  const [updatedStreet, setUpdatedStreet] = useState<string | null>(null);
  const [updatedAda, setUpdatedAda] = useState("");

  const [farmerQuery, setFarmerQuery] = useState("");
  const [debouncedFarmerQuery] = useDebouncedValue(farmerQuery, 200);
  const [productQuery, setProductQuery] = useState("");
  const [debouncedProductQuery] = useDebouncedValue(productQuery, 200);
  const [cityQuery, setCityQuery] = useState("");
  const [debouncedCityQuery] = useDebouncedValue(cityQuery, 200);
  const [provinceQuery, setProvinceQuery] = useState("");
  const [debounceProvinceQuery] = useDebouncedValue(provinceQuery, 200);
  const [streetQuery, setStreetQuery] = useState("");
  const [debouncedStreet] = useDebouncedValue(streetQuery, 200);
  const [agricultureEngineerQuery, setAgricultureEngineerQuery] = useState("");
  const [debouncedagricultureEnginnerQuery] = useDebouncedValue(
    agricultureEngineerQuery,
    200
  );
  const [agriwarning, setAgriwarning] = useState("");
  const [agriwarningQuery, setAgriWarningQuery] = useState("");
  const [debouncedAgriwarningQuery] = useDebouncedValue(agriwarningQuery, 200);
  const [lightningWarning, setLightningWarning] = useState("");
  const [lightningWarningQuery, setLightningWarningQuery] = useState("");
  const [debouncedLightWarningQuery] = useDebouncedValue(
    lightningWarningQuery,
    200
  );
  const [diseaseWarning, setDiseaseWarning] = useState("");
  const [diseaseWarningQuery, setDiseaseWarningQuery] = useState("");
  const [debouncedDiseaseWarningQuery] = useDebouncedValue(
    diseaseWarningQuery,
    200
  );
  const [secondaryFields, setSecondaryFields] = useState<MyFieldTable[]>([]);
  const [detailModalText, setDetailModalText] = useState<string>();
  const [pageSize, setPageSize] = useState(20); // Sayfa boyutunu tutacak state

  const handlePageSizeChange = (value: number) => {
    setPageSize(value); // Yeni sayfa boyutunu ayarla
    setPage(1); // Sayfalamayı sıfırla
  };

  const [opened, { open, close }] = useDisclosure(false);

  const [initialFields, setInitialFields] = useState<MyFieldTable[]>([]);

  const [fetching, setFetching] = useState(true);
  const handleExportToExcel = () => {
    const headers = [
      "Tarla Adı",
      "Üretici",
      "Ürün",
      "Şehir",
      "İlçe",
      "Mahalle/Köy",
      "Ziraat Mühendisi",
      "Zirai Uyarı",
      "Not Sayısı",
      "Hastalık Uyarısı",
      "Uydu Bildirimleri",
      "Oluşturulma Tarihi",
      "Değiştirilme Tarihi",
    ];

    const data = initialFields.map((field) => [
      field.fieldName,
      field.farmer,
      field.product,
      field.city,
      field.province,
      field.street,
      field.agricultureEngineer,
      field.agriwarning,
      field.noteCount,
      field.satelliteNotification,
      field.diseaseWarning,
      field.createDate,
      field.updateDate,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

    // Apply text format to date cells
    for (let R = 1; R <= data.length; ++R) {
      for (let C = 10; C <= 11; ++C) {
        // Assuming date columns are 10 (K) and 11 (L)
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].z = "@"; // Apply text format
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tarlalarım");
    XLSX.writeFile(workbook, "Tarlalarım.xlsx");
  };

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });
  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<MyFieldTable | null>(
    null
  );
  const [sortedData, setSortedData] = useState<MyFieldTable[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const myFieldService = useSelector(
    (state: RootState) => state.myFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState<MyFieldTable[]>([]);
  const [isDeleteFetching, setIsDeleteFetching] = useState(false);

  const from = (page - 1) * pageSize;
  const to = from + pageSize;

  useEffect(() => {
    dispatch(MyFieldService()).then((response) => {
      if (response !== undefined) {
        let payload = response.payload as MyFieldResponse | undefined;
        if (payload?.success) {
          setFetching(false);
        } else {
          setFetching(false);
          toast.error(payload?.userMessage);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (myFieldData) {
      const fetchedFields = myFieldData.map((field) => {
        return {
          fieldName: field.fieldName,
          farmer: (() => {
            const farmerName = !isNull(field.farmerName)
              ? field.farmerName
              : null;
            const farmerPhoneNumber = !isNull(field.farmerPhoneNumber)
              ? field.farmerPhoneNumber?.slice(
                  3,
                  field.farmerPhoneNumber.length
                )
              : null;

            if (farmerName && farmerPhoneNumber) {
              return `${farmerName} - ${farmerPhoneNumber}`;
            } else if (farmerName) {
              return farmerName;
            } else if (farmerPhoneNumber) {
              return farmerPhoneNumber;
            } else {
              return "-";
            }
          })(),
          product: field.seasons.find((x) => !x.seasonClosed)?.plantName ?? "-",
          city: field.address.city.name,
          province: field.address.district.name,
          street: field.address.neighbourhood.name,
          agricultureEngineer:
            field.managers && field.managers.length > 0
              ? field.managers
                  .map((manager) =>
                    manager.employeeName.trim() !== ""
                      ? manager.employeeName
                      : "-"
                  )
                  .join(", ")
              : "-",
          agriwarning:
            field.agriculturalNotificationCount.toString() + " mesaj",
          lightningWarning: "",
          isRead: false,
          averageNDVI: "",

          satelliteNotification: (
            field.satelliteNotificationCount ?? 0
          ).toString(),

          noteCount:
            field.seasons
              .at(field.seasons.length - 1)
              ?.seasonNotes.length.toString() ?? "0",

          maxNDVI: "",
          minNDVI: "",

          fieldId: field.id,
          diseaseWarning: field.diseaseNotificationCount.toString() + " mesaj",

          id: field.id,
          createDate: field.createDate
            ? new Date(field.createDate).toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "-",
          updateDate: field.updateDate
            ? new Date(field.updateDate).toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "-",
        };
      });
      setInitialFields(fetchedFields);
      setSecondaryFields(fetchedFields);
    }
  }, [myFieldData]);

  const [isSmallThanXl, setIsSmallThanXl] = useState(false);
  const [isSmallThanLg, setIsSmallThanLg] = useState(false);
  const [isSmallThanMd, setIsSmallThanMd] = useState(false);

  useEffect(() => {
    const mediaQueryXl = window.matchMedia("(max-width: 88em)");
    setIsSmallThanXl(mediaQueryXl.matches);

    const handleResize = () => {
      setIsSmallThanXl(mediaQueryXl.matches);
    };

    mediaQueryXl.addEventListener("change", handleResize);
    return () => mediaQueryXl.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryLg = window.matchMedia("(max-width: 75em)");
    setIsSmallThanLg(mediaQueryLg.matches);

    const handleResize = () => {
      setIsSmallThanLg(mediaQueryLg.matches);
    };

    mediaQueryLg.addEventListener("change", handleResize);
    return () => mediaQueryLg.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryMd = window.matchMedia("(max-width: 62em)");
    setIsSmallThanMd(mediaQueryMd.matches);

    const handleResize = () => {
      setIsSmallThanMd(mediaQueryMd.matches);
    };

    mediaQueryMd.addEventListener("change", handleResize);
    return () => mediaQueryMd.removeEventListener("change", handleResize);
  }, []);

  const handleAgriWarningClick = (id: string) => {
    navigate(`/notifications?fieldId=${id}&kind=${1}`);
  };

  const handleLightningWarningClick = (id: string) => {
    navigate(`/notifications?fieldId=${id}&kind=${4}`);
  };

  const handleButtonClick = () => {
    // Yönlendirme işlemini gerçekleştir
    navigate("/myfields/add");
  };

  const handleButtonDetailClick = (id: string) => {
    // Yönlendirme işlemini gerçekleştir
    navigate("/myfields/detail?id=" + id);
  };

  const handleIsDelete = (id: string) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else {
        setIsDeleteFetching(false);
      }
    });
  };

  const handleDelete = (id: string) => {
    dispatch(DeleteFieldService(id)).then((response) => {
      if (response !== undefined) {
        const payload = response.payload as DeleteFieldResponse | undefined;
        if (payload?.success) {
          toast.success("Tarla Silindi");
          dispatch(MyFieldService());
          setIsDeleteFetching(false);
        } else {
          toast.error(payload?.userMessage || "Tarla Silinemedi");
          setIsDeleteFetching(false);
        }
      }
    });
  };

  useEffect(() => {
    if (initialFields) {
      setPageData(initialFields.slice(from, to));
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      let filteredData = initialFields.filter(
        ({
          fieldName,
          farmer,
          product,
          city,
          province,
          street,
          agricultureEngineer,
          noteCount,
        }) => {
          if (
            debouncedFieldNameQuery !== "" &&
            fieldName &&
            !fieldName
              .normalize("NFC")
              .toLocaleLowerCase()
              .replaceAll("i̇", "i")
              .includes(
                debouncedFieldNameQuery
                  .trim()
                  .normalize("NFC")
                  .toLocaleLowerCase()
              )
          ) {
            return false;
          }

          if (
            debouncedFarmerQuery !== "" &&
            farmer &&
            !`${farmer}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFarmerQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedProductQuery !== "" &&
            product &&
            !`${product}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedProductQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedCityQuery !== "" &&
            city &&
            !`${city}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedCityQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debounceProvinceQuery !== "" &&
            province &&
            !`${province}`
              .toLowerCase()
              .includes(debounceProvinceQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedStreet !== "" &&
            street &&
            !`${street}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedStreet.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedagricultureEnginnerQuery !== "" &&
            agricultureEngineer &&
            agricultureEngineer.trim() !== "" &&
            !agricultureEngineer
              .split(",")
              .some((name) =>
                name
                  .trim()
                  .toLowerCase()
                  .replaceAll("i̇", "i")
                  .includes(
                    debouncedagricultureEnginnerQuery.trim().toLowerCase()
                  )
              )
          ) {
            return false;
          }
          return true;
        }
      );

      let sortedData = [...filteredData];

      if (sortStatus.columnAccessor === "fieldName") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldName;
          const valueB = b.fieldName;
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "farmer") {
        sortedData.sort((a, b) => {
          const valueA = a.farmer ? a.farmer.toLowerCase() : "";
          const valueB = b.farmer ? b.farmer.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "product") {
        sortedData.sort((a, b) => {
          const valueA = a.product.toLowerCase();
          const valueB = b.product.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "city") {
        sortedData.sort((a, b) => {
          const valueA = a.city.toLowerCase();
          const valueB = b.city.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "province") {
        sortedData.sort((a, b) => {
          const valueA = a.province.toLowerCase();
          const valueB = b.province.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "street") {
        sortedData.sort((a, b) => {
          const valueA = a.street.toLowerCase();
          const valueB = b.street.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "agriWarning") {
        sortedData.sort((a, b) => {
          const valueA = a.agriwarning.toLowerCase();
          const valueB = b.agriwarning.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "lightningWarning") {
        sortedData.sort((a, b) => {
          const valueA = a.lightningWarning.toLowerCase();
          const valueB = b.lightningWarning.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }
      // else if (sortStatus.columnAccessor === "noteCount") {
      //   sortedData.sort((a, b) => {
      //     const valueA = a.noteCount.toString().toLowerCase();
      //     const valueB = b.noteCount.toString().toLowerCase();
      //     return sortStatus.direction === "asc"
      //       ? valueA.localeCompare(valueB)
      //       : valueB.localeCompare(valueA);
      //   });
      else if (sortStatus.columnAccessor === "agricultureEngineer") {
        sortedData.sort((a, b) => {
          const arrayA = a.agricultureEngineer.toLowerCase().split(",");
          const arrayB = b.agricultureEngineer.toLowerCase().split(",");

          for (let i = 0; i < Math.min(arrayA.length, arrayB.length); i++) {
            const trimmedA = arrayA[i].trim();
            const trimmedB = arrayB[i].trim();

            if (!trimmedA || !trimmedB) {
              continue;
            }

            const valueA = trimmedA[0];
            const valueB = trimmedB[0];

            if (valueA !== valueB) {
              return sortStatus.direction === "asc"
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
            }
          }

          return sortStatus.direction === "asc"
            ? arrayA.length - arrayB.length
            : arrayB.length - arrayA.length;
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [
    debouncedFieldNameQuery,
    debouncedFarmerQuery,
    debouncedProductQuery,
    debouncedCityQuery,
    debounceProvinceQuery,
    debouncedStreet,
    debouncedagricultureEnginnerQuery,
    debouncedAgriwarningQuery,
    debouncedLightWarningQuery,
    debouncedDiseaseWarningQuery,
    page,
    sortStatus,
    initialFields,
    from,
    to,
  ]);

  const columns = [
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,

      ellipsis: true,
      render: ({ fieldName }: { fieldName: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {fieldName}
        </span>
      ),
      filter: (
        <TextInput
          label="İsim"
          description="Tarla adlarını ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={fieldNameQuery}
          onChange={(e) => setFieldNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldNameQuery !== "",
    },
    {
      accessor: "farmer",
      title: "Üretici",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ farmer }: { farmer?: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {farmer || ""}
        </span>
      ),
      filter: (
        <TextInput
          label="Üretici"
          description="Belirtilen üretici içeren tarlaları ara."
          placeholder="Tarla Üreticisi ara..."
          icon={<IconSearch size={16} />}
          value={farmerQuery}
          onChange={(e) => setFarmerQuery(e.currentTarget.value)}
        />
      ),
      filtering: farmerQuery !== "",
    },
    {
      accessor: "product",
      title: "Ürün",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ product }: { product?: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {product}
        </span>
      ),
      filter: (
        <TextInput
          label="Ürün"
          description="Belirtilen ürüne sahip tarlaları ara."
          placeholder="Ürün ara..."
          icon={<IconSearch size={16} />}
          value={productQuery}
          onChange={(e) => setProductQuery(e.currentTarget.value)}
        />
      ),
      filtering: productQuery !== "",
    },
    {
      accessor: "city",
      title: "Şehir",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ city }: { city: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {city}
        </span>
      ),
      filter: (
        <TextInput
          label="Şehir"
          description="Belirtilen şehirdeki tarlaları ara."
          placeholder="Şehre göre filtrele"
          icon={<IconSearch size={16} />}
          value={cityQuery}
          onChange={(e) => setCityQuery(e.currentTarget.value)}
        />
      ),
      filtering: cityQuery !== "",
    },
    {
      accessor: "province",
      title: "İlçe",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ province }: { province?: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {province}
        </span>
      ),
      filter: (
        <TextInput
          label="İlçe"
          description="Belirtilen ilçedeki tarlaları ara."
          placeholder="İlçeye göre filtrele"
          icon={<IconSearch size={16} />}
          value={provinceQuery}
          onChange={(e) => setProvinceQuery(e.currentTarget.value)}
        />
      ),
      filtering: provinceQuery !== "",
    },
    {
      accessor: "street",
      title: "Mahalle/Köy",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ street }: { street?: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {street}
        </span>
      ),
      filter: (
        <TextInput
          label="Mahalle/Köy"
          description="Belirtilen mahalle/köydeki tarlaları ara."
          placeholder="Mahalle/Köy'e göre filtrele"
          icon={<IconSearch size={16} />}
          value={streetQuery}
          onChange={(e) => setStreetQuery(e.currentTarget.value)}
        />
      ),
      filtering: streetQuery !== "",
    },
    {
      accessor: "agricultureEngineer",
      title: "Ziraat Mühendisi",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ agricultureEngineer }: { agricultureEngineer: string }) => {
        const engineers = agricultureEngineer.split(", ");
        return (
          <td
            style={{
              fontSize: "12px",
              color: "#000000",
              fontFamily: "Poppins-Light",
              fontWeight: 300,
              textDecoration: "none",
            }}
          >
            {engineers.length > 3 ? (
              <Tooltip label="Detayı Görüntülemek İçin Tıklayınız.">
                <div onClick={() => setDetailModalText(agricultureEngineer)}>
                  {engineers.slice(0, 3).map((name, index) => (
                    <div
                      key={index}
                      style={{
                        fontFamily: "Poppins-Light",
                        fontWeight: 300,
                        color: "#000000",
                        textDecoration: "none",
                      }}
                    >
                      {name}
                      {index === 2 && engineers.length > 3 && (
                        <span
                          style={{
                            fontFamily: "Poppins-Light",
                            fontWeight: 300,
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "none", // Initially no underline
                          }}
                        >
                          {" "}
                          Devamı...
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </Tooltip>
            ) : (
              engineers.map((name, index) => (
                <div
                  key={index}
                  style={{
                    textDecoration: "none", // Initially no underline
                    color: "#000000",
                    fontFamily: "Poppins-Light",
                    fontWeight: 300,
                  }}
                >
                  {name}
                </div>
              ))
            )}
          </td>
        );
      },
      filter: (
        <TextInput
          label="Ziraat Mühendisi"
          description="Belirtilen ziraat mühendislerine göre tarlaları ara."
          placeholder="Ziraat mühendislerine göre filtrele"
          icon={<IconSearch size={16} />}
          value={agricultureEngineerQuery}
          onChange={(e) => setAgricultureEngineerQuery(e.currentTarget.value)}
        />
      ),
      filtering: agricultureEngineerQuery !== "",
    },

    {
      accessor: "agriwarning",
      title: "Zirai Uyarı",
      textglignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: (agriwarning: any) => (
        <button
          style={{
            backgroundColor: "transparent",
            border: "0px solid #ccc",
            borderRadius: "4px",
            fontFamily: "Poppins-Light",
            fontWeight: 300,
          }}
          onClick={() => {
            handleAgriWarningClick(agriwarning.fieldId);
          }}
        >
          <span
            style={{
              textDecoration: "none", // Initially no underline
              color: "#000000",
              fontFamily: "Poppins-Light",
              fontWeight: 300, // Default color
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              e.currentTarget.style.color = "#0000FF"; // Blue color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.textDecoration = "none"; // Remove underline on mouse leave
              e.currentTarget.style.color = "#000000"; // Restore default color on mouse leave
            }}
          >
            {agriwarning.agriwarning}
          </span>
        </button>
      ),
    },

    {
      accessor: "diseaseWarning",
      title: "Hastalık Uyarısı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: (lightningWarning: any) => (
        <button
          style={{
            backgroundColor: "transparent",
            border: "0px solid #ccc",
            borderRadius: "4px",
            fontFamily: "Poppins-Light",
            fontWeight: 300,
          }}
          onClick={() => {
            handleLightningWarningClick(lightningWarning.fieldId);
          }}
        >
          <span
            style={{
              textDecoration: "none", // Initially no underline
              color: "#000000",
              fontFamily: "Poppins-Light",
              fontWeight: 300, // Default color // Default color
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              e.currentTarget.style.color = "#0000FF"; // Blue color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.textDecoration = "none"; // Remove underline on mouse leave
              e.currentTarget.style.color = "#000000"; // Restore default color on mouse leave
            }}
          >
            {lightningWarning.diseaseWarning}
          </span>
        </button>
      ),
    },
    {
      accessor: "satelliteNotification",
      title: "Uydu Bildirimleri",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({
        satelliteNotification,
      }: {
        satelliteNotification?: string;
      }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {satelliteNotification}
        </span>
      ),
    },
    {
      accessor: "noteCount",
      title: "Not Sayısı",
      textglignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,

      render: ({ noteCount }: { noteCount?: string }) => (
        <span
          style={{
            fontFamily: "Poppins-Light",
            fontSize: "12px",
            fontWeight: 300,
            color: "#000000",
          }}
        >
          {noteCount}
        </span>
      ),
    },
    {
      accessor: "isRead",
      title: "",
      textAlignment: "center" as DataTableColumnTextAlignment,

      render: (record: MyFieldTable, index: number) => (
        <Group spacing={4} position="center" noWrap>
          <Drawer.Root opened={opened} onClose={close} position="right">
            <Drawer.Content
              style={{ paddingLeft: "40px", paddingRight: "40px", width: 1000 }}
            >
              <Drawer.Header>
                <Drawer.Title
                  style={{ font: "Poppins-Light", fontSize: "28px" }}
                >
                  Tarla Güncelle
                </Drawer.Title>
                <Drawer.CloseButton />
              </Drawer.Header>

              <Grid>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Tarla Adı
                  </Text>
                  <Input
                    placeholder="Tarla İsmini Girin"
                    value={updatedFieldName}
                    onChange={(event) =>
                      setUpdatedFieldName(event.target.value)
                    }
                  />
                </Col>
                <Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    İl
                  </Text>
                  <Select
                    placeholder="İl Seçin"
                    data={cities}
                    value={updatedCity}
                    onChange={(value) => setUpdatedCity(value)}
                  />
                </Col>
                <Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    İlçe
                  </Text>
                  <Select
                    placeholder="İlçe Seçin"
                    data={provinces}
                    value={updatedProvince}
                    onChange={(value) => setUpdatedProvince(value)}
                  />
                </Col>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Mahalle
                  </Text>
                  <Select
                    placeholder="Mahalle Girin"
                    data={streets}
                    value={updatedStreet}
                    onChange={(value) => setUpdatedStreet(value)}
                  />
                </Col>
                <Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Ada
                  </Text>
                  <Input
                    placeholder="Ada no girin"
                    value={updatedAda}
                    onChange={(event) =>
                      setUpdatedAda(event.currentTarget.value)
                    }
                  />
                </Col>
                <Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Parsel
                  </Text>
                  <Select
                    placeholder="İlçe Seçin"
                    data={provinces}
                    value={updatedProvince}
                    onChange={(value) => setUpdatedProvince(value)}
                  />
                </Col>
                <Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Mahalle
                  </Text>
                  <Select
                    placeholder="Mahalle Girin"
                    data={streets}
                    value={updatedStreet}
                    onChange={(value) => setUpdatedStreet(value)}
                  />
                </Col>

                <Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#0097c4",
                      width: "338px",
                      borderRadius: "32px",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      marginBottom: "20px", // Adjusted margin for spacing
                      padding: "11.5px 40px", // Adjusted padding
                      textAlign: "center",
                    }}
                    onClick={open}
                  >
                    Kaydet
                  </Button>

                  <Button
                    style={{
                      width: "338px",
                      borderRadius: "32px",
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                      border: "0.5px solid #000000",
                      fontFamily: "Poppins-Medium",
                      fontSize: "12px",
                      padding: "11.5px 40px", // Adjusted padding
                      textAlign: "center",
                      fontWeight: "300",
                    }}
                  >
                    İptal
                  </Button>
                </Col>
              </Grid>
            </Drawer.Content>
          </Drawer.Root>

          <Button
            variant="outline"
            color="#000000"
            onClick={() => {
              handleButtonDetailClick(record.id);
            }}
            fullWidth
            style={{
              color: "#000000",
              borderColor: "#000000",
              borderRadius: "2rem",
              width: "100%",
              fontWeight: 300,
            }}
          >
            <Text>Detay</Text>
          </Button>

          <Button
            variant="outline"
            color="#000000"
            fullWidth
            onClick={() => {
              handleIsDelete(record.id);
              setIsDeleteFetching(true);
            }}
            style={{
              color: "#000000",
              borderColor: "#000000",
              borderRadius: "2rem",
              width: "100%",
              fontWeight: 300,
              display: profile?.authorityType === 2 ? "none" : "block", // Yetki tipi 2 ise görünmez
            }}
          >
            <Text>Sil</Text>
          </Button>
        </Group>
      ),
    },
  ];
  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Tarlalarım", href: "#" }]}
        title="Tarlalarım"
      >
        <>
          <Modal
            opened={detailModalText !== undefined}
            onClose={() => setDetailModalText(undefined)}
            className="custom-modal"
            title="Ziraat Mühendisleri"
            centered
            size={"lg"}
            withCloseButton
            styles={{
              content: {
                borderRadius: "2rem",
                padding: ".8rem",
              },
            }}
            sx={{
              ".mantine-Modal-title": {
                fontSize: "20px !important",
                fontWeight: "bold",
              },
            }}
          >
            {detailModalText && <Text>{detailModalText}</Text>}
          </Modal>
          <Suspense fallback={<CustomLoadingOverlay></CustomLoadingOverlay>}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <LoadingOverlay
                visible={isDeleteFetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              />
              <Grid>
                <Grid.Col md={12} lg={8}>
                  <Group position={isSmallThanLg ? "center" : "left"}>
                    <Info size={isSmallThanXl ? 26 : 32} />
                    <Text
                      style={{
                        fontSize: isSmallThanXl ? "14px" : "16px",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Tarla detayına ulaşmak için DETAY butonunu kullanınız.
                    </Text>
                  </Group>
                  <Space h={"md"}></Space>
                  <Text
                    style={{
                      fontSize: isSmallThanXl ? "14px" : "16px",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    Toplam Tarla Sayısı: {initialFields.length}
                  </Text>
                </Grid.Col>
                <Grid.Col md={12} lg={4} style={{ textAlign: "right" }}>
                  <Group position="right" spacing="xs">
                    <NavLink to="/myFields/mapview">
                      <Button
                        style={{
                          borderRadius: "32px",
                          backgroundColor: "#0097c4",
                          fontFamily: "Poppins-Light",
                          fontSize: rem(12),
                          textAlign: "center",
                          height: "36px",
                          float: "right",
                        }}
                      >
                        <MapPin size={16} color="#FFF" />
                        <Space w="xs" />
                        <Text
                          style={{
                            color: "#FFF",
                            fontSize: "12px",
                            fontFamily: "Poppins-Light",
                          }}
                        >
                          Harita Görünümü
                        </Text>
                      </Button>
                    </NavLink>
                    <Button
                      style={{
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        borderRadius: 32,
                        backgroundColor: "#FFFFFF",
                        color: "#000000",
                        border: "0.5px solid #619628",
                        fontFamily: "Poppins-Light",
                        fontSize: "12px",
                      }}
                      onClick={handleExportToExcel}
                    >
                      <MicrosoftExcelLogo size={16} color="#000000" />
                      <Space w="xs" />
                      <Text
                        style={{
                          color: "#000000",
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        Excel Formatı İndirin
                      </Text>
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        paddingLeft: "100px",
                        paddingRight: "100px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        fontFamily: "Poppins-Regular",
                        ...(loginUser.authorityType === 2
                          ? { display: "none" }
                          : { display: "block" }),
                      }}
                      onClick={handleButtonClick}
                      disabled={loginUser.authorityType === 2 ? true : false}
                    >
                      Tarla Ekle
                    </Button>
                  </Group>
                </Grid.Col>
                <Grid.Col
                  md={10}
                  lg={1}
                  style={{ textAlign: "right" }}
                ></Grid.Col>
              </Grid>

              <Space h="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <DataTable
                    withBorder
                    withColumnBorders
                    highlightOnHover
                    className="datatable"
                    minHeight={250}
                    noRecordsText="Tarla Bulunamadı..."
                    records={pageData}
                    columns={columns}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    totalRecords={sortedData.length}
                    recordsPerPage={pageSize}
                    recordsPerPageOptions={[10, 20, 50, 100]}
                    onPageChange={(p) => setPage(p)}
                    onRecordsPerPageChange={(perPage) =>
                      handlePageSizeChange(perPage)
                    }
                    page={page} // page prop'unu ekledim
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>

              <MyFieldsModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </Paper>
          </Suspense>
        </>
      </MainWrapper>
    </Suspense>
  );
};

export default MyFieldsTable;

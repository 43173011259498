import React, { useEffect, useState } from "react";
import {
  Tabs,
  Paper,
  rem,
  Group,
  Drawer,
  Flex,
  Box,
  Modal,
  Stack,
  Space,
  Button,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import AddSurveyCard from "./AddSurveyCard";
import {
  FormBuilderPostData,
  ReactFormBuilder,
  ReactFormGenerator,
  ToolbarItem,
} from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FormPublishService,
  FormQuestionAddService,
  FormQuestionDetailService,
} from "../../interractor/SurveyInterractor";
import { useAppDispatch } from "../../connector/ModuleHook";
import { FormQuestionAddRequest } from "../../data/request/FormQuestionAddRequest";
import { FormQuestionDetailRequest } from "../../data/request/FormQuestionDetailRequest";
import Swal from "sweetalert2";
import { FormPublishRequest } from "../../data/request/FormPublishRequest";
import useWindowDimensions from "../../../common/util/useWindowDimensions";

const SurveyEditor: React.FC = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [data, setData] = useState<any>(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    if (id) {
      setShouldUpdate(true);
    }
  }, [id]);

  useEffect(() => {
    var element = document.getElementsByClassName(
      "react-form-builder-toolbar"
    )[0] as HTMLDivElement;

    if (width < 1490) {
      element.style.setProperty("width", "220px", "important");
      element.style.right = "0";
    } else {
      element.style.setProperty("width", "242px", "important");
      element.style.right = "5%";
    }
  }, [width]);

  const loadFormData = async () => {
    try {
      let response = await dispatch(
        FormQuestionDetailService({
          formId: id,
        } as FormQuestionDetailRequest)
      ).unwrap();
      if (response) {
        if (response?.data) {
          if (response.data.formQuestion.jsonData) {
            let jsonData: any = JSON.parse(response.data.formQuestion.jsonData);
            setData(jsonData);
            console.log(jsonData);
            return jsonData.task_data;
          } else {
            return [] as any;
          }
        } else {
          return [] as any;
        }
      }
      return [] as any;
    } catch (error) {
      console.error("Error loading form data:", error);
      return [] as any;
    }
  };

  const handlePost = async (data: any) => {
    console.log(data);
    setData(data);
    let response = await dispatch(
      FormQuestionAddService({
        formQuestion: {
          formId: id,
          jsonData: JSON.stringify(data),
        },
      } as FormQuestionAddRequest)
    ).unwrap();
    if (response) {
      console.log(response);
    }
  };
  const handleSubmit = async (formData: any[]) => {
    try {
      // Prepare the request body by formatting the form data appropriately
      const requestBody = {
        task_id: 12, // Your task_id or any other data you need to send
        form_data: formData,
      };
      console.log(formData);
      // Send the request to the desired endpoint
      //    const response = await fetch(`${process.env.PUBLIC_URL}/api/submit-form`, {
      //      method: "POST",
      //      headers: {
      //        "Content-Type": "application/json",
      //      },
      //      body: JSON.stringify(requestBody),
      //    });

      //    // Check if the request was successful
      //    if (response.ok) {
      //      const result = await response.json();
      //      console.log("Form successfully submitted:", result);
      //      alert("Form successfully submitted!");
      //    } else {
      //      console.error("Form submission failed:", response.statusText);
      //      alert("Form submission failed!");
      //    }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred during form submission.");
    }
  };

  const publishFormHandler = () => {
    publishForm();
  };

  const publishForm = async () => {
    let response = await dispatch(
      FormPublishService({
        formId: id,
      } as FormPublishRequest)
    ).unwrap();
    if (response) {
      if (response?.success) {
        Swal.fire({
          title: "Form başarı ile kayıt edilmiştir.",
          text: "",
          icon: "success",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      } else {
        Swal.fire({
          title: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
          text: "Bu işlemi geri alamayacaksınız!",
          icon: "warning",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      }
    }
  };

  const formElements = [
    {
      key: "Header",
      name: "Başlık Metni",
      icon: "fas fa-heading",
      static: true,
      content: "Başlık Metni",
    },
    {
      key: "TextInput",
      name: "Kısa Metin",
      icon: "fas fa-font",
      label: "Metin Girişi",
    },
    {
      key: "TextArea",
      name: "Uzun Metin",
      icon: "fas fa-text-height",
      label: "Metin Alanı",
    },
    {
      key: "RadioButtons",
      name: "Radyo Düğmeleri",
      icon: "fas fa-dot-circle",
      label: "Radyo Düğmeleri",
      options: [
        { value: "option1", text: "Seçenek 1" },
        { value: "option2", text: "Seçenek 2" },
      ],
      canPopulateFromApi: false,
    },
    {
      key: "Checkboxes",
      name: "Onay Kutuları",
      icon: "fas fa-check-square",
      label: "Onay Kutuları",
      options: [
        { value: "option1", text: "Seçenek 1" },
        { value: "option2", text: "Seçenek 2" },
      ],
      canPopulateFromApi: false,
    },
    {
      key: "Dropdown",
      name: "Açılır Menü",
      icon: "fas fa-caret-square-down",
      label: "Açılır Menü",
      options: [
        { value: "option1", text: "Seçenek 1" },
        { value: "option2", text: "Seçenek 2" },
      ],
      canPopulateFromApi: false,
    },
    {
      key: "TwoColumnRow",
      name: "İki Sütun",
      icon: "fas fa-columns",
    },
    {
      key: "ThreeColumnRow",
      name: "Üç Sütun",
      icon: "fas fa-columns",
    },
    {
      key: "MultiColumnRow",
      name: "Dört Sütun",
      icon: "fas fa-columns",
    },
  ] as ToolbarItem[];

  const FiveColumn = () => {
    return <div className="five-column">{/* Beş sütun içeriği */}</div>;
  };
  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Formlar", href: "/survey/list" },
        { title: "Form Editor", href: "#" },
      ]}
      title={"Form Editor"}
      hideMenu={true}
    >
      <>
        <Flex>
          <Button
            variant="filled"
            color="blue"
            onClick={() => setPreviewVisible(true)}
            style={{ marginBottom: "20px", marginRight: "10px" }}
          >
            Ön İzleme
          </Button>
          <Button
            variant="filled"
            color="blue"
            onClick={() => publishFormHandler()}
            style={{
              marginBottom: "20px",
              backgroundColor: "#50aa4c",
              marginRight: "10px",
            }}
          >
            Kaydet
          </Button>

          <Button
            variant="filled"
            color="#000000"
            fullWidth
            style={{
              color: "#FFF",
              width: "120px",
              fontWeight: 300,
              backgroundColor: "red",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Geri
          </Button>
        </Flex>

        <Box>
          <React.StrictMode>
            <ReactFormBuilder
              onLoad={loadFormData}
              onPost={handlePost}
              locale="tr"
              editMode={false}
              toolbarItems={formElements}
            />
          </React.StrictMode>
        </Box>

        <Modal
          size={"900px"}
          opened={previewVisible}
          onClose={() => setPreviewVisible(false)}
          zIndex={1000}
        >
          {data && id && (
            <ReactFormGenerator
              form_action={`${process.env.PUBLIC_URL}/path/to/form/submit`}
              form_method="POST"
              task_id={12}
              answer_data={[]}
              authenticity_token={""}
              data={data.task_data}
              onSubmit={handleSubmit}
              submitButton={
                <Button
                  variant="filled"
                  color="blue"
                  type="submit"
                  style={{ marginBottom: "20px", backgroundColor: "#50aa4c" }}
                >
                  Kaydet
                </Button>
              }
              locale="tr"
            />
          )}
          <Space h="10px" />
        </Modal>
      </>
    </MainWrapper>
  );
};

export default SurveyEditor;

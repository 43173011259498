import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { AgrovisioNotesResponse, SatelliteResponse } from "../data/response/SatelliteResponse";

export const SatelliteService = createAsyncThunk(
  "satellite/getSatalite",
  async function getSatelliteData(id: string) {
    try {
      //
      const response = await AxiosHelper.getInstance().get<SatelliteResponse>(
        `/corporate/services/satellites/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getAgroNotes = createAsyncThunk(
  "satellite/notes",
  async ({ AgrovisioFieldId, AgrovisioName }: { AgrovisioFieldId: string, AgrovisioName: string }) => {
    try {
      const response = await AxiosHelper.getInstance().get<AgrovisioNotesResponse>(
        `/services/agrovisio/field/notes?AgrovisioFieldId=${AgrovisioFieldId}&AgrovisioName=${AgrovisioName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error; // Hata fırlat ki thunk hata durumunu işleyebilsin
    }
  }
);


const initialState: ServiceState<SatelliteResponse> = {};
const initialStateNotes: ServiceState<AgrovisioNotesResponse> = {};


const SatelliteSlice = createSlice({
  name: "satalite",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SatelliteService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(SatelliteService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(SatelliteService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default SatelliteSlice.reducer;

export const AgroNotesSlice = createSlice({
  name: "AgroNotesSlice",
  initialState: initialStateNotes,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgroNotes.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getAgroNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(getAgroNotes.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

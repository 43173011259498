import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Group,
  Switch,
  Image,
  Paper,
  Space,
  Text,
  rem,
  Loader,
  Skeleton,
  Grid,
  UnstyledButton,
} from "@mantine/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "@mantine/hooks";
import { useAppDispatch } from "../../../dashboard/store/dashboardHook";
import { CaretLeft, CaretRight } from "phosphor-react";
import {
  fetchWeatherHourlyVisualCrossData,
  fetchWeatherHourlyWeatherApiData,
  fetchWeatherHourlyWeatherBitData,
  fetchHourlyWeatherTarlaIOData,
} from "../../interactor/WeatherInteractor";

const logos = {
  tarlaio: process.env.PUBLIC_URL + "/assets/images/left_menu_logo.png",
  weatherapi: process.env.PUBLIC_URL + "/assets/images/weatherapi_logo.png",
  visualcross:
    process.env.PUBLIC_URL + "/assets/images/Visual_Crossing_Logo.png",
  weatherbit: process.env.PUBLIC_URL + "/assets/images/weatherbit.svg",
};

interface AccountProps {
  sourceType: string;
  store?: any[];
  isLoading?: boolean;
  lat?: number;
  long?: number;
  onFavChange: (fav: string) => void;
  fav?: string;
}

function isToday(date: Date): boolean {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

function isTomorrow(date: Date): boolean {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getFullYear() === tomorrow.getFullYear()
  );
}

function WeatherForecastHourDetailCard({
  sourceType,
  store,
  isLoading,
  lat,
  long,
  onFavChange,
  fav,
}: AccountProps) {
  const dispatch = useAppDispatch();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isSmallerThanMd = useMediaQuery("(max-width: 62em)");
  const isSmallerThanSm = useMediaQuery("(max-width: 48em)");
  useEffect(() => {
    if (lat && long && lat > 0 && long > 0) {
      switch (sourceType) {
        case "tarlaio":
          dispatch(fetchHourlyWeatherTarlaIOData({ lat, long, sourceType }));
          break;
        case "visualcross":
          dispatch(
            fetchWeatherHourlyVisualCrossData({ lat, long, sourceType })
          );
          break;
        case "weatherapi":
          dispatch(fetchWeatherHourlyWeatherApiData({ lat, long, sourceType }));
          break;
        case "weatherbit":
          dispatch(fetchWeatherHourlyWeatherBitData({ lat, long, sourceType }));
          break;
      }
    }
  }, [dispatch, lat, long, sourceType]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const left = isSmallerThanMd ? (isSmallerThanSm ? -150 : -250) : -280;
      scrollContainerRef.current.scrollBy({ left, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const left = isSmallerThanMd ? (isSmallerThanSm ? 150 : 250) : 280;
      scrollContainerRef.current.scrollBy({ left, behavior: "smooth" });
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftDrag, setScrollLeftDrag] = useState(0);
  useEffect(() => {
    const onMouseUp = () => {
      setIsDragging(false);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.style.cursor = "grab";
      }
    };

    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);
  const onMouseDown = (e: any) => {
    setIsDragging(true);
    if (!scrollContainerRef.current) return;
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeftDrag(scrollContainerRef.current.scrollLeft);
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const onMouseEnd = () => {
    setIsDragging(false);
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.style.cursor = "grap";
  };

  const onMouseMove = (e: any) => {
    if (!isDragging) return;
    e.preventDefault();
    if (!scrollContainerRef.current) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    scrollContainerRef.current.scrollLeft = scrollLeftDrag - walk;
  };

  return (
    <Paper className="widgets" bg="#F8F8F8" style={{ marginBottom: "4px" }}>
      <Flex
        style={{ paddingLeft: "40px", paddingRight: rem(40) }}
        direction="column"
        pt={rem(30)}
        gap="md"
        p={20}
      >
        <Space h="10px" />
        <Grid align="center">
          <Grid.Col sm={12} md={7}>
            <Flex justify={{ base: "center", sm: "center", md: "left" }}>
              <LazyLoadImage
                src={logos[sourceType as keyof typeof logos]}
                rel="preload"
                placeholder={<Skeleton />}
                effect="blur"
                style={{ objectFit: "contain" }}
                height={
                  sourceType === "weatherapi"
                    ? 50
                    : sourceType === "tarlaio"
                    ? 40
                    : 30
                }
              />
            </Flex>
          </Grid.Col>
          <Grid.Col sm={12} md={5}>
            <Group position="right" p={"20px"}>
              <Switch
                onChange={(e) => {
                  onFavChange(e.currentTarget.value);
                  localStorage.setItem("weatherFav", e.currentTarget.value);
                }}
                value={sourceType}
                checked={fav === sourceType}
                style={{
                  borderRadius: 32,
                  backgroundColor: "#0097c4",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
              />
              <Text
                style={{
                  fontSize: "12px",
                  fontFamily: "Poppins-Light",
                  width: rem(100),
                  textAlign: "center",
                }}
              >
                Favorilere Ekle
              </Text>
            </Group>
          </Grid.Col>
        </Grid>
        {isLoading ? (
          <Flex justify="center" bg="transparent">
            <Loader size={30} />
          </Flex>
        ) : (
          <Flex align="center" justify="space-between">
            <UnstyledButton onClick={scrollLeft} pr={10}>
              <CaretLeft size={32} />
            </UnstyledButton>
            <Flex
              ref={scrollContainerRef}
              onMouseDown={onMouseDown}
              onMouseLeave={onMouseEnd}
              onMouseUp={onMouseEnd}
              onMouseMove={onMouseMove}
              style={{
                overflowX: "auto",
                display: "flex",
                gap: "10px",
                padding: "10px",
                cursor: "grab",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {store
                ?.filter((x) => Date.parse(x.time) >= Date.now())
                .map((x) => (
                  <>
                    <Flex
                      style={{
                        paddingTop: "20px",
                        height: "auto",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                      direction="column"
                      align={"space-even"}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: rem(24),
                        color: "#d9d9d9",
                      }}
                    >
                      <Flex direction="column" align={"center"} gap={rem(8)}>
                        <Image src={x.iconUri} width={24} height={24} />
                        <Space h="3px" />
                        <Text
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins-Bold",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#000000"}
                        >
                          {`${x.temperature} °C`}
                        </Text>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontFamily: "Poppins-Regular",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#878787"}
                        >
                          {isToday(new Date(x.time))
                            ? "Bugün"
                            : isTomorrow(new Date(x.time))
                            ? "Yarın"
                            : new Date(x.time).toLocaleDateString("tr-TR", {
                                weekday: "long",
                              })}
                        </Text>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontFamily: "Poppins-Medium",
                            width: "120px",
                            textAlign: "center",
                          }}
                          c={"#000000"}
                        >
                          {new Date(x.time).toLocaleTimeString("tr-TR", {
                            hour: "numeric",
                            minute: "numeric",
                          })}
                        </Text>
                      </Flex>
                      <Space h="30px" />
                    </Flex>
                    <Space w="10px" />
                  </>
                ))}
            </Flex>
            <UnstyledButton onClick={scrollRight} pl={10}>
              <CaretRight size={32} />
            </UnstyledButton>
          </Flex>
        )}
      </Flex>
    </Paper>
  );
}

export default WeatherForecastHourDetailCard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OTPRequest } from "../data/request/LoginRequests";
import AxiosHelper from "../../common/util/AxiosHelper";
import { OTPResponse } from "../data/response/LoginResponses";
import { ServiceState } from "../../common/data/ServiceState";
import { LoginDto } from "../data/dto/LoginDto";

export const RefreshTokenService = createAsyncThunk(
  "login/service/refresh",
  async () => {
    try {
      const otpResponse = await AxiosHelper.getInstance().post<OTPResponse>(
        "corporate/auth/refresh",
        {
          refreshToken: localStorage.getItem("refreshToken"),
        }
      );

      return otpResponse.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<OTPResponse> = {};

const RefreshToken = createSlice({
  name: "login/refreshtoken",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RefreshTokenService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(RefreshTokenService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(RefreshTokenService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default RefreshToken.reducer;

import { Menu, Text, createStyles, rem } from "@mantine/core";
import { useAppDispatch } from "../../../../../../modules/dashboard/store/dashboardHook";
import { useNavigate } from "react-router";

const useMenuStyles = createStyles((theme) => ({
  item: {
    "&[data-hovered]": {},
  },
}));

function MenuList({
  isOpen,
  list: MenuItems,
  handleSelectedItem,
  currentItem,
}: any) {
  const { classes: menu } = useMenuStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const style = {
    height: `${rem(428)}!important`,
    background: "transparent",
    width: "94% !important",
  };

  return (
    <Menu
      opened={true}
      classNames={menu}
      position="left-start"
      styles={(theme) => ({
        dropdown: style,
      })}
    >
      <Menu.Dropdown sx={{ borderRadius: 0, border: 0 }}>
        {MenuItems.map((item: any, index: number) => (
          <Menu.Item
            key={index}
            closeMenuOnClick={false}
            icon={item.icon}
            sx={(theme) => ({
              backgroundColor: `${
                currentItem === item ? theme.colors.white : theme.colors.white
              }!important`,
            })}
            onClick={() => {
              if (!item.menu) {
                navigate(process.env.PUBLIC_URL + item.route);
                window.location.reload();
              }
            }}
            onMouseOver={() => {
              handleSelectedItem(item);
            }}
          >
            <Text
              size="sm"
              variant="medium"
              c="#000000"
              style={{
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
              }}
            >
              {item.label}
            </Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

export default MenuList;

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import WeatherDetail from "../presentation/view/WeatherDetail";

export default function WeatherRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/weatherDetail" element={<WeatherDetail />} />
      </Routes>
    </Provider>
  );
}

import { BaseRequest, PageRequest } from "../../../common/data/BaseRequest";

export class NotificationUpdateRequest extends BaseRequest {
  id: string;
  corporateInboxStatus: number;

  constructor({ id, corporateInboxStatus }: NotificationUpdateRequest) {
    super();
    this.id = id;
    this.corporateInboxStatus = corporateInboxStatus;
  }
}

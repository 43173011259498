import { BaseRequest } from "../../../common/data/BaseRequest";

export class PlantVariantRequest extends BaseRequest {
  PlantId: string | null = null;

  constructor(plantId: string | null) {
    super();
    this.PlantId = plantId;
  }
}

import { Flex, rem, Divider, Skeleton } from "@mantine/core";
import { useState } from "react";
import {
  Notification,
  Icon,
} from "phosphor-react";
import MenuListContract from "./MenuListContract";
import { LazyLoadImage } from "react-lazy-load-image-component";

const left_menu_logo =
  process.env.PUBLIC_URL + "/assets/images/left_menu_logo.png";

export interface MenuItem {
  label: string;
  icon?: Icon;
  route?: string;
  menu?: [MenuItem];
  modal?: MenuModalItem;
  authType: string;
  subType: string;
}

export interface MenuModalItem {
  title: string;
  description: string;
  url: string;
  authType: string;
  subType: string;
}
function LeftMenuContract({ closeButton }: any) {
  const [opened] = useState(false);
  const [currentItem, setCurrentItem] = useState<MenuItem | null>(null);

  const menuItems = [
    {
      label: "Sözleşme Onayı",
      icon: (
        <Notification
          size={rem(24)}
          color="white"
          style={{
            borderRadius: rem(24),
            padding: rem(3),
            backgroundColor: "#7169d2",
          }}
        />
      ),
      route: "/agreement",
    },
  ];
  const handleSelectedItem = (item: MenuItem) => {
    setCurrentItem(item);
  };

  return (
    <Flex direction="column">
      <Flex justify={"end"} px={rem(10)}>
        {closeButton}
      </Flex>

      <Flex
        direction={"row"}
        gap={rem(5)}
        p={rem(20)}
        align={"center"}
        justify={"center"}
      >
        <LazyLoadImage
          src="/assets/images/login_logo.png"
          rel="preload"
          width={170}
          height={"auto"}
          alt="Login Icon Alt"
          placeholder={<Skeleton />}
          effect="blur"
          style={{ objectFit: "contain" }}
        />
      </Flex>

      <Divider size={"1"} color="#FFFFFF" />
      <Flex justify={"center"} align={"center"}>
      <MenuListContract
          isOpen={opened}
          list={menuItems}
          currentItem={currentItem}
          handleSelectedItem={handleSelectedItem}
        />

      </Flex>
    </Flex>
  );
}

export default LeftMenuContract;

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import ChangePassword from "../presentation/view/changepassword";
import Profile from "../presentation/view/Profile";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/profile/changepassword" element={<ChangePassword />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Provider>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";

import FieldDetailRoutes from "../modules/fielddetail/connector/FieldDetailRoutes";
import LoginRoutes from "../modules/login/connector/ModuleRoutes";
import MyFieldsRoutes from "../modules/myfields/connector/ModuleRoutes";
import NotificationsRoutes from "../modules/notifications/connector/ModuleRoutes";
import ProfileRoutes from "../modules/profile/connector/ModuleRoutes";
import SatelliteRoutes from "../modules/satellite/connector/ModuleRoutes";
import UserProfileRoutes from "../modules/userprofile/connector/ModuleRoutes";
import UserRoutes from "../modules/users/connector/ModuleRoutes";
import WeatherRoutes from "../modules/weather/connector/ModuleRoutes";
import SurveyRoutes from "../modules/survey/connector/ModuleRoutes";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import CorporateDashboardRoutes from "../modules/corporatedashboard/connector/ModuleRoutes";
import AxiosHelper from "../modules/common/util/AxiosHelper";
import { OTPResponse } from "../modules/login/data/response/LoginResponses";

const allowedPaths = [
  "/login",
  "/agreement",
  "/users",
  "/users/add",
  "/weatherDetail",
  "/myFields/detail",
  "/myFields",
  "/myfields/add",
  "/myFields/mapview",
  "/myFields/navigation",
  "/profile",
  "/profile/changepassword",
  "/notifications",
  "/userprofile",
  "/satellite",
  "/satellite/:fieldId",
  "/survey/list",
  "/survey/add",
  "/survey/update/:id",
  "/survey/editor/:id",
  "/survey/display/:type/:id",
  "/survey/question/report",
  "/survey/answer/report",
  "/mytask/list",
  "/mytask/detail/list/:id",
  "/mytask/detail/:id",
  "/task/list",
  "/task/add",
  "/task/update/:id",
  "/task/detail/:id",
  "/task/assign/:id",
  "/corporatedashboard",
];

var timeout: NodeJS.Timeout | undefined;

function LoginCheck(props: any) {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    try {
      let token = localStorage.getItem("token");
      let tokenExpirationDate =
        localStorage.getItem("tokenExpirationDate") == null
          ? null
          : parseInt(localStorage.getItem("tokenExpirationDate") ?? "0");
      let expDate = token == null ? null : jwtDecode(token).exp;

      const convertedTimestamp = convertDateToTimestamp(
        new Date().toLocaleTimeString()
      );

      if (
        tokenExpirationDate == null ||
        (tokenExpirationDate != null &&
          tokenExpirationDate < (convertedTimestamp ?? 0)) ||
        (expDate != null &&
          expDate !== undefined &&
          convertDateToTimestamp != null &&
          expDate < (convertedTimestamp ?? 0))
      ) {
        token = null;
        tokenExpirationDate = null;
        expDate = null;
      }

      if (
        tokenExpirationDate == null &&
        window.location.pathname !== "/login"
      ) {
        let weatherFav = localStorage.getItem("weatherFav");
        localStorage.clear();
        if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);

        navigate("/login");
      } else if (
        !allowedPaths.some((allowedPath: string) =>
          window.location.pathname
            .toLowerCase()
            .includes(allowedPath.toLowerCase())
        )
      ) {
        navigate("/corporatedashboard");
      } else if (window.location.pathname === "/login") {
        let weatherFav = localStorage.getItem("weatherFav");
        localStorage.clear();
        if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);
      }

      if (tokenExpirationDate != null) {
        let diff = tokenExpirationDate - (convertedTimestamp ?? 0) - 60;

        clearTimeout(timeout);
        if (diff <= 0) {
          refreshToken();
        } else {
          timeout = setTimeout(() => {
            refreshToken();
          }, diff * 1000);
          return () => clearTimeout(timeout);
        }
      }
    } catch (ex) {
      let weatherFav = localStorage.getItem("weatherFav");
      localStorage.clear();
      if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);

      navigate("/login");
    }
  }, [location]);

  const refreshToken = () => {
    AxiosHelper.getInstance()
      .post<OTPResponse>("corporate/auth/refresh", {
        refreshToken: localStorage.getItem("refreshToken"),
      })
      .then((response) => {
        try {
          var t = new Date();
          t.setSeconds(
            t.getSeconds() +
              ((response.data.data?.token?.expiresIn ?? 0) >
              parseInt(process.env.REACT_APP_MAX_TIMEOUT ?? "900")
                ? parseInt(process.env.REACT_APP_MAX_TIMEOUT ?? "900")
                : response.data.data?.token?.expiresIn ?? 0)
          );

          localStorage.setItem(
            "tokenExpirationDate",
            (t.getTime() / 1000).toString()
          );
          localStorage.setItem(
            "token",
            response.data.data?.token?.accessToken || ""
          );
          localStorage.setItem(
            "refreshToken",
            response.data.data?.token?.refreshToken || ""
          );

          let tokenExpirationDate =
            localStorage.getItem("tokenExpirationDate") == null
              ? null
              : parseInt(localStorage.getItem("tokenExpirationDate") ?? "0");

          const convertedTimestamp = convertDateToTimestamp(
            new Date().toLocaleTimeString()
          );

          if (tokenExpirationDate != null) {
            let diff = tokenExpirationDate - (convertedTimestamp ?? 0) - 60;

            clearTimeout(timeout);
            if (diff <= 0) {
              refreshToken();
            } else {
              timeout = setTimeout(() => {
                refreshToken();
              }, diff * 1000);
            }
          }
        } catch {
          let weatherFav = localStorage.getItem("weatherFav");
          localStorage.clear();
          if (weatherFav !== null)
            localStorage.setItem("weatherFav", weatherFav);

          navigate("/login");
        }
      })
      .catch((error) => {
        let weatherFav = localStorage.getItem("weatherFav");
        localStorage.clear();
        if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);

        navigate("/login");
      });
  };

  const convertDateToTimestamp = (dateString: string) => {
    const date = moment(dateString, "h:mm:ss A");
    if (date.isValid()) {
      const timestamp = date.unix();
      return timestamp;
    } else {
      console.error("Invalid date string format");
      return undefined;
    }
  };

  return (
    <>
      <>
        <LoginRoutes />
        <UserRoutes />
        <WeatherRoutes />
        <FieldDetailRoutes />
        <MyFieldsRoutes />
        <ProfileRoutes />
        <NotificationsRoutes />
        <UserProfileRoutes />
        <SatelliteRoutes />
        <SurveyRoutes />
        <CorporateDashboardRoutes />
      </>
    </>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={<LoginCheck pathName={window.location.pathname} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

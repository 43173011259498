import { useEffect } from "react";

export function useClickOutside(
  callback: (event: MouseEvent) => void,
  refs: React.RefObject<HTMLElement>[]
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (refs.every((ref) => !ref.current?.contains(event.target as Node))) {
        callback(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, refs]);
}

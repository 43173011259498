import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { DeleteFieldResponse } from "../data/response/DeleteFieldResponse";

export const DeleteFieldService = createAsyncThunk(
  "corporate/deleteField",
  async (id: string) => {
    try {
      const response =
        await AxiosHelper.getInstance().delete<DeleteFieldResponse>(
          `/corporate/fields/${id}`
        );
      return response.data;
    } catch (error) {
      console.error(error || "An unknown error occurred.");
    }
  }
);

const initialState: ServiceState<DeleteFieldResponse> = {};

const DeleteFieldSlice = createSlice({
  name: "deleteField",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteFieldService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DeleteFieldService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DeleteFieldService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default DeleteFieldSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OTPRequest } from "../data/request/LoginRequests";
import AxiosHelper from "../../common/util/AxiosHelper";
import { OTPResponse } from "../data/response/LoginResponses";
import { ServiceState } from "../../common/data/ServiceState";

export const OTPService = createAsyncThunk(
  "login/service/confirm",
  async (request: OTPRequest, thunkAPI) => {
    try {
      const otpResponse = await AxiosHelper.getInstance().post<OTPResponse>(
        "corporate/auth/confirm",
        request
      );
      if (otpResponse.data.data?.token) {
        localStorage.setItem(
          "token",
          otpResponse.data.data?.token?.accessToken ?? ""
        );

        localStorage.setItem(
          "refreshToken",
          otpResponse.data.data?.token?.refreshToken ?? ""
        );
      }

      localStorage.setItem(
        "corporateId",
        otpResponse.data?.data?.corporateId ?? ""
      );

      localStorage.setItem(
        "corporateName",
        otpResponse.data?.data?.corporateName ?? ""
      );

      localStorage.setItem(
        "employeeName",
        otpResponse.data?.data?.employeeName ?? ""
      );

      localStorage.setItem(
        "authorityType",
        (otpResponse.data?.data?.authorityType ?? 1).toString()
      );

      localStorage.setItem(
        "subscriptionType",
        (otpResponse.data?.data?.subscriptionType ?? 1).toString()
      );

      var t = new Date();
      t.setSeconds(
        t.getSeconds() +
          ((otpResponse.data?.data?.token?.expiresIn ?? 0) >
          parseInt(process.env.REACT_APP_MAX_TIMEOUT ?? "900")
            ? parseInt(process.env.REACT_APP_MAX_TIMEOUT ?? "900")
            : otpResponse.data?.data?.token?.expiresIn ?? 0)
      );

      localStorage.setItem(
        "tokenExpirationDate",
        (t.getTime() / 1000).toString()
      );

      return otpResponse.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: ServiceState<OTPResponse> = {};

const OTPSlice = createSlice({
  name: "login/login",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(OTPService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(OTPService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(OTPService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default OTPSlice.reducer;

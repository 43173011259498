import {
  Button,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "./../connector/ModuleHook";
import { RootState } from "./../connector/ModuleStore";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import { Info } from "phosphor-react";
import {
  IconSettings,
  IconEdit,
  IconDetails,
  IconClearAll,
} from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { IconSearch, IconSortDescending, IconPlus } from "@tabler/icons-react";
import {
  ClearAnswersService,
  FormListService,
} from "../interractor/SurveyInterractor";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import { useNavigate } from "react-router";
import { FormDto } from "../data/dto/FormDto";
import { ClearAnswersRequest } from "../data/request/ClearAnswersRequest";
import Swal from "sweetalert2";
import moment from "moment";
import { DatePicker, DatesProvider } from "@mantine/dates";
import dayjs from "dayjs";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../app/common/presentation/view/component/mainWrapper")
);

interface SurveysProps {}

const Surveys: React.FC<SurveysProps> = ({}) => {
  const [fetching, setFetching] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const size = useWindowSize();
  const [isDeleteFetching, setIsDeleteFetching] = useState(false);
  const [pageData, setPageData] = useState<FormDto[]>([]);
  const [initialRecords, setInitialRecords] = useState<FormDto[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });

  const [nameQuery, setNameQuery] = useState("");
  const [descriptionQuery, setDescriptionQuery] = useState("");
  const [isConstantFormQuery, setIsConstantFormQuery] = useState<string>("");
  const [creatorQuery, setCreatorQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
  const [debouncedIsConstantFormQuery] = useDebouncedValue(
    isConstantFormQuery,
    200
  );
  const [debouncedDescriptionQuery] = useDebouncedValue(descriptionQuery, 200);
  const [debouncedCreatorQuery] = useDebouncedValue(creatorQuery, 200);

  const [createdDateQuery, setCreatedDateQuery] = useState("");
  const [debouncedCreatedDateQuery] = useDebouncedValue(createdDateQuery, 200);

  const [releaseDateQuery, setReleaseDateQuery] = useState("");
  const [debouncedReleaseDateQuery] = useDebouncedValue(releaseDateQuery, 200);

  const [isSmallThanXl, setIsSmallThanXl] = useState(false);
  const [isSmallThanLg, setIsSmallThanLg] = useState(false);
  const [isSmallThanMd, setIsSmallThanMd] = useState(false);

  const formListService = useSelector(
    (state: RootState) => state.FormListState.value
  );

  const formsData = formListService?.data;

  useEffect(() => {
    const mediaQueryXl = window.matchMedia("(max-width: 88em)");
    setIsSmallThanXl(mediaQueryXl.matches);

    const handleResize = () => {
      setIsSmallThanXl(mediaQueryXl.matches);
    };

    mediaQueryXl.addEventListener("change", handleResize);
    return () => mediaQueryXl.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryLg = window.matchMedia("(max-width: 75em)");
    setIsSmallThanLg(mediaQueryLg.matches);

    const handleResize = () => {
      setIsSmallThanLg(mediaQueryLg.matches);
    };

    mediaQueryLg.addEventListener("change", handleResize);
    return () => mediaQueryLg.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryMd = window.matchMedia("(max-width: 62em)");
    setIsSmallThanMd(mediaQueryMd.matches);

    const handleResize = () => {
      setIsSmallThanMd(mediaQueryMd.matches);
    };

    mediaQueryMd.addEventListener("change", handleResize);
    return () => mediaQueryMd.removeEventListener("change", handleResize);
  }, []);

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    dispatch(FormListService());
  }, []);

  useEffect(() => {
    if (formsData?.forms) {
      const forms = formsData.forms.map((form: FormDto) => {
        return {
          id: form.id,
          name: form.name || "-",
          description: form.description || "-",
          title: form.title || "-",
          subTitle: form.subTitle || "-",
          isConstantFormName: form.isConstantForm ? "Evet" : "Hayır",
          creator: form.creator || "-",
          createdDate: form.createdDate
            ? moment(form.createdDate).add(3, "hour").format("DD.MM.YYYY")
            : "-",
          releaseDate: form.releaseDate
            ? moment(form.releaseDate).add(3, "hour").format("DD.MM.YYYY")
            : "-",
        } as FormDto;
      });
      setInitialRecords(forms);
    }
  }, [formsData]);

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({
          name,
          description,
          isConstantFormName,
          creator,
          createdDate,
          releaseDate,
        }) => {
          if (
            debouncedNameQuery !== "" &&
            name &&
            !`${name}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedDescriptionQuery !== "" &&
            descriptionQuery &&
            !`${description}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedDescriptionQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedCreatorQuery !== "" &&
            creatorQuery &&
            !`${creator}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedCreatorQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedIsConstantFormQuery &&
            isConstantFormQuery &&
            !`${isConstantFormName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedIsConstantFormQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedCreatedDateQuery !== "" &&
            createdDateQuery &&
            createdDate !== debouncedCreatedDateQuery
          ) {
            return false;
          }
          if (
            debouncedReleaseDateQuery !== "" &&
            releaseDateQuery &&
            releaseDate !== debouncedReleaseDateQuery
          ) {
            return false;
          }
          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "description") {
        sortedData.sort((a, b) => {
          const valueA = a.description ? a.description.toLowerCase() : "";
          const valueB = b.description ? b.description.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "creator") {
        sortedData.sort((a, b) => {
          const valueA = (a.creator ?? "").toLowerCase();
          const valueB = (b.creator ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "isConstantFormName") {
        sortedData.sort((a, b) => {
          const valueA = (a.isConstantFormName ?? "").toLowerCase();
          const valueB = (b.isConstantFormName ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "createdDate") {
        sortedData.sort((a, b) => {
          const dateA = a.createdDate
            ? moment(a.createdDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB = b.createdDate
            ? moment(b.createdDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      } else if (sortStatus.columnAccessor === "releaseDate") {
        sortedData.sort((a, b) => {
          const dateA =
            a.releaseDate !== "-"
              ? moment(a.releaseDate, "DD.MM.YYYY")
              : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB =
            b.releaseDate !== "-"
              ? moment(b.releaseDate, "DD.MM.YYYY")
              : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus]);

  const handleButtonClick = () => {
    navigate("/survey/add");
  };

  const columns = [
    {
      accessor: "name",
      title: "Form Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen isimdeki formları ara."
          placeholder="Form adı ara..."
          icon={<IconSearch size={16} />}
          value={nameQuery}
          onChange={(e) => setNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: nameQuery !== "",
    },
    // {
    //   accessor: "description",
    //   title: "Açıklama",
    //   textAlignment: "center" as DataTableColumnTextAlignment,
    //   sortable: true,
    //   ellipsis: true,
    //   filter: (
    //     <TextInput
    //       label="İsim"
    //       description="Belirtilen açıklamayı içeren formları ara."
    //       placeholder="Kullanıcı adı ara..."
    //       icon={<IconSearch size={16} />}
    //       value={descriptionQuery}
    //       onChange={(e) => setDescriptionQuery(e.currentTarget.value)}
    //     />
    //   ),
    //   filtering: descriptionQuery !== "",
    // },
    // {
    //   accessor: "isConstantFormName",
    //   title: "Tarla Ziyaretçi Formu",
    //   textAlignment: "center" as DataTableColumnTextAlignment,
    //   sortable: true,
    //   ellipsis: true,
    //   filter: (
    //     <TextInput
    //       label="Tarla Ziyaretçi Formu"
    //       description="Tarla ziyaretçi formu açıklamayı içeren formları ara."
    //       placeholder="Tarla ziyaretçi formu ara..."
    //       icon={<IconSearch size={16} />}
    //       value={isConstantFormQuery}
    //       onChange={(e) => setIsConstantFormQuery(e.currentTarget.value)}
    //     />
    //   ),
    //   filtering: isConstantFormQuery !== "",
    // },
    {
      accessor: "creator",
      title: "Oluşturan",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Oluşturan"
          description="Belirtilen kullanıcı adını içeren formları ara."
          icon={<IconSearch size={16} />}
          value={creatorQuery}
          onChange={(e) => setCreatorQuery(e.currentTarget.value)}
        />
      ),
      filtering: creatorQuery !== "",
    },
    {
      accessor: "createdDate",
      title: "Oluşturulma Tarihi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            maxDate={new Date()}
            value={
              createdDateQuery == ""
                ? null
                : moment(createdDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setCreatedDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={createdDateQuery == ""}
            color="red"
            onClick={() => {
              setCreatedDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: createdDateQuery !== "",
    },
    {
      accessor: "releaseDate",
      title: "Son Değişiklik Tarihi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            maxDate={new Date()}
            value={
              releaseDateQuery == ""
                ? null
                : moment(releaseDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setReleaseDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={releaseDateQuery == ""}
            color="red"
            onClick={() => {
              setReleaseDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: releaseDateQuery !== "",
    },
    {
      accessor: "isRead",
      title: "Aksiyon",
      textAlignment: "center" as DataTableColumnTextAlignment,

      render: (record: FormDto, index: number) => (
        <Group spacing={4} position="center" noWrap>
          <Tooltip label="Editor">
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#FFF",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "80px",
                fontWeight: 300,
                backgroundColor: "#7169d2",
              }}
              onClick={() => {
                navigate("/survey/editor/" + record.id);
              }}
            >
              <IconSettings />
            </Button>
          </Tooltip>
          <Tooltip label="Düzenle">
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#FFF",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "80px",
                fontWeight: 300,
                backgroundColor: "#7169d2",
              }}
              onClick={() => {
                navigate("/survey/update/" + record.id);
              }}
            >
              <IconEdit />
            </Button>
          </Tooltip>
          <>
            <Tooltip label="Tek Sayfa Görümüm">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "120px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  navigate("/survey/display/1/" + record.id);
                }}
              >
                Görüntüle
              </Button>
            </Tooltip>
            {/* <Tooltip label="Tek Sorulu Görünüm">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "120px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  navigate("/survey/display/2/" + record.id);
                }}
              >
                Görüntüle
              </Button>
            </Tooltip> */}
            {/* <Tooltip label="Cevapları Sil">
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#FFF",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "80px",
                  fontWeight: 300,
                  backgroundColor: "#7169d2",
                }}
                onClick={() => {
                  publishFormHandler(record.id ?? "");
                }}
              >
                <IconClearAll />
              </Button>
            </Tooltip> */}
          </>
        </Group>
      ),
    },
  ];

  const publishFormHandler = (id: string) => {
    Swal.fire({
      title: "Form'u ait tüm cevapları silmek istediğinize emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Yayınla!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        clearAnswersForm(id);
      }
    });
  };

  const clearAnswersForm = async (id: string) => {
    let response = await dispatch(
      ClearAnswersService({
        formId: id,
      } as ClearAnswersRequest)
    ).unwrap();
    if (response) {
      if (response?.success) {
        Swal.fire({
          title: "Form cevapları başarı ile silinmiştir.",
          icon: "success",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      } else {
        Swal.fire({
          title: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
          icon: "warning",
          confirmButtonColor: "#0097c4",
          confirmButtonText: "Tamam",
        });
      }
    }
  };

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper breadCrumbs={[{ title: "Form", href: "#" }]} title="Form">
        <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <LoadingOverlay
                visible={isDeleteFetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              />
              <Grid>
                <Grid.Col md={12} lg={8}>
                  <Group position={isSmallThanLg ? "center" : "left"}>
                    <Info size={isSmallThanXl ? 26 : 32} />

                    <Text
                      style={{
                        fontSize: isSmallThanXl ? "14px" : "16px",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Form düzenlemek için EDİTOR butonunu kullanınız.
                    </Text>
                  </Group>
                </Grid.Col>
                <Grid.Col lg={6} md={12}>
                  <Flex>
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "250px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={handleButtonClick}
                    >
                      <IconPlus /> Form Ekle
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Space h="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <DataTable
                    withColumnBorders
                    highlightOnHover
                    minHeight={250}
                    noRecordsText="Form Bulunamadı..."
                    className="datatable"
                    records={pageData}
                    columns={columns}
                    totalRecords={sortedData.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
          </Suspense>
        </Paper>
      </MainWrapper>
    </Suspense>
  );
};

export default Surveys;

import { Image, rem } from "@mantine/core";
import { notifications } from "@mantine/notifications";

const info =
  process.env.PUBLIC_URL + "/NewDesign/assets/icons/notification/info.svg";
const close =
  process.env.PUBLIC_URL + "/NewDesign/assets/icons/notification/close.svg";
const check =
  process.env.PUBLIC_URL + "/NewDesign/assets/icons/notification/check.svg";
const warning =
  process.env.PUBLIC_URL + "/NewDesign/assets/icons/notification/warning.svg";

var notificationId = 0;

export function showErrorNotification(message: string) {
  return notifications.show({
    message,
    withCloseButton: false,
    icon: <Image src={close} width={rem(24)} height={rem(24)} />,
    styles: (theme) => ({
      icon: { background: "transparent" },
      description: {
        fontFamily: "Satoshi-Medium",
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      root: {
        background: theme.colors.danger[0],
        border: `1px solid ${theme.colors.danger[1]}`,
        maxWidth: rem(540),
        boxShadow: "0px 6px 10px 0px #0000001A",
      },
    }),
  });
}

export function showInfoNotification(message: string) {
  return notifications.show({
    message,
    withCloseButton: false,
    icon: <Image src={info} width={rem(24)} height={rem(24)} />,
    styles: (theme) => ({
      icon: { background: "transparent" },
      description: {
        fontFamily: "Satoshi-Medium",
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      root: {
        background: theme.colors["primary-light"][0],
        border: `1px solid ${theme.colors["primary-light"][1]}`,
        boxShadow: "0px 6px 10px 0px #0000001A",
        maxWidth: rem(540),
      },
    }),
  });
}

export function showWarningNotification(message: string) {
  return notifications.show({
    message,
    withCloseButton: false,
    icon: <Image src={warning} width={rem(24)} height={rem(24)} />,
    styles: (theme) => ({
      icon: { background: "transparent" },
      description: {
        fontFamily: "Satoshi-Medium",
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      root: {
        background: theme.colors.warning[0],
        border: `1px solid ${theme.colors.warning[2]}`,
        maxWidth: rem(540),
        boxShadow: "0px 6px 10px 0px #0000001A",
      },
    }),
  });
}

export function showSuccessNotification(message: string) {
  return notifications.show({
    message,
    withCloseButton: false,

    icon: <Image src={check} width={rem(24)} height={rem(24)} />,
    styles: (theme) => ({
      icon: { background: "transparent" },
      description: {
        fontFamily: "Satoshi-Medium",
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      root: {
        background: theme.colors.secondary[0],
        borderRadius: `1px solid ${theme.colors.secondary[1]}`,
        boxShadow: "0px 6px 10px 0px #0000001A",
        maxWidth: rem(540),
      },
    }),
  });
}

export function showLoadingNotification(message: string) {
  const id = ++notificationId;
  notifications.show({
    id: id.toString(),
    loading: true,
    autoClose: false,
    message,
    withCloseButton: false,

    icon: <Image src={check} width={rem(24)} height={rem(24)} />,
    styles: (theme) => ({
      icon: { background: "transparent" },
      description: {
        fontFamily: "Satoshi-Medium",
        fontSize: rem(14),
        lineHeight: rem(20),
      },
      root: {
        background: theme.colors.secondary[0],
        borderRadius: `1px solid ${theme.colors.secondary[1]}`,
        boxShadow: "0px 6px 10px 0px #0000001A",
        maxWidth: rem(540),
      },
    }),
  });
  return id.toString();
}

export function showLoadingUpdateNotification(
  message: string,
  loading: boolean
) {
  return loading
    ? notifications.show({
        id: "loading",
        message,
        autoClose: false,
        withCloseButton: false,
        loading,
        styles: (theme) => ({
          icon: { background: "transparent" },
          description: {
            fontFamily: "Satoshi-Medium",
            fontSize: rem(14),
            lineHeight: rem(20),
          },
          root: {
            background: theme.colors.secondary[0],
            borderRadius: `1px solid ${theme.colors.secondary[1]}`,
            boxShadow: "0px 6px 10px 0px #0000001A",
            maxWidth: rem(540),
          },
        }),
      })
    : notifications.update({
        id: "loading",
        message,
        loading,
        autoClose: 2000,
        withCloseButton: false,
        icon: <Image src={check} width={rem(24)} height={rem(24)} />,
        styles: (theme) => ({
          icon: { background: "transparent" },
          description: {
            fontFamily: "Satoshi-Medium",
            fontSize: rem(14),
            lineHeight: rem(20),
          },
          root: {
            background: theme.colors.secondary[0],
            borderRadius: `1px solid ${theme.colors.secondary[1]}`,
            boxShadow: "0px 6px 10px 0px #0000001A",
            maxWidth: rem(540),
          },
        }),
      });
}

export function showToasterNotification(title: string, message: string) {
  return notifications.show({
    title,
    message,
    withCloseButton: false,
    sx: {
      position: "relative",
      "&::before": {
        content: '""',
        height: "100%",
        width: rem(4),
        position: "absolute",
        top: 0,
        left: 0,
        borderTopLeftRadius: rem(16),
        borderBottomLeftRadius: rem(16),
      },
    },
    styles: (theme) => ({
      title: {
        fontFamily: "Satoshi-Bold",
        fontSize: rem(14),
        lineHeight: rem(20),
        color: theme.colors.gray[8],
      },
    }),
  });
}

export function hideNotification(id: string) {
  notifications.hide(id);
}

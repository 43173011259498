import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import { ServiceState } from "../../common/data/ServiceState";
import { NotificationRequest } from "../data/request/NotificationRequest";
import { NotificationResponse } from "../data/response/NotificationResponse";

// Async Thunk action creator
export const notificationGetCorporateInbox = createAsyncThunk(
  "notification/corporateInbox",
  async (request: NotificationRequest, thunkAPI) => {
    try {
      var sQ = "";

      request.query?.forEach(function (value, key) {
        sQ += `&searchColumn[${key}]=${value}`;
      });

      const response =
        await AxiosHelper.getInstance().get<NotificationResponse>(
          `corporate/inbox?page=${request.Page}&pageSize=${
            request.Size
          }&sortColumnName=${request.sortStatus?.columnAccessor}&isDescending=${
            request.sortStatus?.direction === "desc"
          }${sQ}`
        );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<NotificationResponse> = {};

export const getNotificationsSlice = createSlice({
  name: "notification/corporateInbox",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(notificationGetCorporateInbox.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(notificationGetCorporateInbox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(notificationGetCorporateInbox.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default getNotificationsSlice.reducer;

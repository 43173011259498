import './App.css';
import Router from './routes/Routes';

function App() {
  return (
    <Router />
  );
}

export default App;

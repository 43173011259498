import {
  Container,
  Flex,
  Grid,
  List,
  MediaQuery,
  Paper,
  Skeleton,
  Space,
  Text,
  Transition,
  rem,
} from "@mantine/core";

import React, { useRef, useState } from "react";

import { IconLogout } from "@tabler/icons-react";
import { CaretDown, UserCircle } from "phosphor-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useClickOutside } from "./useClickOutside";
import ImeceBreadCrump, { BreadcrumbModel } from "../breadcrumb";


const profileIcon = process.env.PUBLIC_URL + "/assets/images/profile_icon.png";

interface HeaderProps {
  breadCrumbs?: Array<BreadcrumbModel>;
  title?: string;
}

function Header({ breadCrumbs, title }: HeaderProps) {
  const navigate = useNavigate();

  const profile = {
    corporateName: localStorage.getItem("corporateName"),
    employeeName: localStorage.getItem("employeeName"),
    authorityType: parseInt(localStorage.getItem("authorityType") ?? "1"),
  };

  const [opened, setOpened] = useState(false);

  const scaleY = {
    in: { opacity: 1, transform: "scaleY(1)" },
    out: { opacity: 0, transform: "scaleY(0)" },
    common: { transformOrigin: "top" },
    transitionProperty: "transform, opacity",
  };
  const flexRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const SignOut = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  useClickOutside(
    (event) => {
      if (
        dropdownRef.current &&
        dropdownRef.current.contains(event.target as Node)
      ) {
        return;
      }
      setOpened(false);
    },
    [flexRef, dropdownRef]
  );

  return (
    <Container fluid style={{ width: "100%" }}>
      <Grid style={{ width: "100%" }}>
        <Grid.Col
          span={12}
          md={6}
          sm={6}
          xs={12}
          order={2}
          orderXs={2}
          orderSm={1}
          orderMd={1}
        >
          <Flex
            direction="column"
            align={{ base: "center", xs: "center", sm: "start", md: "start" }}
            justify={{ base: "center", xs: "center", sm: "start", md: "start" }}
          >
            <MediaQuery
              smallerThan={"md"}
              styles={{ fontSize: "22px !important" }}
            >
              <Text style={{ fontSize: "28px", fontFamily: "Poppins-Light" }}>
                {title}
              </Text>
            </MediaQuery>
            <ImeceBreadCrump bcItems={breadCrumbs} />
          </Flex>
        </Grid.Col>
        <Grid.Col
          span={12}
          md={6}
          sm={6}
          xs={12}
          order={1}
          orderXs={1}
          orderSm={2}
          orderMd={2}
        >
          <Flex
            direction={"column"}
            align={{
              base: "center",
              xs: "center",
              sm: "end",
              md: "end",
            }}
            sx={{ position: "relative" }}
          >
            <Flex
              direction="row"
              onClick={() => setOpened((opened) => !opened)}
              ref={flexRef}
              align={{
                base: "center",
                xs: "center",
                sm: "center",
                md: "center",
              }}
              sx={{
                cursor: "pointer",
              }}
            >
              <LazyLoadImage
                src={profileIcon}
                rel="preload"
                width={50}
                height={50}
                alt="Login Icon Alt"
                placeholder={<Skeleton />}
                effect="blur"
                style={{ objectFit: "contain" }}
              />
              <Space w="md" h="md" />
              <Flex direction="column" style={{ paddingRight: rem(18) }}>
                <MediaQuery
                  smallerThan={"md"}
                  styles={{ fontSize: "12px !important" }}
                >
                  <Text
                    fs="xl"
                    style={{ fontSize: "14px", fontFamily: "Poppins-Medium" }}
                  >
                    {profile?.employeeName} -{" "}
                    {profile?.authorityType === 1
                      ? "Yetkili"
                      : profile?.authorityType === 2
                      ? "Mühendis"
                      : profile?.authorityType === 3
                      ? "Üretici"
                      : ""}
                  </Text>
                </MediaQuery>

                <MediaQuery
                  smallerThan={"md"}
                  styles={{ fontSize: "10px !important" }}
                >
                  <Text
                    fs="xl"
                    pt={".05rem"}
                    style={{
                      color: "#878787",
                      fontSize: "12px",
                      fontFamily: "Poppins-Regular",
                    }}
                  >
                    {profile?.corporateName} <br />
                  </Text>
                </MediaQuery>
              </Flex>
              <MediaQuery
                smallerThan={"md"}
                styles={{
                  height: "1.2rem !important",
                  width: "1.2rem !important",
                }}
              >
                <CaretDown size={rem(22)} weight="regular" />
              </MediaQuery>
            </Flex>
            {/* Dropdown Menu */}
            <Transition
              mounted={opened}
              transition={scaleY}
              duration={200}
              timingFunction="ease"
            >
              {(styles) => (
                <Paper
                  shadow="md"
                  className="header-dropdown-menu-paper"
                  ref={dropdownRef}
                >
                  <List
                    className="header-dropdown-list"
                    center
                    spacing="xs"
                    size="sm"
                  >
                    <NavLink to="/userprofile">
                      <List.Item
                        icon={<UserCircle size={rem(20)} color="black" />}
                      >
                        Profili Gör
                      </List.Item>
                    </NavLink>
                    <NavLink to="#" onClick={SignOut}>
                      <List.Item
                        icon={<IconLogout size={rem(20)} color="black" />}
                        style={{ marginTop: ".4rem", marginBottom: ".2rem" }}
                      >
                        Çıkış
                      </List.Item>
                    </NavLink>
                  </List>
                </Paper>
              )}
            </Transition>
          </Flex>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export default Header;

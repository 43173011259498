import {
  Anchor,
  Breadcrumbs,
  Flex,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { House } from "phosphor-react";

interface BreadCrumpProps {
  bcItems?: Array<BreadcrumbModel>;
}
export interface BreadcrumbModel {
  title: string | JSX.Element;
  href?: string;
}
export default function ImeceBreadCrump({ bcItems }: BreadCrumpProps) {
  const theme = useMantineTheme();
  return (
    <>
      {bcItems ? (
        <Breadcrumbs style={{ fontSize: "0rem" }}>
          {new Array<BreadcrumbModel>({
            title: (
              <Flex>
                <House size={rem(16)} />
                <Text pl={".3rem"}>Sözleşme</Text>
              </Flex>
            ),
            href: "/agreement",
          })
            .concat(bcItems)
            ?.map((item, index) => (
              <Text
                key={index}
                mt={rem(2)}
                c={theme.colors.dark[4]}
                size="xs"
                variant="medium"
                style={{ cursor: item.href ? "default" : "pointer" }}
              >
                {item.title}
              </Text>
            ))}
        </Breadcrumbs>
      ) : null}
    </>
  );
}


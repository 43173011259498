import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import {
  WeatherHourlyResponsesDays,
  WeatherHourlyWeatherCastResponse,
  WeatherResponse,
  WeatherResponseDaily,
  WeatherResponseDays,
} from "../data/response/WeatherResponses";
import { ServiceError, ServiceState } from "../../common/data/ServiceState";
import { WeatherRequests } from "../data/request/WeatherRequests";
// Async Thunk action creator
export const fetchWeatherWeatherBitData = createAsyncThunk(
  "weather/fetchWeatherWeatherBitData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<WeatherResponse>(
        `/services/weatherbit/daily?Latitude=${request.lat}&Longitude=${request.long}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherHourlyWeatherBitData = createAsyncThunk(
  "weather/fetchHourlyWeatherWeatherBitData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherHourlyWeatherCastResponse>(
          `/services/weatherbit/hourly?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherWeatherApiData = createAsyncThunk(
  "weather/fetchWeatherWeatherApiData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<WeatherResponseDays>(
        `/services/weatherapi/daily?Latitude=${request.lat}&Longitude=${request.long}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherHourlyWeatherApiData = createAsyncThunk(
  "weather/fetchWeatherHourlyWeatherApiData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherHourlyWeatherCastResponse>(
          `/services/weatherapi/hourly?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherVisualCrossData = createAsyncThunk(
  "weather/fetchWeatherVisualCrossData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<WeatherResponse>(
        `/services/visualcross/daily?Latitude=${request.lat}&Longitude=${request.long}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherHourlyVisualCrossData = createAsyncThunk(
  "weather/fetchWeatherHourlyVisualCrossData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherHourlyWeatherCastResponse>(
          `/services/visualcross/hourly?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherTarlaIOData = createAsyncThunk(
  "weather/fetchWeatherTarlaIOData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherResponseDaily>(
          `/services/tarlaio/daily?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchHourlyWeatherTarlaIOData = createAsyncThunk(
  "weather/fetchHourlyWeatherTarlaIOData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherHourlyWeatherCastResponse>(
          `/services/tarlaio/hourly?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherAccuWeatherData = createAsyncThunk(
  "weather/fetchWeatherAccuWeatherData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response = await AxiosHelper.getInstance().get<WeatherResponseDays>(
        `/services/accuweather/daily?Latitude=${request.lat}&Longitude=${request.long}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchWeatherHourlyAccuWeatherData = createAsyncThunk(
  "/services/weather/fetchWeatherHourlyAccuWeatherData",
  async (request: WeatherRequests, thunkAPI) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<WeatherHourlyResponsesDays>(
          `/services/accuweather/hourly?Latitude=${request.lat}&Longitude=${request.long}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialStateHourlies: ServiceState<WeatherHourlyResponsesDays> = {};

const initialStateForecastHour: ServiceState<WeatherHourlyWeatherCastResponse> =
  {};

const initialStateDays: ServiceState<WeatherResponseDays> = {};

const initialState: ServiceState<WeatherResponse> = {};

const initialStateDaily: ServiceState<WeatherResponseDaily> = {};

export const weatherWeatherBitSlice = createSlice({
  name: "weather/weatherBit",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherWeatherBitData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherWeatherBitData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherWeatherBitData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = new ServiceError();
        // TODO : error
      });
  },
});

export const weatherHourlyWeatherBitSlice = createSlice({
  name: "weather/hourlyWeatherBit",
  initialState: initialStateForecastHour,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherHourlyWeatherBitData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherHourlyWeatherBitData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherHourlyWeatherBitData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherAccuWeatherSlice = createSlice({
  name: "weather/accuWeather",
  initialState: initialStateDays,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherAccuWeatherData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherAccuWeatherData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherAccuWeatherData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherHourlyAccuWeatherSlice = createSlice({
  name: "weather/accuHourlyWeather",
  initialState: initialStateHourlies,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherHourlyAccuWeatherData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherHourlyAccuWeatherData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherHourlyAccuWeatherData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherTarlaIOSlice = createSlice({
  name: "weather/tarlaio",
  initialState: initialStateDaily,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherTarlaIOData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherTarlaIOData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherTarlaIOData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherHourlyTarlaIOSlice = createSlice({
  name: "weather/hourlytarlaio",
  initialState: initialStateForecastHour,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHourlyWeatherTarlaIOData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchHourlyWeatherTarlaIOData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchHourlyWeatherTarlaIOData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherVisualCrossSlice = createSlice({
  name: "weather/visualcross",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherVisualCrossData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherVisualCrossData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherVisualCrossData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherHourlyVisualCrossSlice = createSlice({
  name: "weather/hourlyvisualcross",
  initialState: initialStateForecastHour,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherHourlyVisualCrossData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherHourlyVisualCrossData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherHourlyVisualCrossData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherWeatherAPISlice = createSlice({
  name: "weather/weatherapi",
  initialState: initialStateDays,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherWeatherApiData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherWeatherApiData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherWeatherApiData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export const weatherHourlyWeatherAPISlice = createSlice({
  name: "weather/hourlyweatherapi",
  initialState: initialStateForecastHour,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherHourlyWeatherApiData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchWeatherHourlyWeatherApiData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(fetchWeatherHourlyWeatherApiData.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { loggerMiddleware } from "../../common/domain/middleware/loggerMiddleware";
import { getFieldInfoSlice } from "../../fielddetail/interactor/FieldDetailInteractor";
import MyFieldInteractor from "../../myfields/interactor/MyFieldInteractor";
import {
  weatherAccuWeatherSlice,
  weatherHourlyAccuWeatherSlice,
  weatherHourlyTarlaIOSlice,
  weatherHourlyVisualCrossSlice,
  weatherHourlyWeatherAPISlice,
  weatherHourlyWeatherBitSlice,
  weatherTarlaIOSlice,
  weatherVisualCrossSlice,
  weatherWeatherAPISlice,
  weatherWeatherBitSlice,
} from "../interactor/WeatherInteractor";
import { ModuleMiddleware } from "./ModuleMiddleware";
const rootReducer = combineReducers({
  weatherAccuState: weatherAccuWeatherSlice.reducer,
  weatherTarlaIOOState: weatherTarlaIOSlice.reducer,
  weatherBitState: weatherWeatherBitSlice.reducer,
  weatherVisualCrossState: weatherVisualCrossSlice.reducer,
  weatherApiState: weatherWeatherAPISlice.reducer,
  weatherHourlyAccuState: weatherHourlyAccuWeatherSlice.reducer,
  weatherHourlyTarlaIOOState: weatherHourlyTarlaIOSlice.reducer,
  weatherHourlyBitState: weatherHourlyWeatherBitSlice.reducer,
  weatherHourlyVisualCrossState: weatherHourlyVisualCrossSlice.reducer,
  weatherHourlyApiState: weatherHourlyWeatherAPISlice.reducer,
  myFieldState: MyFieldInteractor,
  getFieldInfoState: getFieldInfoSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ModuleMiddleware,
      loggerMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;

import axios, { AxiosInstance } from "axios";

class AxiosHelper {
  private static instance: AxiosInstance;

  private constructor() {}

  static getInstance(noAuth: Boolean = false): AxiosInstance {
    if (noAuth) {
      return axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "tr",
        },
        responseType: "json",
        timeout: 10000
      });
    }

    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Accept-Language": "tr",
      },
      responseType: "json",
    });
  }
}

export default AxiosHelper;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { DistrictRequest } from "../data/request/DistrictRequest";
import { DistrictResponse } from "../data/response/DistrictResponse";
import { PlantRequest } from "../data/request/PlantRequest";
import { PlantsResponse } from "../data/response/PlantsResponse";

export const PlantService = createAsyncThunk(
  "plants/getPlants",
  async (plant: PlantRequest) => {
    try {
      const response = await AxiosHelper.getInstance().get<PlantsResponse>(
        `parameter/plants?plantType=${plant.PlantType}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState: ServiceState<PlantsResponse> = {};

const PlantSlice = createSlice({
  name: "plants",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PlantService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(PlantService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(PlantService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO: Handle the error
      });
  },
});

export default PlantSlice.reducer;

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import Satellite from "../presentation/view/Satellite";
import SatelliteAllFields from "../../myfields/presentation/view/SatelliteAllFields";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/satellite" element={<Satellite />} />
        <Route path="/satellite/:fieldId" element={<Satellite />} />
      </Routes>
    </Provider>
  );
}

import { BaseRequest } from "../../../common/data/BaseRequest";

export class AgreementFetchRequest extends BaseRequest {
  constructor(
    public language: string
  ) {
    super();
  }
}

export class AgreementAcceptRequest extends BaseRequest {
  constructor(
    public agreementId: string,
  ) {
    super();
  }
}

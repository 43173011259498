import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mantine/core";
import {
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useSearchParams } from "react-router-dom";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import AxiosHelper from "../../../common/util/AxiosHelper";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useAppDispatch } from "../../../satellite/connector/ModuleHook";
import { MyFieldResponse } from "../../data/response/MyFieldResponses";
import { Field } from "../../../notifications/presentation/types/field";
import { set } from "lodash";

interface AccountProps {
  seasonId?: string;
}

const NavigationFields: React.FC<AccountProps> = () => {
  const [fetching, setFetching] = useState(false);
  const [fieldState, setFieldState] = useState<any>([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [userLocation, setUserLocation] = useState<any | null>(null);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [destination, setDestination] = useState<{ lat: number; lng: number }>({
    lat: 41.0082,
    lng: 28.9784,
  });
  const [count, setCount] = useState(0);
  const dispatch = useAppDispatch();
  const [queryParams] = useSearchParams();
  const queryParam = queryParams.get("id");
  const origin = { lat: 41.015137, lng: 28.97953 }; // Default starting point

  // Kullanıcı konumunu alma
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          alert("Mevcut konum alınamadı");
        }
      );
    } else {
      setError("Tarayıcı konum servislerini desteklemiyor");
    }
  }, []);

  // API'den tarlaları çekme
  useEffect(() => {
    const getData = async () => {
      const response = await AxiosHelper.getInstance().get<MyFieldResponse>(
        "corporate/fields"
      );
      if (response) {
        setFieldState(response.data.data.fields);
      }
    };
    getData();
  }, []);

  // Sadece queryParam ve fieldState güncellendiğinde hedefi ayarla
  useEffect(() => {
    if (queryParam && fieldState.length > 0) {
      const getFieldInfoState: Field = fieldState.find(
        (item: any) => item.id === queryParam
      );
      if (getFieldInfoState?.address) {
        setDestination({
          lat: getFieldInfoState.address.latitude,
          lng: getFieldInfoState.address.longitude,
        });

        setCount(0);
      } else {
        alert("Konum bilgisi alınamadı.");
      }
    }
  }, [queryParam, fieldState]);

  const handleDirectionsCallback = (result: any, status: any) => {
    if (count < 4) {
      if (status === "OK") {
        setDirectionsResponse(result);
        setCount((prev) => prev + 1);
      } else {
        setError(`Yol tarifi alınamadı: ${status}`);
      }
    }
  };

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Tarlalarım", href: "/myFields" },
        { title: "Harita Görünümü", href: "/myFields/mapview" },
        { title: "Navigasyon", href: "#" },
      ]}
      title="Navigasyon"
    >
      <Box>
        <LoadScript googleMapsApiKey="AIzaSyAHcH4YdYOtEpu6FF9EMQku65TFMCLfYM4">
          <GoogleMap
            id="direction-example"
            mapContainerStyle={{ height: "500px", width: "100%" }}
            zoom={14}
            center={origin}
          >
            {userLocation && destination && (
              <DirectionsService
                options={{
                  origin: userLocation, // Kullanıcının mevcut konumu
                  destination: destination, // Hedef konumu
                  travelMode: "DRIVING" as any, // Seyahat modu
                }}
                callback={handleDirectionsCallback}
              />
            )}

            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}

            {error && <div style={{ color: "red" }}>{error}</div>}
          </GoogleMap>
        </LoadScript>
      </Box>
    </MainWrapper>
  );
};

export default NavigationFields;

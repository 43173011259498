import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import {
  showErrorNotification,
  showInfoNotification,
} from "../../component/miniNotification";

export const loggerMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
      await showErrorNotification(
        action.error?.data?.message ?? "Bir hata meydana geldi."
      );
    } else if (action?.error) {
      await showErrorNotification(
        action.error?.data?.message ?? "Bir hata meydana geldi."
      );
    } else if (
      action.payload !== undefined &&
      action.payload.Results?.length > 0
    ) {
      if (
        action.payload.Results.find(
          (x: any) =>
            x.ErrorCode === "SessionNotFound" ||
            x.ErrorCode === "79388#AlreadyLoggedIn" ||
            x.ErrorCode === "SessionNotExists"
        )
      ) {
        localStorage.clear();
        await api.dispatch(push(`${process.env.PUBLIC_URL}/login`));
        window.location.reload();
        showInfoNotification(
          action.payload.Results.find(
            (x: any) =>
              x.ErrorCode === "SessionNotFound" ||
              x.ErrorCode === "79388#AlreadyLoggedIn"
          ).ErrorMessage ?? "Oturumunuz Sonlandı"
        );
      } else if (
        action.payload.Results.find(
          (x: any) =>
            x.ErrorCode === "WaitingResult" ||
            x.ErrorCode === "330674#WrongIBAN" ||
            x.ErrorCode === "GetCustomerExceptionCheckMessage" ||
            x.ErrorMessage ===
              "The remote server returned an error: (400) Bad Request." ||
            x.ErrorMessage ===
              "Girilen IBAN'a ait banka bilgileri alınamadı." ||
            x.ErrorCode === "CaptchaRequired"
        )
      ) {
      } else {
        action.payload.Results.filter(
          (x: any) => x.IsFriendly === true
        ).forEach(async (y: any) => {
          await showInfoNotification(
            y?.ErrorMessage ?? "Bir hata meydana geldi."
          );
        });
      }
    }
    return await next(action);
  };

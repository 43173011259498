import React, { Suspense, lazy } from "react";
import {
  Tabs,
  Paper,
  rem,
  LoadingOverlay,
  Grid,
  MediaQuery,
} from "@mantine/core";
import CustomLoadingOverlay from "../../../../../../modules/utils/CustomLoadingOverlay";

const MainWrapper = lazy(() => import("../mainWrapper"));
const MultiAddUsersCard = lazy(() => import("./MultiAddUsersCard"));
const AddUsersCard = lazy(() => import("./AddUsersCard"));

const UsersTabs: React.FC = () => {
  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[
          { title: "Kullanıcılar", href: "/users" },
          { title: "Kullanıcı Ekle", href: "#" },
        ]}
        title="Kullanıcı Ekle"
      >
        <Suspense fallback={<CustomLoadingOverlay></CustomLoadingOverlay>}>
          <Paper
            shadow="md"
            p={20}
            h={rem("%100")}
            className="widgets profile-paper"
            bg="white"
          >
            <Tabs color="#eef0f8" variant="pills" defaultValue="first">
              <Grid>
                <Grid.Col span={12} style={{ color: "#eef0f8" }}>
                  <Tabs.List
                    style={{
                      backgroundColor: "#eef0f8",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      display: "flex",
                      borderRadius: "24px",
                      justifyContent: "space-around",
                    }}
                  >
                    <MediaQuery smallerThan={"md"} styles={{ width: "90%" }}>
                      <Tabs.Tab
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                          paddingLeft: "101px",
                          paddingRight: "101px",
                          borderRadius: "24px",
                          backgroundColor: "#FFFFFF",
                          color: "#000000",
                          // Seçili tabın arka plan rengi
                        }}
                        value="first"
                      >
                        Kullanıcı Ekle
                      </Tabs.Tab>
                    </MediaQuery>
                    {/*<MediaQuery
                    smallerThan={"md"}
                    styles={{width: "90%"}}>
                    <Tabs.Tab
                      style={{
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        paddingLeft: "101px",
                        paddingRight: "101px",
                        borderRadius: "24px",
                        backgroundColor: "#FFFFFF",
                        color: "#000000",
                      }}
                      value="second"
                    >
                      Toplu Kullanıcı Ekle
                    </Tabs.Tab>
                    </MediaQuery>*/}
                  </Tabs.List>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Tabs.Panel value="first">
                    <AddUsersCard />
                  </Tabs.Panel>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Tabs.Panel value="second">
                    <MultiAddUsersCard />
                  </Tabs.Panel>
                </Grid.Col>
              </Grid>
            </Tabs>
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default UsersTabs;

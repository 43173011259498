import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import Notifications from "../presentation/view/notifications";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/notifications" element={<Notifications />} />
      </Routes>
    </Provider>
  );
}

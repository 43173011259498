import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import LoginV2 from "../presentation/view/loginV2";
import AgreementPage from "../presentation/view/AgreementPage";
import { ModuleStore } from "./ModuleStore";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/login" element={<LoginV2 />} />
        <Route path="/agreement" element={<AgreementPage />} />
      </Routes>
    </Provider>
  );
}

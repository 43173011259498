import {
	AppShell,
	Burger,
	Flex,
	Header,
	MediaQuery,
	Navbar,
	rem,
	useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import LeftMenuContract from "./LeftMenuContract";
import { BreadcrumbModel } from "./breadcrumbContract";
import AppHeader from "./headerContract";

function MainWrapperContract({
	children,
	breadCrumbs,
	title,
	hideMenu,
}: {
	children: React.ReactElement<HTMLAllCollection>;
	profile?: any;
	breadCrumbs?: Array<BreadcrumbModel>;
	title?: string;
	hideMenu?: boolean;
	loaderProps?: {
		active: boolean;
		title?: string;
		subTitle?: string;
	};
}) {
	const theme = useMantineTheme();
	const [opened, setOpened] = useState<boolean>(false);
	const matches = useMediaQuery("(max-width: 48.1rem)");

	return (
		<>
			<AppShell
				layout="alt"
				navbarOffsetBreakpoint="sm"
				asideOffsetBreakpoint="sm"
				header={
					<Header
						height={{
							base: rem(50),
							xs: rem(150),
							sm: rem(90),
							md: rem(100),
						}}
						p="md"
						fixed={false}
					>
						<Flex direction={"row"}>
							<MediaQuery
								largerThan="sm"
								styles={{ display: "none" }}
							>
								<Burger
									opened={opened}
									onClick={() => {
										setOpened(!opened);
									}}
									size="sm"
									mt={rem(10)}
									mr="xl"
								/>
							</MediaQuery>
							<AppHeader breadCrumbs={breadCrumbs} title={title} />
						</Flex>
					</Header>
				}
				navbar={
					<>
						{!hideMenu && (
							<Navbar
								hiddenBreakpoint="sm"
								height={"100%"}
								hidden={!opened}
								width={{ sm: rem(210), lg: rem(250) }}
							>
								<LeftMenuContract
									closeButton={
										matches && (
											<Burger
												opened={opened}
												onClick={() => {
													setOpened(!opened);
												}}
												size="sm"
												color={theme.colors.gray[6]}
												mt={rem(10)}
											/>
										)
									}
								/>
							</Navbar>
						)}
					</>
				}
				
			>
				<MediaQuery
					smallerThan={"sm"}
					largerThan={"xs"}
					styles={{ marginBottom: rem(50) }}
				>
					{children}
				</MediaQuery>
			</AppShell>
		</>
	);
}

export default MainWrapperContract;

import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Text,
  MediaQuery,
  Burger,
  Image,
  Space,
  Flex,
  useMantineTheme,
} from "@mantine/core";
import { Box, NavLink } from "@mantine/core";
import { IconGauge, IconChevronRight } from "@tabler/icons-react";

const cart = process.env.PUBLIC_URL + "/assets/images/cart.png";
const imece_logo = process.env.PUBLIC_URL + "/assets/images/imece_logo.jpg";
const profileIcon = process.env.PUBLIC_URL + "/assets/images/profile_icon.png";

export default function Dashboard() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Box w={240}>
            <NavLink
              label="Gübreleme"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />
            <NavLink label="Bütün Liste" />
            <NavLink label="Bilgi Alınacaklar" />

            <NavLink
              label="Sulama"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />
            <NavLink label="Bütün Liste" />
            <NavLink label="Bilgi Alınacaklar" />

            <NavLink
              label="Uydu"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />
            <NavLink label="Bütün Liste" />
            <NavLink label="Bilgi Alınacaklar" />

            <NavLink
              label="Uzmana Sor"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />

            <NavLink label="Geçmiş" />

            <NavLink
              label="Tarlalarım"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />
            <NavLink label="Tarlalarım" />
            <NavLink label="Tarla Ekle" />
            <NavLink label="Tarla Düzenle-Kaldır" />
            <NavLink
              label="Market"
              icon={<IconGauge size="1rem" stroke={1.5} />}
              rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            />
            <NavLink label="Siparişlerim" />
            <NavLink label="Ödeme Yöntemlerim" />
            <NavLink label="Adreslerim" />
          </Box>
        </Navbar>
      }
      footer={
        <Footer height={60} p="md">
          Moralabs 2023
        </Footer>
      }
      header={
        <Header height={{ base: 250, md: 120 }} p="sm" bg="cyan">
          <Flex
            color="red"
            justify={"space-between"}
            align={"center"}
            h={"100%"}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

            <Flex align="center">
              <Image height={50} width={50} src={imece_logo} alt="ImeceMobil" />
              <Space w="md" h="md" />
              <Text fs="xl" fw="bold">
                Imece Pro Panel
              </Text>
            </Flex>
            <Flex align="start" justify="right" mr="xl">
              <Image
                height={50}
                width={50}
                src={cart}
                style={{ marginTop: "10px" }}
              />
              <Space w="md" h="md" />
              <Image
                height={50}
                width={50}
                src={profileIcon}
                style={{ marginTop: "10px" }}
              />
            </Flex>
          </Flex>
        </Header>
      }
    >
      <Text>Resize app to see responsive navbar in action</Text>
    </AppShell>
  );
}

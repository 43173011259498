import {
  Button,
  Col,
  Flex,
  Grid,
  Paper,
  Select,
  Stack,
  Text,
  rem,
} from "@mantine/core";
import { DateInput, DatesProvider } from "@mantine/dates";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { set, toInteger } from "lodash";
import { CalendarBlank } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import {
  FieldAnalysis,
  GetFieldDetailInfoResponse,
} from "../../data/request/FieldDetailRequests";
import {
  fetchFieldAnalysisValues,
  fetchFieldInfo,
  updateFieldAnalysisInfo,
} from "../../interactor/FieldDetailInteractor";
import { AnalysisResponse } from "../../data/response/FieldDetailResponses";

interface AccountProps {
  store?: GetFieldDetailInfoResponse;
  seasonId?: string;
}

const SeasonsDetailWidget: React.FC<AccountProps> = ({ store, seasonId }) => {
  const dispatch = useAppDispatch();
  const [analyze, setAnalyze] = useState<FieldAnalysis>();

  const [seasonAnalysisUpdateData, setSeasonAnalysisNotesUpdateData] =
    useState<FieldAnalysis>();

  const changeFieldAnalysisState = useAppSelector(
    (state) => state.updateFieldAnalysisState.value
  );
  const [pHValues, setPHValues] = useState([]);
  const [organicValues, setOrganicValues] = useState([]);
  const [saltValues, setSaltValues] = useState([]);
  const [sTValues, setSTValues] = useState([]);
  const [potassiumValues, setPotassiumValues] = useState([]);
  const [limeValues, setLimeValues] = useState([]);
  const [phosphorusValues, setPhosphorusValues] = useState([]);

  const [isDataNull, setIsDataNull] = useState<boolean>(false);
  const [isButtonToggle, setIsButtonToggle] = useState<boolean>(false);
  const [isSeasonClosed, setIsSeasonClosed] = useState<boolean>(false);
  const isAllFieldsFilled = () => {
    return (
      seasonAnalysisUpdateData &&
      seasonAnalysisUpdateData.analysisDate !== undefined &&
      seasonAnalysisUpdateData.pH !== undefined &&
      seasonAnalysisUpdateData.salt !== undefined &&
      seasonAnalysisUpdateData.saturation !== undefined &&
      seasonAnalysisUpdateData.nitrogen !== undefined &&
      seasonAnalysisUpdateData.phosphorus !== undefined &&
      seasonAnalysisUpdateData.lime !== undefined &&
      seasonAnalysisUpdateData.potassium !== undefined
    );
  };

  const [queryParams] = useSearchParams();
  const queryParam = queryParams.get("id");

  const analyzeDateRef = useRef<HTMLInputElement>(null);
  const pHValueRef = useRef<HTMLInputElement>(null);
  const saltValueRef = useRef<HTMLInputElement>(null);
  const sTValueRef = useRef<HTMLInputElement>(null);
  const organicValueRef = useRef<HTMLInputElement>(null);
  const phosphorusValueRef = useRef<HTMLInputElement>(null);
  const limeValuesRef = useRef<HTMLInputElement>(null);
  const potassiumValuesRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (analyzeDateRef.current) {
      analyzeDateRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          pHValueRef.current?.focus();
        }
      });
    }
    if (pHValueRef.current) {
      pHValueRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          sTValueRef.current?.focus();
        }
      });
    }
    if (sTValueRef.current) {
      sTValueRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          saltValueRef.current?.focus();
        }
      });
    }
    if (saltValueRef.current) {
      saltValueRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          limeValuesRef.current?.focus();
        }
      });
    }
    if (limeValuesRef.current) {
      limeValuesRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          organicValueRef.current?.focus();
        }
      });
    }
    if (organicValueRef.current) {
      organicValueRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          phosphorusValueRef.current?.focus();
        }
      });
    }
    if (phosphorusValueRef.current) {
      phosphorusValueRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          potassiumValuesRef.current?.focus();
        }
      });
    }
    if (potassiumValuesRef.current) {
      potassiumValuesRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          analyzeDateRef.current?.focus();
        }
      });
    }
  }, [
    analyzeDateRef,
    pHValueRef,
    sTValueRef,
    saltValueRef,
    limeValuesRef,
    organicValueRef,
    phosphorusValueRef,
    potassiumValuesRef,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const actionResult = await dispatch(fetchFieldAnalysisValues({}));

        if (fetchFieldAnalysisValues.fulfilled.match(actionResult)) {
          const responseData = actionResult.payload as AnalysisResponse;
          // Ensure parameters array is present and not empty
          if (
            responseData?.data?.parameters[0] &&
            responseData.data?.parameters.length > 0
          ) {
            const parametersValue = responseData.data?.parameters[0]?.value;
            if (parametersValue) {
              // Clean up the JSON string
              let cleanedJson = parametersValue
                .replace(/‘|’/g, '"') // Replace curly quotes with straight quotes
                .replace(/(\r\n|\n|\r)/gm, "") // Remove newlines and extra spaces
                .trim(); // Remove leading and trailing spaces

              try {
                // Parse the cleaned JSON
                const data = JSON.parse(cleanedJson);

                if (data?.values && data.values.length > 0) {
                  const pHValuesFromJson = data.values[0]?.ph.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setPHValues(pHValuesFromJson);
                  const organicValuesFromJson = data.values[0]?.azote.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setOrganicValues(organicValuesFromJson);
                  const saltValuesFromJson = data.values[0]?.salt.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setSaltValues(saltValuesFromJson);
                  const sTValuesFromJson = data.values[0]?.saturation.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setSTValues(sTValuesFromJson);
                  const potassiumValuesFromJson = data.values[0]?.potassium.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setPotassiumValues(potassiumValuesFromJson);
                  const limeValuesFromJson = data.values[0]?.lime.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setLimeValues(limeValuesFromJson);
                  const phosphorusValuesFromJson = data.values[0]?.phosphor.map(
                    (value: string, index: number) => ({
                      value: index.toString(),
                      label: value,
                    })
                  );
                  setPhosphorusValues(phosphorusValuesFromJson);
                } else {
                  console.error(
                    "Unexpected data format or empty values:",
                    data
                  );
                }
              } catch (jsonError) {
                console.error("Error parsing JSON:", jsonError);
              }
            } else {
              console.error("Parameters value is empty or undefined");
            }
          } else {
            console.error("No parameters available in response data");
          }
        } else {
          console.error("Failed to fetch data:", actionResult.payload);
        }
      } catch (error) {
        console.error("Error processing data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (changeFieldAnalysisState?.success === true && queryParam !== null) {
      dispatch(fetchFieldInfo({ id: queryParam }));
      toast.success("Toprak Analiz Bilgileri Kaydedildi");
    } else if (
      changeFieldAnalysisState?.messageCode === "true" &&
      queryParam !== null
    ) {
      toast.error("Toprak Analiz Bilgileri Kaydedilemedi");
    }
  }, [changeFieldAnalysisState]);
  const handleEditNoteWarning = () => {
    const validation = form.validate();
    if (Object.keys(validation.errors).length === 0) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamayacaksınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0097c4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Toprak Analiz Verilerini Güncelle!",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          handleEditAnalysis();
        }
      });
    }
  };

  const handleEditAnalysis = () => {
    if (seasonAnalysisUpdateData) {
      const updatedFieldAnalysis = {
        ...seasonAnalysisUpdateData,
        seasonId: seasonId,
      };
      dispatch(
        updateFieldAnalysisInfo({ fieldAnalysis: updatedFieldAnalysis })
      );
    }
  };
  const form = useForm({
    initialValues: {
      analyzeDateData: undefined,
      pHValue: undefined,
      saltValue: undefined,
      sTValue: undefined,
      nitrogenValue: undefined,
      phosphorusValue: undefined,
      limeValue: undefined,
      potassiumValue: undefined,
    },

    validate: {
      analyzeDateData: (value: any) =>
        value || seasonAnalysisUpdateData?.analysisDate
          ? null
          : "Analiz tarihi değeri seçilmelidir",
      pHValue: (value: any) =>
        validateAnalysisValue(value, "pH değeri seçilmelidir"),
      saltValue: (value: any) =>
        validateAnalysisValue(value, "Tuz değeri seçilmelidir"),
      sTValue: (value: any) =>
        validateAnalysisValue(value, "Saturasyon değeri seçilmelidir"),
      nitrogenValue: (value: any) =>
        validateAnalysisValue(value, "Organik Madde değeri seçilmelidir"),
      phosphorusValue: (value: any) =>
        validateAnalysisValue(value, "Fosfor değeri seçilmelidir"),
      limeValue: (value: any) =>
        validateAnalysisValue(value, "Kireç değeri seçilmelidir"),
      potassiumValue: (value: any) =>
        validateAnalysisValue(value, "Potasyum değeri seçilmelidir"),
    },
  });

  function validateAnalysisValue(
    value: any,
    errorMessage: string
  ): string | null {
    if (
      value ||
      form.values.pHValue ||
      form.values.saltValue ||
      form.values.sTValue ||
      form.values.nitrogenValue ||
      form.values.phosphorusValue ||
      form.values.limeValue ||
      form.values.potassiumValue ||
      seasonAnalysisUpdateData?.pH ||
      seasonAnalysisUpdateData?.salt ||
      seasonAnalysisUpdateData?.saturation ||
      seasonAnalysisUpdateData?.nitrogen ||
      seasonAnalysisUpdateData?.phosphorus ||
      seasonAnalysisUpdateData?.lime ||
      seasonAnalysisUpdateData?.potassium
    ) {
      return null;
    }
    return "En az bir analiz değeri seçilmelidir";
  }
  useEffect(() => {
    if (store === undefined) {
      setSeasonAnalysisNotesUpdateData({
        ...seasonAnalysisUpdateData,
        analysisDate: undefined,
        pH: undefined,
        salt: undefined,
        saturation: undefined,
        lime: undefined,
        nitrogen: undefined,
        phosphorus: undefined,
        potassium: undefined,
      });
    } else {
      const fieldAnalysisLength = store?.field.seasons.find(
        (x) => x.id === seasonId
      )?.fieldAnalysis?.length;
      if (fieldAnalysisLength && fieldAnalysisLength > 1) {
        setSeasonAnalysisNotesUpdateData(
          store?.field.seasons
            .find((x) => x.id === seasonId)
            ?.fieldAnalysis?.at(fieldAnalysisLength - 1)
        );
      } else {
        setSeasonAnalysisNotesUpdateData(
          store?.field.seasons
            .find((x) => x.id === seasonId)
            ?.fieldAnalysis?.at(0)
        );
      }
    }

    setIsDataNull(true);
    setIsButtonToggle(false);
    setIsSeasonClosed(
      store?.field.seasons?.find((x) => x.id === seasonId)?.seasonClosed ||
        false
    );

    form.clearErrors();
    form.setFieldValue("analyzeDateData", undefined);
    form.setFieldValue("pHValue", undefined);
    form.setFieldValue("sTValue", undefined);
    form.setFieldValue("saltValue", undefined);
    form.setFieldValue("limeValue", undefined);
    form.setFieldValue("nitrogenValue", undefined);
    form.setFieldValue("phosphorusValue", undefined);
    form.setFieldValue("potassiumValue", undefined);

    if (
      store?.field.seasons
        ?.find((x) => x.id === seasonId)
        ?.fieldAnalysis?.at(0) !== undefined
    ) {
      setIsDataNull(false);
      setIsButtonToggle(false);
      setIsDisabled(!isDisabled);
    }
  }, [seasonId, store, isSeasonClosed]);

  let analysisDate = "Not Available";
  if (
    store &&
    store.field &&
    store.field.seasons &&
    store.field.seasons[0] &&
    store.field.seasons[0].fieldAnalysis &&
    store.field.seasons[0].fieldAnalysis[0] &&
    store.field.seasons[0].fieldAnalysis[0].analysisDate
  ) {
    const dateValue = new Date(
      store.field.seasons[0].fieldAnalysis[0].analysisDate
    );
    if (!isNaN(dateValue.getTime())) {
      analysisDate = dateValue.toLocaleDateString("tr-TR");
    }
  }

  const pHValueFromStore = seasonAnalysisUpdateData?.pH;

  let calculatedpHValue;

  if (pHValueFromStore !== undefined) {
    if (pHValueFromStore >= 0 && pHValueFromStore < pHValues.length) {
      calculatedpHValue = pHValues[pHValueFromStore];
    } else {
      calculatedpHValue = pHValueFromStore.toString();
    }
  }

  const sTValueFromStore = seasonAnalysisUpdateData?.saturation;
  let calculatedsTValue;

  if (sTValueFromStore !== undefined) {
    if (sTValueFromStore >= 0 && sTValueFromStore < sTValues.length) {
      calculatedsTValue = sTValues[sTValueFromStore];
    } else {
      calculatedsTValue = sTValueFromStore.toString();
    }
  }

  const saltValueFromStore = seasonAnalysisUpdateData?.salt;
  let calculatedSaltValue;

  if (saltValueFromStore !== undefined) {
    if (saltValueFromStore >= 0 && saltValueFromStore < saltValues.length) {
      calculatedSaltValue = saltValues[saltValueFromStore];
    } else {
      calculatedSaltValue = saltValueFromStore.toString();
    }
  }
  const limeValueFromStore = seasonAnalysisUpdateData?.lime;
  let calculatedLimeValue;

  if (limeValueFromStore !== undefined) {
    if (limeValueFromStore >= 0 && limeValueFromStore < limeValues.length) {
      calculatedLimeValue = limeValues[limeValueFromStore];
    } else {
      calculatedLimeValue = limeValueFromStore.toString();
    }
  }

  const organicValueFromStore = seasonAnalysisUpdateData?.nitrogen;
  let calculatedOrganicValue;

  if (organicValueFromStore !== undefined) {
    if (
      organicValueFromStore >= 0 &&
      organicValueFromStore < organicValues.length
    ) {
      calculatedOrganicValue = organicValues[organicValueFromStore];
    } else {
      calculatedOrganicValue = organicValueFromStore.toString();
    }
  }

  const phosphorusValueFromStore = seasonAnalysisUpdateData?.phosphorus;

  let calculatedPhosphorusValue;

  if (phosphorusValueFromStore !== undefined) {
    if (
      phosphorusValueFromStore >= 0 &&
      phosphorusValueFromStore < phosphorusValues.length
    ) {
      calculatedPhosphorusValue = phosphorusValues[phosphorusValueFromStore];
    } else {
      calculatedPhosphorusValue = phosphorusValueFromStore.toString();
    }
  }
  const potassiumValueFromStore = seasonAnalysisUpdateData?.potassium;

  let calculatedPotassiumValue;

  if (potassiumValueFromStore !== undefined) {
    if (
      potassiumValueFromStore >= 0 &&
      potassiumValueFromStore < potassiumValues.length
    ) {
      calculatedPotassiumValue = potassiumValues[potassiumValueFromStore];
    } else {
      calculatedPotassiumValue = potassiumValueFromStore.toString();
    }
  }

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleToggleDisable = () => {
    setIsButtonToggle(!isButtonToggle);
    setIsDisabled(!isDisabled);
  };

  return (
    <Paper p={20} h={rem(700)} className="widgets" radius={rem(12)} bg="white">
      <Stack sx={{ gap: rem(10) }}>
        <form onSubmit={form.onSubmit((values) => {})}>
          <Grid>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Analiz Tarihi
              </Text>
              <DatesProvider
                settings={{
                  locale: dayjs.locale("tr"),
                  firstDayOfWeek: 1,
                  weekendDays: [0, 6],
                }}
              >
                <DateInput
                  ref={analyzeDateRef}
                  disabled={
                    isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                  }
                  valueFormat="DD.MM.YYYY"
                  {...form.getInputProps("analyzeDateData")}
                  rightSection={<CalendarBlank size={16} color="#6c757d" />}
                  placeholder="Analiz Tarihi"
                  clearable
                  allowDeselect
                  maxDate={new Date()}
                  value={
                    seasonAnalysisUpdateData?.analysisDate
                      ? new Date(seasonAnalysisUpdateData.analysisDate)
                      : null
                  }
                  onChange={(e: Date) => {
                    if (e > new Date()) {
                      // If the selected date is in the future, ignore the change
                      return;
                    }
                    const dateWithNoon = new Date(e);
                    dateWithNoon.setHours(12);
                    form
                      .getInputProps("analyzeDateData")
                      .onChange(dateWithNoon);
                    setSeasonAnalysisNotesUpdateData({
                      ...seasonAnalysisUpdateData,
                      analysisDate: dateWithNoon,
                    });
                  }}
                />
              </DatesProvider>
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ph
              </Text>

              <Select
                ref={pHValueRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("pHValue")}
                placeholder="PH Değeri Seçin"
                data={pHValues}
                value={seasonAnalysisUpdateData?.pH?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("pHValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    pH: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                St
              </Text>
              <Select
                ref={sTValueRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("sTValue")}
                placeholder="St değerini seçin"
                data={sTValues}
                value={seasonAnalysisUpdateData?.saturation?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("sTValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    saturation: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Tuz
              </Text>
              <Select
                ref={saltValueRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("saltValue")}
                placeholder="Tuz değeri seçin"
                data={saltValues}
                value={seasonAnalysisUpdateData?.salt?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("saltValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    salt: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Kireç
              </Text>
              <Select
                ref={limeValuesRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("limeValue")}
                placeholder="Kireç değeri seçin"
                data={limeValues}
                value={seasonAnalysisUpdateData?.lime?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("limeValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    lime: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Organik Madde
              </Text>
              <Select
                ref={organicValueRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("nitrogenValue")}
                placeholder="Organik Madde değerini seçin"
                data={organicValues}
                value={seasonAnalysisUpdateData?.nitrogen?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("nitrogenValue").onChange(e);

                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    nitrogen: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Fosfor
              </Text>
              <Select
                ref={phosphorusValueRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("phosphorusValue")}
                placeholder="Fosfor değeri seçin"
                data={phosphorusValues}
                value={seasonAnalysisUpdateData?.phosphorus?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("phosphorusValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    phosphorus: toInteger(e),
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Potasyum
              </Text>
              <Select
                ref={potassiumValuesRef}
                disabled={
                  isSeasonClosed ? true : isDataNull ? false : !isButtonToggle
                }
                {...form.getInputProps("potassiumValue")}
                placeholder="Potasyum değeri seçin"
                data={potassiumValues}
                value={seasonAnalysisUpdateData?.potassium?.toString() || ""}
                onChange={(e) => {
                  form.getInputProps("potassiumValue").onChange(e);
                  setSeasonAnalysisNotesUpdateData({
                    ...seasonAnalysisUpdateData,
                    potassium: toInteger(e),
                  });
                }}
              />
            </Col>
          </Grid>
          <Col span={12} style={{ marginTop: "20px", alignSelf: "flex-end" }}>
            <Flex gap={rem(40)} style={{ justifyContent: "flex-end" }}>
              <Button
                type={
                  isDataNull ? "submit" : !isButtonToggle ? "submit" : "button"
                }
                style={{
                  paddingBottom: "11.5px",
                  paddingTop: "11.5px",
                  paddingLeft: "32.5px",
                  paddingRight: "32.5px",
                  borderRadius: rem(32),
                  backgroundColor: "#0097c4",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                  ...(isSeasonClosed
                    ? { display: "none" }
                    : { display: "block" }),
                }}
                disabled={isSeasonClosed ? true : false}
                onClick={
                  isDataNull
                    ? handleEditNoteWarning
                    : !isButtonToggle
                    ? handleToggleDisable
                    : handleEditNoteWarning
                }
              >
                {isDataNull
                  ? "Kaydet"
                  : !isButtonToggle
                  ? "Güncelle"
                  : "Kaydet"}
              </Button>
            </Flex>
          </Col>
        </form>
      </Stack>
    </Paper>
  );
};

export default SeasonsDetailWidget;

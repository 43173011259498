import { BaseRequest } from "../../../common/data/BaseRequest";

export class PlantRequest extends BaseRequest {
  PlantType: number | null = null;

  constructor(plantType: number | null) {
    super();
    this.PlantType = plantType;
  }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { EmployeeResponse } from "../data/response/EmployeeResponse";

export const EmployeeService = createAsyncThunk(
  "employee/getEmployee",
  async (allEmployee?: boolean) => {
    try {
      const response = await AxiosHelper.getInstance().get<EmployeeResponse>(
        "/corporate/employees" + (allEmployee ? "?withproducer=true" : "")
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<EmployeeResponse> = {};

const EmployeeSlice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EmployeeService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(EmployeeService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(EmployeeService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default EmployeeSlice.reducer;

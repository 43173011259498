import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { CultivationRequest } from "../data/request/CultivationRequest";
import { CultivationResponse } from "../data/response/CultivationResponse";

export const CultivationService = createAsyncThunk(
  "cultivations/getCultivations",
  async (cultivation: CultivationRequest) => {
    try {
      const response = await AxiosHelper.getInstance().get<CultivationResponse>(
        `parameter/cultivationareas`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const initialState: ServiceState<CultivationResponse> = {};

const CultivationSlice = createSlice({
  name: "culsivations",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CultivationService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CultivationService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CultivationService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO: Handle the error
      });
  },
});

export default CultivationSlice.reducer;

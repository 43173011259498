import { BaseRequest } from "../../../common/data/BaseRequest";

export class NeighborhoodRequest extends BaseRequest {
  DistrictId: string;
  CacheKey: string | "";

  constructor(districtId: string, cacheKey: string) {
    super();
    this.DistrictId = districtId;
    this.CacheKey = cacheKey;
  }
}

import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import UserProfile from "../presentation/view/UserProfile";

export default function ModuleRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/userprofile" element={<UserProfile />} />
      </Routes>
    </Provider>
  );
}

import { BaseRequest } from "../../../common/data/BaseRequest";

export class M2MRequest extends BaseRequest {
  clientId: string = process.env.REACT_APP_CLIENT_ID ?? "";
  clientSecret: string = process.env.REACT_APP_CLIENT_SECRET ?? "";
}

export class LoginRequest extends BaseRequest {
  phoneNumberDto?: PhoneNumberDto;
  alias?: string;

  constructor(countryCode?: string, phoneNumber?: string, alias?: string) {
    super();

    this.phoneNumberDto = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    };
    this.alias = alias;
  }
}

export class OTPRequest extends BaseRequest {
  code?: String;
  alias?: String;
  phoneNumberDto?: PhoneNumberDto;

  constructor(
    code?: string,
    countryCode?: string,
    phoneNumber?: string,
    alias?: string
  ) {
    super();
    this.code = code;
    this.alias = alias;
    this.phoneNumberDto = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
    };
  }
}

interface PhoneNumberDto {
  countryCode?: string;
  phoneNumber?: string;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { AddEmployeeRequest } from "../data/request/AddEmployeeRequest";
import { AddEmployeeResponse } from "../data/response/AddEmployeeResponse";
import { DeleteEmployeeResponse } from "../data/response/DeleteEmployeeResponse";

export const DeleteEmployeeService = createAsyncThunk(
  "corporate/deleteEmployee",
  async (id: string) => {
    try {
      const response =
        await AxiosHelper.getInstance().delete<DeleteEmployeeResponse>(
          `/corporate/employees/${id}`
        );
      return response.data;
    } catch (error) {
      console.error(error || "An unknown error occurred.");
    }
  }
);

const initialState: ServiceState<DeleteEmployeeResponse> = {};

const DeleteEmployeeSlice = createSlice({
  name: "deleteEmployee",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteEmployeeService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DeleteEmployeeService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DeleteEmployeeService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default DeleteEmployeeSlice.reducer;

import {
  Grid,
  LoadingOverlay,
  Paper,
  Space,
  useMantineTheme,
} from "@mantine/core";
import React, { Suspense, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";

import { useSearchParams } from "react-router-dom";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { Seasons } from "../../data/request/FieldDetailRequests";
import FieldDetailHeader from "./FieldDetailHeader";
import { fetchProfileInfo } from "../../../profile/interactor/ProfileInteractor";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";

const MainWrapper = React.lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);
const FieldInfoCard = React.lazy(() => import("./FieldInfoCard"));
const PersonalInfoCard = React.lazy(() => import("./PersonalInfoCard"));
const FieldVisitorFormCard = React.lazy(() => import("./FieldVisitorFormCard"));
const NotificationsMiniCard = React.lazy(
  () => import("./NotificationsMiniCard")
);
const WeeklyNotificationsCard = React.lazy(
  () => import("./WeeklyNotificationsCard")
);
const SeasonsCard = React.lazy(() => import("./SeasonsCard"));
const NDVICard = React.lazy(() => import("./NDVICard"));
const WeatherCard = React.lazy(
  () =>
    import(
      "../../../../app/common/presentation/view/component/Widgets/WeatherCard"
    )
);
const MyFieldsDetail: React.FC = () => {
  const [location, setLocation] = useState({ lat: 0, long: 0 });

  const weatherAccuState = useAppSelector(
    (state) => state.weatherAccuState.value?.data?.days
  );

  const profileSubscriptionType = useAppSelector(
    (state) => state.getProfileInfoState.value?.data?.subscriptionType
  );
  const weatherApiState = useAppSelector(
    (state) => state.weatherApiState.value?.data?.days
  );
  const weatherBitState = useAppSelector(
    (state) => state.weatherBitState.value?.data?.weatherForecasts
  );
  const weatherTarlaIOOState = useAppSelector(
    (state) => state.weatherTarlaIOOState.value?.data?.dailyWeatherForecast
  );
  const weatherVisualCrossState = useAppSelector(
    (state) => state.weatherVisualCrossState.value?.data?.weatherForecasts
  );
  const isLoadingweatherAccuState = useAppSelector(
    (state) => state.weatherAccuState.isLoading
  );
  const isLoadingweatherApiState = useAppSelector(
    (state) => state.weatherApiState.isLoading
  );
  const isLoadingweatherBitState = useAppSelector(
    (state) => state.weatherBitState.isLoading
  );
  const isLoadingweatherTarlaIOOState = useAppSelector(
    (state) => state.weatherTarlaIOOState.isLoading
  );
  const isLoadingweatherVisualCrossState = useAppSelector(
    (state) => state.weatherVisualCrossState.isLoading
  );

  const weatherHourlyAccuState = useAppSelector(
    (state) => state.weatherHourlyAccuState.value?.data?.hourlies
  );
  const weatherHourlyApiState = useAppSelector(
    (state) => state.weatherHourlyApiState.value?.data?.weatherForecasts
  );
  const weatherHourlyBitState = useAppSelector(
    (state) => state.weatherHourlyBitState.value?.data?.weatherForecasts
  );
  const weatherHourlyTarlaIOOState = useAppSelector(
    (state) => state.weatherHourlyTarlaIOOState.value?.data?.weatherForecasts
  );
  const weatherHourlyVisualCrossState = useAppSelector(
    (state) => state.weatherHourlyVisualCrossState.value?.data?.weatherForecasts
  );

  const [seasonId, setSeasonId] = useState<string>();

  const getFieldInfoState = useAppSelector(
    (state) => state.getFieldInfoState.value?.data
  );

  const getNDVIScoreState = useAppSelector(
    (state) => state.getFieldNDVIState.value?.data
  );

  const getNotificationsState = useAppSelector(
    (state) => state.getFieldNotificationState.value?.data
  );

  const getUserInfoState = useAppSelector(
    (state) => state.getFieldInfoState.value?.data
  );
  const getSeasonsInfoState = useAppSelector(
    (state) => state.getSeasonsInfoState.value?.data?.field.seasons
  ) as Seasons[] | undefined;

  const dispatch = useAppDispatch();

  const fieldState = useAppSelector(
    (state) => state.myFieldState.value?.data.fields
  );

  const addSeasonsIsLoading = useAppSelector(
    (state) => state.addSeasonsState.isLoading
  );

  const updateSeasonsIsLoading = useAppSelector(
    (state) => state.updateSeasonState.isLoading
  );

  const closeSeasonsIsLoading = useAppSelector(
    (state) => state.closeSeasonState.isLoading
  );

  const [queryParams] = useSearchParams();
  const queryParam = queryParams.get("id");

  useEffect(() => {
    const field = fieldState?.find((x) => x.id === queryParam);

    setLocation({
      lat: field?.address?.latitude ?? 0,
      long: field?.address?.longitude ?? 0,
    });
  }, [fieldState, queryParam]);

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, []);

  useEffect(() => {
    dispatch(MyFieldService());
  }, []);

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <LoadingOverlay
        visible={
          (addSeasonsIsLoading ?? false) ||
          (updateSeasonsIsLoading ?? false) ||
          (closeSeasonsIsLoading ?? false)
        }
        overlayBlur={2}
        loaderProps={{
          size: "md",
          color: "#0097c4",
          variant: "oval",
        }}
      />
      <MainWrapper
        breadCrumbs={[
          { title: "Tarlalarım", href: "/myFields" },
          { title: "Tarlalarım Detay", href: "#" },
        ]}
        title="Tarla Detay"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Paper
            style={{ backgroundColor: "transparent" }}
            p="xl"
            className="profile-paper"
          >
            <Grid>
              <Grid.Col span={12}>
                <WeatherCard
                  isLoading={
                    localStorage.getItem("weatherFav") === "visualcross"
                      ? isLoadingweatherVisualCrossState
                      : localStorage.getItem("weatherFav") === "weatherbit"
                      ? isLoadingweatherBitState
                      : localStorage.getItem("weatherFav") === "accuweather"
                      ? isLoadingweatherAccuState
                      : localStorage.getItem("weatherFav") === "weatherapi"
                      ? isLoadingweatherApiState
                      : localStorage.getItem("weatherFav") === "tarlaio"
                      ? isLoadingweatherTarlaIOOState
                      : false
                  }
                  sourceType={
                    localStorage.getItem("weatherFav") === "tarlaio"
                      ? "tarlaio"
                      : localStorage.getItem("weatherFav") === "weatherbit"
                      ? "weatherbit"
                      : localStorage.getItem("weatherFav") === "accuweather"
                      ? "accuweather"
                      : localStorage.getItem("weatherFav") === "weatherapi"
                      ? "weatherapi"
                      : localStorage.getItem("weatherFav") === "visualcross"
                      ? "visualcross"
                      : "visualcross"
                  }
                  storeDaily={
                    localStorage.getItem("weatherFav") === "visualcross"
                      ? weatherVisualCrossState
                      : localStorage.getItem("weatherFav") === "weatherbit"
                      ? weatherBitState
                      : localStorage.getItem("weatherFav") === "accuweather"
                      ? weatherAccuState
                      : localStorage.getItem("weatherFav") === "weatherapi"
                      ? weatherApiState
                      : weatherTarlaIOOState
                  }
                  storeHourly={
                    localStorage.getItem("weatherFav") === "visualcross"
                      ? weatherHourlyVisualCrossState
                      : localStorage.getItem("weatherFav") === "weatherbit"
                      ? weatherHourlyBitState
                      : localStorage.getItem("weatherFav") === "accuweather"
                      ? weatherHourlyAccuState
                      : localStorage.getItem("weatherFav") === "weatherapi"
                      ? weatherHourlyApiState
                      : weatherHourlyTarlaIOOState
                  }
                  lat={location.lat}
                  long={location.long}
                  fieldId={queryParam ?? ""}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid grow>
                  <Grid.Col md={4} sm={12}>
                    <FieldInfoCard store={getFieldInfoState} />
                  </Grid.Col>

                  {profileSubscriptionType !== 0 && (
                    <Grid.Col md={4} sm={12}>
                      <NDVICard store={getNDVIScoreState} />
                    </Grid.Col>
                  )}
                  <Grid.Col md={4} sm={12}>
                    <NotificationsMiniCard store={getNotificationsState} />
                  </Grid.Col>
                  <Grid.Col md={4} sm={12}>
                    <WeeklyNotificationsCard />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12}>
                <Grid>
                  <Grid.Col md={4} sm={12}>
                    <PersonalInfoCard store={getUserInfoState} />

                    <Space h="20px" />

                    <SeasonsCard
                      store={getSeasonsInfoState}
                      onSeasonSelect={(seasonId) => setSeasonId(seasonId)}
                    />
                    <Space h="20px" />
                    <FieldVisitorFormCard />
                  </Grid.Col>

                  <Grid.Col md={8} sm={12}>
                    <FieldDetailHeader
                      store={getFieldInfoState}
                      seasonId={seasonId}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default MyFieldsDetail;

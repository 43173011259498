import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { useState } from "react";
import Users from "../presentation/view/Users";
import { ModuleStore } from "./ModuleStore";
import UsersTabs from "../../../app/common/presentation/view/component/Widgets/UsersTabs";

export default function UserRoutes() {
  const [isModalOpenPlant, setIsModalOpenPlant] = useState(false);
  const closeModal = () => setIsModalOpenPlant(false);
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/users/add" element={<UsersTabs />} />
      </Routes>
    </Provider>
  );
}

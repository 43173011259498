import React from "react";
import { Tabs, Paper, rem } from "@mantine/core";
import MainWrapper from "../mainWrapper";
import AddFieldsCard from "./AddFieldsCard";
import MultiAddFieldsCard from "./MultiAddFieldsCard";

const AddMyFieldsTabs: React.FC = () => {
  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Tarlalarım", href: "/myFields" },
        { title: "Tarla Ekle", href: "#" },
      ]}
      title="Tarla Ekle"
    >
      <Paper
        shadow="md"
        p={20}
        className="widgets"
        bg="white"
        style={{
          borderTopLeftRadius: "23px",
          borderTopRightRadius: "23px",
        }}
      >
        <Tabs color="#eef0f8" variant="pills" defaultValue="first">
          <Tabs.List
            style={{
              backgroundColor: "#eef0f8",
              paddingTop: "10px",
              paddingBottom: "10px",
              height: "60px",
              display: "flex",
              borderRadius: "24px",
              justifyContent: "space-around",
            }}
          >
            <Tabs.Tab
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                paddingLeft: "101px",
                paddingRight: "101px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
                // Seçili tabın arka plan rengi
              }}
              value="first"
            >
              Tarla Ekle
            </Tabs.Tab>
            {/* <Tabs.Tab
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                paddingLeft: "101px",
                paddingRight: "101px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
              }}
              value="second"
            >
              Toplu Tarla Ekle
            </Tabs.Tab> */}
          </Tabs.List>
          <Tabs.Panel value="first">
            <AddFieldsCard />
          </Tabs.Panel>
          <Tabs.Panel value="second">
            <MultiAddFieldsCard />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </MainWrapper>
  );
};

export default AddMyFieldsTabs;

import React, { useEffect, useState } from "react";
import {
  Tabs,
  Paper,
  Grid,
  Col,
  Text,
  Select,
  LoadingOverlay,
  TextInput,
  Button,
} from "@mantine/core";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import { useAppDispatch } from "../../connector/ModuleHook";
import {
  FormListService,
  QuestionFormReportService,
} from "../../interractor/SurveyInterractor";
import { FormListRequest } from "../../data/request/FormListRequest";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { IconSearch, IconSortDescending } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import {
  FormQuestionOptionDto,
  FormQuestionReportDto,
} from "../../data/dto/FormQuestionReportDto";
import * as XLSX from "xlsx";

const PAGE_SIZE = 10;

const QuetionReport: React.FC = () => {
  const dispatch = useAppDispatch();

  const [fetching, setFetching] = useState(false);

  const formListService = useSelector(
    (state: RootState) => state.FormListState.value
  );

  const formsData = formListService?.data;

  useEffect(() => {
    if (!formsData) {
      setFetching(true);
      dispatch(FormListService());
    }
  }, []);

  useEffect(() => {
    if (formsData?.forms) {
      setFetching(false);
    }
  }, [formsData]);

  const [form, setForm] = useState<any>(null);
  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState<any[]>([]);

  const questionFormReportService = useSelector(
    (state: RootState) => state.QuestionFormReportState.value
  );

  const questionFormReportData = questionFormReportService?.data;

  useEffect(() => {
    if (form) {
      dispatch(QuestionFormReportService({ formId: form }));
    }
  }, [form]);

  useEffect(() => {
    if (questionFormReportData?.formQuestionDtos) {
      let questions = questionFormReportData.formQuestionDtos
        .map((item: FormQuestionReportDto) => {
          return {
            formQuestionId: item.formQuestionId,
            label: item.label,
            isRequired: item.isRequired ? "Evet" : "Hayır",
            seo: item.seo,
            options: item.optionDtos
              .map((option: FormQuestionOptionDto) => {
                return option.text + " - " + option.value;
              })
              .join(",  "),
          };
        })
        .sort((a, b) => a.seo - b.seo);

      setSortedData(questions);
      setPageData(questions.slice(from, to));
      setInitialRecords(questions);
    }
  }, [questionFormReportData]);

  const handleExportToExcel = () => {
    const headers = ["Sıra", "Soru Id", "Soru", "Zorunlu Mu?", "Seçenekler"];

    const data = initialRecords.map((item) => [
      item.seo,
      item.formQuestionId,
      item.label,
      item.isRequired,
      item.options,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

    for (let R = 1; R <= data.length; ++R) {
      for (let C = 10; C <= 11; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].z = "@"; // Apply text format
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tarlalarım");
    XLSX.writeFile(workbook, "Soru_Raporu.xlsx");
  };

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  const [formQuestionIdQuery, setFormQuestionIdQuery] = useState("");
  const [debouncedformQuestionIdQuery] = useDebouncedValue(
    formQuestionIdQuery,
    200
  );
  const [labelQuery, setLabelQuery] = useState("");
  const [debouncedLabelQuery] = useDebouncedValue(labelQuery, 200);

  const [seoQuery, setSeoQuery] = useState("");
  const [debouncedSeoQuery] = useDebouncedValue(seoQuery, 200);

  const [isRequiredQuery, seIsRequiredQuery] = useState("");
  const [debouncedIsRequired] = useDebouncedValue(isRequiredQuery, 200);

  const [optionsQuery, setOptionsQuery] = useState("");
  const [debouncedOptionsQuery] = useDebouncedValue(optionsQuery, 200);

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({ formQuestionId, label, isRequired, seo, options }) => {
          if (
            debouncedformQuestionIdQuery !== "" &&
            formQuestionIdQuery &&
            !`${formQuestionId}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedformQuestionIdQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedLabelQuery !== "" &&
            labelQuery &&
            !`${label}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedLabelQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedIsRequired !== "" &&
            isRequiredQuery &&
            !`${isRequired}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedIsRequired.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedSeoQuery &&
            seoQuery &&
            !`${seo}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedSeoQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedOptionsQuery &&
            optionsQuery &&
            !`${options}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedOptionsQuery.trim().toLowerCase())
          ) {
            return false;
          }

          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "seo") {
        sortedData.sort((a, b) => {
          const valueA = a.seo ? a.seo : -1;
          const valueB = b.seo ? b.seo : -1;
          return sortStatus.direction === "asc" ? a.seo - b.seo : b.seo - a.seo;
        });
      } else if (sortStatus.columnAccessor === "formQuestionId") {
        sortedData.sort((a, b) => {
          const valueA = a.formQuestionId ? a.formQuestionId.toLowerCase() : "";
          const valueB = b.formQuestionId ? b.formQuestionId.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "label") {
        sortedData.sort((a, b) => {
          const valueA = (a.label ?? "").toLowerCase();
          const valueB = (b.label ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "isRequired") {
        sortedData.sort((a, b) => {
          const valueA = (a.isRequired ?? "").toLowerCase();
          const valueB = (b.isRequired ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "options") {
        sortedData.sort((a, b) => {
          const valueA = (a.options ?? "").toLowerCase();
          const valueB = (b.options ?? "").toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else {
        return true;
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus]);

  const columns = [
    {
      accessor: "seo",
      title: "Sıra",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Sıra numarası"
          description="Belirtilen soru numarasına ait soruları ara."
          placeholder="Soru numarası ile ara..."
          icon={<IconSearch size={16} />}
          value={seoQuery}
          onChange={(e) => setSeoQuery(e.currentTarget.value)}
        />
      ),
      filtering: seoQuery !== "",
    },
    {
      accessor: "formQuestionId",
      title: "Soru Id",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Soru Id'si"
          description="Belirtilen id deki soruları ara."
          placeholder="Id ile ara..."
          icon={<IconSearch size={16} />}
          value={formQuestionIdQuery}
          onChange={(e) => setFormQuestionIdQuery(e.currentTarget.value)}
        />
      ),
      filtering: formQuestionIdQuery !== "",
    },
    {
      accessor: "label",
      title: "Soru",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen açıklamayı içeren tarlaları ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={labelQuery}
          onChange={(e) => setLabelQuery(e.currentTarget.value)}
        />
      ),
      filtering: labelQuery !== "",
    },
    {
      accessor: "isRequired",
      title: "Zorunlu Mu?",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Zorunlu mu?"
          description="Zorunlu mu parametresi ile ara"
          placeholder="Zorhnlu mu ile ara..."
          icon={<IconSearch size={16} />}
          value={isRequiredQuery}
          onChange={(e) => seIsRequiredQuery(e.currentTarget.value)}
        />
      ),
      filtering: isRequiredQuery !== "",
    },

    {
      accessor: "options",
      title: "Seçenekler",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Seçenekler"
          description="Seçenekleri içeren formları ara."
          placeholder="Seçenek ara..."
          icon={<IconSearch size={16} />}
          value={optionsQuery}
          onChange={(e) => setOptionsQuery(e.currentTarget.value)}
        />
      ),
      filtering: optionsQuery !== "",
    },
  ];

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Formlar", href: "/survey/list" },
        { title: "Soru Raporu ", href: "#" },
      ]}
      title="Soru Raporu"
    >
      <Paper
        shadow="md"
        p={20}
        className="widgets"
        bg="white"
        style={{
          borderTopLeftRadius: "23px",
          borderTopRightRadius: "23px",
        }}
      >
        <LoadingOverlay
          visible={fetching || false}
          overlayBlur={2}
          loaderProps={{
            size: "md",
            color: "#0097c4",
            variant: "oval",
          }}
        />
        <Tabs color="#eef0f8" variant="pills" defaultValue="first">
          <Tabs.List
            style={{
              backgroundColor: "#eef0f8",
              paddingTop: "10px",
              paddingBottom: "10px",
              height: "60px",
              display: "flex",
              borderRadius: "24px",
              justifyContent: "space-around",
            }}
          >
            <Tabs.Tab
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                paddingLeft: "101px",
                paddingRight: "101px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
                color: "#000000",
                // Seçili tabın arka plan rengi
              }}
              value="first"
            >
              Soru Raporu
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="first">
            <Grid>
              <Grid.Col sm={12} md={12}>
                <Grid>
                  <Grid.Col sm={12} md={10}>
                    <Col span={12}>
                      <Text
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins-Medium",
                          color: "#136583",
                        }}
                      >
                        {" "}
                        Form:
                      </Text>
                      {formsData && (
                        <Select
                          placeholder="--- Seçiniz ---"
                          data={
                            formsData.forms.map((form) => ({
                              value: form.id!,
                              label: form.name,
                            })) || []
                          }
                          onChange={(value) => {
                            setForm(value);
                          }}
                          value={form}
                          clearable
                        />
                      )}
                    </Col>
                  </Grid.Col>

                  <Grid.Col sm={12} md={2}>
                    <Col span={12}>
                      <Button
                        style={{
                          backgroundColor: "#0097c4",
                          paddingBottom: "11.5px",
                          paddingTop: "11.5px",
                          width: "100%",
                          borderRadius: 32,
                          color: "#FFFFFF",
                          textAlign: "center",
                          fontSize: "12px",
                          alignItems: "right",
                          fontFamily: "Poppins-Medium",
                        }}
                        onClick={() => {
                          handleExportToExcel();
                        }}
                      >
                        Excel'e Aktar
                      </Button>
                    </Col>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                <DataTable
                  withColumnBorders
                  highlightOnHover
                  minHeight={250}
                  noRecordsText="Form Bulunamadı..."
                  className="datatable"
                  records={pageData}
                  columns={columns}
                  totalRecords={sortedData.length}
                  recordsPerPage={PAGE_SIZE}
                  page={page}
                  onPageChange={(p) => setPage(p)}
                  fetching={fetching}
                  loaderColor="#0097c4"
                  sortStatus={sortStatus}
                  onSortStatusChange={handleSortStatusChange}
                  sortIcons={{
                    sorted: <IconSortDescending size={14} />,
                    unsorted: <IconSortDescending size={14} />,
                  }}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </MainWrapper>
  );
};

export default QuetionReport;

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  List,
  Loader,
  Modal,
  Paper,
  Select,
  Text,
  rem,
} from "@mantine/core";
import React, {
  HtmlHTMLAttributes,
  Suspense,
  lazy,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../connector/ModuleHook";
import { RootState } from "../../connector/ModuleStore";
import {
  getAgroNotes,
  SatelliteService,
} from "../../../satellite/interactor/SatelliteInteractor";
import { isEmpty } from "lodash";
import { MyFieldResponse } from "../../../myfields/data/response/MyFieldResponses";
import { toast } from "react-toastify";
import { SatelliteResponse } from "../../data/response/SatelliteResponse";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  AgrovisioFieldDatesRequest,
  AgrovisioFieldImageRequest,
  AgrovisioImageTypes,
  DummyRequests,
} from "../../../fielddetail/data/request/FieldDetailRequests";
import { Dot } from "@phosphor-icons/react";
import {
  agrovisioFieldDates,
  agrovisioFieldImage,
  agrovisioImageTypes,
  fetchFieldInfo,
} from "../../../fielddetail/interactor/FieldDetailInteractor";
import NotificationPanel from "../NotificationPanel";
import { useWindowSize } from "@uidotdev/usehooks";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import moment from "moment";
import { IconCloud, IconInfoCircle } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { da } from "date-fns/locale";

// Gerekli bileşenleri kaydedin
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const satellite = process.env.PUBLIC_URL + "/assets/images/satellite.png";
const bitkiSagligi = process.env.PUBLIC_URL + "/assets/images/BitkiSagligi.png";
const azotDurumu = process.env.PUBLIC_URL + "/assets/images/AzotDurumu.png";
const suStresi = process.env.PUBLIC_URL + "/assets/images/SuStresi.png";
const bulutlulukDurumu =
  process.env.PUBLIC_URL + "/assets/images/BulutlulukDurumu.png";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);

mapboxgl.accessToken =
  "pk.eyJ1IjoiemFoaWRpbWVjZSIsImEiOiJjbHZldnl3MG8wYmNvMnFvNHJtMm44aXZhIn0.v524HPF3Z_kBWdR76fAzUQ";

interface AccountProps {
  seasonId?: string;
}

export const options = {
  title: "Line intervals, default",
  curveType: "function",
  lineWidth: 4,
  intervals: { style: "line" },
  legend: "none",
};

const Satellite: React.FC<AccountProps> = ({ seasonId }) => {
  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();
  const size = useWindowSize();
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [notes, setNotes] = useState<string | null>(null); // State to manage notes
  const [noteTitle, setNoteTitle] = useState<string>("");
  const [isClosed, setIsClosed] = useState(false);

  const [agroModalOpen, setAgroModalOpen] = useState(false);
  const [chartModalOpen, setChartModalOpen] = useState(false);

  const sataliteService = useSelector(
    (state: RootState) => state.satelliteState.value
  );

  const sataliteData = sataliteService?.data;

  const fieldService = useSelector(
    (state: RootState) => state.fieldState.value
  );

  const getFieldInfoState = useAppSelector(
    (state) => state.getFieldInfoState.value?.data
  );

  const fieldData = fieldService?.data?.fields;

  const AgroState = useAppSelector((state) => state.satelliteState.value);

  const AgrNoteoState = useAppSelector(
    (state: RootState) => state.getNoteAgroState.value
  );

  const agroNoteTitles =
    AgrNoteoState?.data?.fieldsImageNotes?.map((note) => note.title) ?? [];

  const agroNoteTitle = agroNoteTitles.length > 0 ? agroNoteTitles[0] : "";

  const agroNoteText =
    AgrNoteoState?.data?.fieldsImageNotes?.map((note) => note.text) ?? [];

  const agroNotText = agroNoteText.length > 0 ? agroNoteText[0] : "";

  const handleFieldSelect = (value: string) => {
    setFetching(true);

    dispatch(SatelliteService(value)).then((response) => {
      if (response !== undefined) {
        let payload = response.payload as SatelliteResponse | undefined;
        if (payload?.success) {
          dispatch(fetchFieldInfo({ id: value }));
          dispatch(
            getAgroNotes({
              AgrovisioFieldId: payload.data?.agrovisioFieldId ?? "",
              AgrovisioName: value,
            })
          );
          // selectedFieldId yerine value kullanılıyor
          setFetching(false);
        } else {
          setFetching(false);
          toast.error(payload?.userMessage);
        }
      }
    });
  };
  const seasons = getFieldInfoState?.field?.seasons;
  const lastSeasonName =
    seasons && seasons.length > 0 ? seasons[seasons.length - 1].seasonName : "";
  const firstCultivationAreaName =
    seasons && seasons.length > 0
      ? seasons[seasons.length - 1].cultivationAreaName
      : "";
  const plantName =
    seasons && seasons.length > 0 ? seasons[seasons.length - 1].plantName : "";
  const plantVariantName =
    seasons && seasons.length > 0
      ? seasons[seasons.length - 1].plantVariantName
      : "";
  const plantDate =
    seasons && seasons.length > 0
      ? seasons[seasons.length - 1].plantingDate
      : "";
  const harvestDate =
    seasons && seasons.length > 0
      ? seasons[seasons.length - 1].harvestDate
      : "";

  const { fieldId } = useParams();

  const agrovisioFieldId = sataliteData?.agrovisioFieldId ?? "";

  const fieldIdforNotes = sataliteData?.agrovisioFieldId ?? "";

  useEffect(() => {
    dispatch(MyFieldService()).then((response) => {
      if (response !== undefined) {
        let payload = response.payload as MyFieldResponse | undefined;
        if (payload?.success) {
          setFetching(false);
        } else {
          setFetching(false);
          toast.error(payload?.userMessage);
        }
      }
    });
  }, [dispatch]);

  const selectItems = fieldData?.map((field) => ({
    value: field.id,
    label: field.fieldName,
  }));

  useEffect(() => {
    if (fieldService && fieldId) {
      handleFieldSelect(fieldId);
      setSelectedFieldId(fieldId);
    }
  }, [fieldService, fieldId]);

  let imageTypeNamesData = [
    { value: "Sentinel-2", label: "10x10 (Sentinel-2)" },
    { value: "PlanetScope-8Bands", label: "3x3 (PlanetScope-8Bands)" },
  ];

  let formattedPlantDate;
  if (plantDate) {
    formattedPlantDate = new Date(plantDate).toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else {
    <Dot size={24} />;
  }

  let formattedHarvestDate;
  if (harvestDate) {
    formattedHarvestDate = new Date(harvestDate).toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  } else {
    <Dot size={24} />;
  }

  const handleCloseNotification = () => {
    setNotes(null);
    setNoteTitle("");
    setIsClosed(true);
  };

  // Eğer bir seçili alan ID'si varsa, bu ID'ye karşılık gelen tarlanın adını ve ID'sini varsayılan olarak belirle
  const defaultValue = selectedFieldId ? selectedFieldId : undefined;

  //**************MAPBOX*********************/

  const node = useRef(null);

  const [imageTypeNames, setImageTypeNames] = useState(imageTypeNamesData);

  const [selectedAgrovisioFieldId, setSelectedAgrovisioFieldId] = useState<
    string | null
  >(null);
  const [selectedImageTypeId, setSelectedImageTypeId] = useState<any | null>(
    null
  );
  const [avaibleServiceTypesAndDates, setAvaibleServiceTypesAndDates] =
    useState<any | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [agrovisioImageResponse, setAgrovisioImageResponse] = useState<
    any | null
  >(null);

  const [proSatelliteExits, setProSatelliteExits] = useState<boolean | null>(
    null
  );

  const [isLoding, setIsLoading] = useState(false);

  const [cloudyDay, setCloudyDay] = useState(false);

  const [dayErrorTitle, setDayErrorTitle] = useState<string | null>(null);
  const [dayErrorMessage, setDayErrorMessage] = useState<string | null>(null);

  const [selectedImageTypeName, setSelectedImageTypeName] =
    useState("Sentinel-2");

  const [selectedImageTypeNameChart, setSelectedImageTypeNameChart] =
    useState("Sentinel-2");

  const [chartData, setChartData] = useState<any | null>(null);

  const [infoImage, setInfoImage] = useState(bitkiSagligi);

  const [mapWidth, setMapWidth] = useState(0);
  const paperRef = useRef<HTMLDivElement>(null);

  const fieldState = useAppSelector(
    (state) => state.fieldState.value?.data.fields
  );

  const agrovisioFieldImageState = useAppSelector(
    (state) => state.agrovisioFieldImageState.value?.data
  );

  const agrovisioImageTypesState = useAppSelector(
    (state) => state.agrovisioImageTypes.value?.data
  );

  const corporateSatelliteState = useAppSelector(
    (state) => state.corporateSatellite.value?.data
  );

  const agrovisioFieldDatesState = useAppSelector(
    (state) => state.agrovisioFieldDates.value?.data
  );

  const RezizeMap = () => {
    if (paperRef?.current) {
      setMapWidth(paperRef.current.offsetWidth - 80);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      RezizeMap();
      window.addEventListener("resize", RezizeMap);
      return () => window.removeEventListener("resize", RezizeMap);
    }, 2000);
  }, [size.width, paperRef]);

  useEffect(() => {
    if (sataliteService?.data) {
      dispatch(agrovisioImageTypes(new DummyRequests()));
      dispatch(
        agrovisioFieldImage(
          new AgrovisioFieldImageRequest(sataliteService?.data.agrovisioFieldId)
        )
      );
      setSelectedAgrovisioFieldId(sataliteService?.data.agrovisioFieldId);
      dispatch(
        agrovisioFieldDates(
          new AgrovisioFieldDatesRequest(
            sataliteService?.data.agrovisioFieldId,
            sataliteService?.data.agrovisioUserId
          )
        )
      );
    } else {
      // setIsLoading(true);
    }
  }, [sataliteService?.data]);

  useEffect(() => {
    MapAgrovisioDateSelectMaxDate(agrovisioFieldDatesState, true);
  }, [agrovisioFieldDatesState]);

  useEffect(() => {
    MapAgrovisioDateSelectMaxDate(agrovisioFieldDatesState, false);
  }, [selectedImageTypeName]);

  useEffect(() => {
    if (agrovisioImageTypesState?.agrovisioImageTypes) {
      let selectedImageType = agrovisioImageTypesState.agrovisioImageTypes.find(
        (item: any) => item.id == selectedImageTypeId
      );

      switch (selectedImageType?.name) {
        case "PlantHealth":
          setInfoImage(bitkiSagligi);
          break;
        case "WaterStress":
          setInfoImage(suStresi);
          break;
        case "Nitrogen":
          setInfoImage(azotDurumu);
          break;
        case "CloudStatus":
          setInfoImage(bulutlulukDurumu);
          break;
        default:
          setInfoImage(bitkiSagligi);
          break;
      }
    }
  }, [selectedImageTypeId]);

  const MapAgrovisioDateSelectMaxDate = (
    agrovisioFieldDatesState: any,
    isSet: boolean
  ) => {
    if (agrovisioFieldDatesState) {
      const resultArray = agrovisioFieldDatesState.imageTypes.map(
        (imageType: any) => {
          const distinctDays = Array.from(
            new Set(imageType.days.map((dayItem: any) => dayItem.day))
          );
          const cloundyDays = imageType.days
            .filter(
              (dayItem: any) =>
                dayItem.images[0].analysis.validPixelPercentage >= 0 &&
                dayItem.images[0].analysis.validPixelPercentage < 50
            )
            .map((dayItem: any) => dayItem.day);

          return {
            typeName: imageType.typeName,
            days: distinctDays,
            cloundyDays: cloundyDays,
          };
        }
      );

      const filteredArray = resultArray.filter(
        (item: any) => item.typeName === selectedImageTypeName
      );

      if (filteredArray.length > 0) {
        const today = moment();

        const sortedDays = filteredArray[0].days
          .map((day: any) => moment(day))
          .sort((a: any, b: any) => {
            return Math.abs(a.diff(today)) - Math.abs(b.diff(today));
          });

        const maxDate = sortedDays[0].toDate();

        setSelectedDate(maxDate);
      }

      if (isSet) {
        setAvaibleServiceTypesAndDates(resultArray);
      }
    }
  };

  useEffect(() => {
    if (agrovisioFieldDatesState) {
      const result: any = {};

      let checkproService: boolean = agrovisioFieldDatesState.imageTypes.some(
        (item: any) => item.typeName === "PlanetScope-8Bands"
      );
      if (proSatelliteExits === null) {
        if (checkproService) {
          setSelectedImageTypeName("PlanetScope-8Bands");
          setSelectedImageTypeNameChart("PlanetScope-8Bands");
          setProSatelliteExits(true);
        } else {
          setImageTypeNames([
            { value: "Sentinel-2", label: "10x10 (Sentinel-2)" },
          ]);
          setProSatelliteExits(false);
        }
      }
    }
  }, [agrovisioFieldDatesState, proSatelliteExits]);

  useEffect(() => {
    if (
      agrovisioFieldDatesState &&
      proSatelliteExits !== null &&
      selectedImageTypeNameChart
    ) {
      const result: any = {};

      agrovisioFieldDatesState.imageTypes.forEach((imageType) => {
        if (imageType.typeName === selectedImageTypeNameChart) {
          imageType.days.forEach((dayInfo) => {
            const day = new Date(dayInfo.day).toISOString().split("T")[0]; // Format date to YYYY-MM-DD
            dayInfo.images.forEach((image) => {
              image.analysis.indexes.forEach((index) => {
                if (
                  (selectedImageTypeNameChart === "PlanetScope-8Bands" &&
                    index.indexName !== "WaterStress") ||
                  selectedImageTypeNameChart === "Sentinel-2"
                ) {
                  const indexName = index.indexName;
                  const maxValue = index.max;
                  const minValue = index.min;
                  const meanValue = index.mean;

                  // Eğer indexName daha önce eklenmemişse, yeni bir dizi oluşturun
                  if (!result[indexName]) {
                    result[indexName] = [];
                  }

                  // Her bir indexName için günlere göre maxValue, minValue, meanValue ekleyin
                  result[indexName].push({
                    day,
                    maxValue,
                    minValue,
                    meanValue,
                  });
                }
              });
            });
          });
        }
      });
      const today = moment();

      const labels = [
        ...new Set(
          Object.values(result)
            .flat()
            .map((item: any) => item.day)
            .sort((a: any, b: any) => {
              const diffA = Math.abs(moment(a).diff(today));
              const diffB = Math.abs(moment(b).diff(today));
              return diffB - diffA;
            })
        ),
      ];

      const datasets = Object.keys(result).map((indexName) => ({
        label: getLabelAnalysis(indexName),
        data: labels.map((day) => {
          const found = result[indexName].find((item: any) => item.day === day);
          return found ? found.meanValue : null;
        }),
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: getRandomColor(indexName), // Her çizgi için farklı bir renk seçin
        additionalData: labels.map((day) => {
          const found = result[indexName].find((item: any) => item.day === day);
          return found
            ? {
                minValue: found.minValue,
                maxValue: found.maxValue,
                meanValue: found.meanValue,
              }
            : null;
        }),
      }));

      setChartData({
        labels,
        datasets,
      });
    }
  }, [
    agrovisioFieldDatesState,
    selectedImageTypeNameChart,
    proSatelliteExits,
    selectedImageTypeNameChart,
  ]);

  const getLabelAnalysis = (indexName: string) => {
    switch (indexName) {
      case "PlantHealth":
        return "Bitki Gelişimi";
      case "Nitrogen":
        return "Azot Durumu";
      case "WaterStress":
        return "Su Stresi";
    }

    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getRandomColor = (indexName: string) => {
    switch (indexName) {
      case "PlantHealth":
        return "#be4142";
      case "Nitrogen":
        return "#44bfbe";
      case "WaterStress":
        return "#7dae81";
    }

    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "",
        },
      },
      y: {
        title: {
          display: true,
          text: "Gelişim Durumu",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const dataPoint = context.dataset.additionalData[context.dataIndex];
            const label = context.dataset.label || "";
            if (dataPoint) {
              return `${label}: Minimum: ${dataPoint.minValue.toFixed(
                2
              )}, Maximum: ${dataPoint.maxValue.toFixed(
                2
              )}, Ortalama: ${dataPoint.meanValue.toFixed(2)}`;
            }
            return "";
          },
        },
      },
    },
  };

  useEffect(() => {
    if (selectedDate && agrovisioFieldDatesState?.imageTypes) {
      setCloudyDay(false);
      var days = agrovisioFieldDatesState?.imageTypes
        .filter((item: any) => item.typeName === selectedImageTypeName)
        .flatMap((item: any) => item.days);
      let date = days.find((item: any) =>
        moment(item.day).local().isSame(moment(selectedDate).local(), "day")
      );
      if (date) {
        if (date.images.length > 0) {
          if (
            date.images[0].analysis.validPixelPercentage < 50 &&
            date.images[0].analysis.validPixelPercentage >= 0
          ) {
            setDayErrorTitle("Yoğun Bulut Uyarısı");
            setDayErrorMessage(
              "Yoğun bulut sebebi ile uydu bu tarihteki görünümü gösteremiyor. Tarih değiştirerek haritayı güncelleyebilirsiniz."
            );
            setCloudyDay(true);
          } else if (date.images[0].analysis.validPixelPercentage == -1) {
            setDayErrorTitle("Analiz Henüz Hazırlanmadı");
            setDayErrorMessage(
              "Sistemimiz şu an tarlanızın analizini hazırlamaktadır. Lütfen daha sonra bu sayfayı tekrar ziyaret ediniz."
            );
            setCloudyDay(true);
          }
        }
      }
    }
  }, [selectedDate, agrovisioFieldDatesState]);

  const GetAgrovisioImage = async (
    agrovisioFieldImageState: any,
    selectedAgrovisioFieldId: any,
    selectedImageTypeId: any,
    agrovisioImageTypes: any
  ) => {
    let imageType = agrovisioImageTypes.find(
      (item: AgrovisioImageTypes) => item.id == selectedImageTypeId
    );
    if (!selectedDate) {
      return;
    }
    setIsLoading(true);

    try {
      const tokenData = agrovisioFieldImageState.accessToken;

      if (tokenData) {
        const url = `${
          process.env.REACT_APP_BASE_URL
        }external/agrovisio/partner-api/v1/field/image?fieldId=${selectedAgrovisioFieldId}&imageTypeId=${
          imageType?.agrovisioImageTypeId
        }&imageIndexId=${imageType?.agrovisioImageIndexId}&imageDate=${
          moment(selectedDate).add(3, "hours").toISOString().substring(0, 10) +
          "T00%3A00%3A00.000Z"
        }`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${tokenData}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        setAgrovisioImageResponse(URL.createObjectURL(blob));

        setIsLoading(false);

        // if (response?.status === 200) {
        //   setAgrovisioImageResponse(URL.createObjectURL(response.data));
        // }
      }
    } catch {
      setAgrovisioImageResponse(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      agrovisioFieldImageState &&
      selectedAgrovisioFieldId &&
      selectedImageTypeId &&
      agrovisioImageTypesState?.agrovisioImageTypes
    ) {
      // dispatch(
      //   agrovisioFieldImage(
      //     new AgrovisioFieldImageRequest(selectedAgrovisioFieldId)
      //   )
      // );
      GetAgrovisioImage(
        agrovisioFieldImageState,
        selectedAgrovisioFieldId,
        selectedImageTypeId,
        agrovisioImageTypesState?.agrovisioImageTypes
      );
    }
  }, [
    agrovisioFieldImageState,
    selectedAgrovisioFieldId,
    selectedImageTypeId,
    selectedDate,
    agrovisioImageTypesState,
  ]);

  useEffect(() => {
    if (agrovisioImageTypesState?.agrovisioImageTypes) {
      setSelectedImageTypeId(
        agrovisioImageTypesState.agrovisioImageTypes.filter(
          (item: AgrovisioImageTypes) =>
            item.imageTypeName === selectedImageTypeName
        )[0].id
      );
    }
  }, [agrovisioImageTypesState, selectedImageTypeName]);

  useEffect(() => {
    if (
      fieldState &&
      agrovisioImageTypesState &&
      selectedImageTypeName &&
      selectedFieldId
    ) {
      var getFieldInfoState: any = fieldState.find(
        (item: any) => item.id == selectedFieldId
      );
      if (getFieldInfoState) {
        const map = new mapboxgl.Map({
          container: node.current ?? "",
          style: "mapbox://styles/zahidimece/clvew32w3012h01qp2uctah1f",
          center: [
            getFieldInfoState.address.longitude,
            getFieldInfoState.address.latitude,
          ] as LngLatLike,
          zoom: 15,
          maxZoom: 18,
          minZoom: 15,
        });
        map.on("load", () => {
          if (agrovisioImageResponse) {
            const imageData = agrovisioImageResponse;

            if (getFieldInfoState.address?.coordinates) {
              map.addSource("overlayImage", {
                type: "image",
                url: imageData,
                coordinates: getPolygonCoordinates(
                  getFieldInfoState.address.coordinates
                ),
              });
              map.addLayer({
                id: "overlayImageLayer",
                source: "overlayImage",
                type: "raster",
                paint: {
                  "raster-opacity": 0.85,
                },
              });
              map.addSource("polygon", {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      polygonBounds(getFieldInfoState.address.coordinates),
                    ],
                  },
                  properties: {},
                } as GeoJSON.Feature<GeoJSON.Polygon>,
              });
              // Add the fill layer (transparent interior)
              map.addLayer({
                id: "polygon-fill",
                type: "fill",
                source: "polygon",
                layout: {},
                paint: {
                  "fill-color": "rgba(0, 0, 0, 0)", // Transparent fill
                  "fill-opacity": 0, // Ensure the fill is fully transparent
                },
              });
              // Add the outline layer (dark navy blue border)
              map.addLayer({
                id: "polygon-outline",
                type: "line",
                source: "polygon",
                layout: {},
                paint: {
                  "line-color": "#dc1919", // Dark navy blue
                  "line-width": 3, // Adjust the width as needed
                },
              });
            } else {
              setDayErrorTitle("Uyarı");
              setDayErrorMessage(
                "Seçili tarla/tesis için polygon bilgisi alınamadı."
              );
            }
          }
        });
        return () => {
          map.remove();
        };
      }
    }
  }, [
    fieldState,
    agrovisioImageTypesState,
    selectedImageTypeName,
    agrovisioImageResponse,
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = () => {
    setIsOpen(!isOpen);
  };

  const findCenterPoint = (coordinates: any[]) => {
    if (!coordinates || coordinates.length === 0) {
      return [0, 0] as LngLatLike;
    }

    let totalLat = 0;
    let totalLon = 0;

    coordinates.forEach((coord) => {
      totalLat += coord.latitude;
      totalLon += coord.longitude;
    });
    const avgLat = totalLat / coordinates.length;
    let avgLon = totalLon / coordinates.length;
    avgLon = ((avgLon + 180) % 360) - 180;
    return [avgLon, avgLat] as LngLatLike;
  };

  const getPolygonCoordinates = (coordinates: any[]) => {
    if (!coordinates || coordinates.length === 0) {
      return [];
    }

    const lats = coordinates.map((coord) => coord.latitude);
    const lons = coordinates.map((coord) => coord.longitude);

    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLon = Math.min(...lons);
    const maxLon = Math.max(...lons);

    return [
      [minLon, maxLat],
      [maxLon, maxLat],
      [maxLon, minLat],
      [minLon, minLat],
    ];
  };

  const polygonBounds = (coordinates: any[]) => {
    if (!coordinates || coordinates.length === 0) {
      return [0, 0];
    }
    return coordinates.map((coord) => [coord.longitude, coord.latitude]);
  };

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Uydu Görüntüsü", href: "#" }]}
        title="Uydu Görüntüsü"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Paper
            shadow="xs"
            p="xl"
            pt="0"
            className="profile-paper"
            ref={paperRef}
          >
            <Container fluid>
              <Grid>
                <Grid.Col span={12}>
                  <Text
                    style={{ fontSize: "12px", fontFamily: "Poppins-Medium" }}
                    py={rem(10)}
                  >
                    Tarla Seç
                  </Text>
                  <Select
                    data={selectItems || []}
                    searchable
                    placeholder="Tarla Seç"
                    defaultValue={defaultValue} // Seçili alanı otomatik olarak belirle
                    value={selectedFieldId}
                    onChange={(value) => {
                      if (value !== null) {
                        setSelectedFieldId(value); // select değeri değiştiğinde state'i güncelle
                        handleFieldSelect(value); // seçilen tarlaya göre veriyi getir
                        setChartData(null);
                      }
                    }}
                    sx={{
                      Input: {
                        height: "52px",
                        borderRadius: "18px",
                      },
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Flex direction="column">
                    <Text
                      fs="l"
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Light",
                        color: "#000000",
                        marginBottom: "8px",
                      }}
                    >
                      {lastSeasonName}
                    </Text>
                    <Flex direction="row" wrap="wrap" align="center" gap="xs">
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Yetiştirilme Alanı:
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {firstCultivationAreaName}
                      </Text>
                      <Dot size={24} />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Ürün:
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {plantName}
                      </Text>
                      <Dot size={24} />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Ürün Tipi:
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {plantVariantName}
                      </Text>
                      <Dot size={24} />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Ekim ve Dikim Tarihi:
                      </Text>

                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {formattedPlantDate}
                      </Text>
                      <Dot size={24} />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Hasat Tarihi:
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {formattedHarvestDate}
                      </Text>
                      <Dot size={24} />
                    </Flex>
                    <Flex direction="row" wrap="wrap" align="center" gap="xs">
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                          color: "#136583",
                        }}
                      >
                        Tarla Id:
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins-Light",
                        }}
                      >
                        {sataliteData?.agrovisioFieldId}
                      </Text>
                    </Flex>
                  </Flex>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Flex justify={"flex-start"} align={"flex-start"}>
                    {fetching ? (
                      <Loader color="#0097c4"></Loader>
                    ) : (
                      !isEmpty(sataliteData) && (
                        <>
                          {/* <iframe
                            title="Satellite Image"
                            src={process.env.REACT_APP_AGRO_FRAME?.replace(
                              "{token}",
                              sataliteData?.imeceToken ?? ""
                            ).replace(
                              "{fieldId}",
                              sataliteData?.agrovisioFieldId ?? ""
                            )}
                            width="100%"
                            height="750px"
                            style={{ border: "none", borderRadius: "32px" }}
                          /> */}
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: 10,
                                left: 10,
                                zIndex: 1,
                                display: "flex",
                              }}
                            >
                              {avaibleServiceTypesAndDates && (
                                <div style={{ display: "flex" }}>
                                  <DateInput
                                    placeholder="Tarih Seçiniz"
                                    value={selectedDate}
                                    locale="tr"
                                    renderDay={(date: Date) => {
                                      const days =
                                        avaibleServiceTypesAndDates.some(
                                          (item: any) =>
                                            item.typeName ===
                                            selectedImageTypeName
                                        )
                                          ? avaibleServiceTypesAndDates.filter(
                                              (item: any) =>
                                                item.typeName ===
                                                selectedImageTypeName
                                            )[0].days
                                          : [];

                                      let checkNoExits = !days.some(
                                        (day: string) =>
                                          moment(day)
                                            .local()
                                            .format("YYYY-MM-DD") ===
                                          moment(date)
                                            .local()
                                            .format("YYYY-MM-DD")
                                      );

                                      let cloundyDay = !checkNoExits
                                        ? avaibleServiceTypesAndDates
                                            .filter(
                                              (item: any) =>
                                                item.typeName ===
                                                selectedImageTypeName
                                            )[0]
                                            .cloundyDays.some(
                                              (day: string) =>
                                                moment(day)
                                                  .local()
                                                  .format("YYYY-MM-DD") ===
                                                moment(date)
                                                  .local()
                                                  .format("YYYY-MM-DD")
                                            )
                                        : false;

                                      return cloundyDay ? (
                                        <div
                                          style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            color: "#FFF",
                                            width: "25px",
                                            height: "28px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span>
                                            <IconCloud
                                              color="#373ea4"
                                              style={{
                                                width: "25px",
                                                height: "32px",
                                              }}
                                            />
                                            {/* {moment(date).local().format("D")} */}
                                          </span>
                                        </div>
                                      ) : checkNoExits ? (
                                        <div
                                          style={{
                                            width: "25px",
                                            height: "28px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span>
                                            {moment(date).local().format("D")}
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor: "#1b9ec7",
                                            borderRadius: "50%",
                                            color: "#FFF",
                                            width: "25px",
                                            height: "28px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span>
                                            {moment(date).local().format("D")}
                                          </span>
                                        </div>
                                      );
                                    }}
                                    excludeDate={(date) => {
                                      const days =
                                        avaibleServiceTypesAndDates.some(
                                          (item: any) =>
                                            item.typeName ===
                                            selectedImageTypeName
                                        )
                                          ? avaibleServiceTypesAndDates.filter(
                                              (item: any) =>
                                                item.typeName ===
                                                selectedImageTypeName
                                            )[0].days
                                          : [];

                                      return !days.some(
                                        (day: string) =>
                                          moment(day)
                                            .local()
                                            .format("YYYY-MM-DD") ===
                                          moment(date)
                                            .local()
                                            .format("YYYY-MM-DD")
                                      );
                                    }}
                                    onChange={(e: any) => {
                                      const dateValue = e
                                        ? new Date(e)
                                        : new Date();

                                      setSelectedDate(dateValue);
                                    }}
                                    style={{ width: "125px" }}
                                  />
                                  {cloudyDay && (
                                    <IconCloud
                                      color="white"
                                      size="32px"
                                      style={{ marginLeft: "8px" }}
                                    />
                                  )}
                                </div>
                              )}
                              &nbsp;&nbsp;&nbsp;
                              {agrovisioImageTypesState?.agrovisioImageTypes && (
                                <Select
                                  placeholder="Seçiniz"
                                  value={selectedImageTypeId}
                                  onChange={(e) => setSelectedImageTypeId(e)}
                                  data={
                                    agrovisioImageTypesState.agrovisioImageTypes
                                      .filter(
                                        (item: AgrovisioImageTypes) =>
                                          item.imageTypeName ===
                                          selectedImageTypeName
                                      )
                                      .map((item: AgrovisioImageTypes) => ({
                                        value: item.id ?? "",
                                        label: item.textName ?? "",
                                      })) || []
                                  }
                                  style={{ width: "160px" }}
                                ></Select>
                              )}
                              &nbsp;&nbsp;&nbsp;
                              <Select
                                data={imageTypeNames || []}
                                id="imageTypeSelect"
                                value={selectedImageTypeName}
                                onChange={(value) => {
                                  if (value) {
                                    setSelectedImageTypeName(value);
                                    setSelectedImageTypeNameChart(value);
                                  }
                                }}
                                style={{ width: "160px" }}
                              />
                              {/* <div
                                style={{
                                  position: "relative",
                                  height: "30px",
                                  width: "100%",
                                }}
                              >
                              
                              </div> */}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "20px",
                                backgroundColor: "#1b9ec7",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                zIndex: 10,
                              }}
                            >
                              <img
                                src={infoImage}
                                alt="info"
                                width={128}
                                height={128}
                              />
                            </div>

                            <div
                              style={{
                                position: "absolute",
                                cursor: "pointer",
                                top: "20px",
                                right: "20px",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                                zIndex: 10,
                              }}
                              onClick={() => setAgroModalOpen(true)}
                            >
                              <IconInfoCircle size={40} color="#FFF" />
                            </div>
                            {isLoding && (
                              <div
                                style={{
                                  width: mapWidth.toString() + "px",
                                  height: "500px",
                                  position: "absolute",
                                  left: "0",
                                  top: "0",
                                  right: "0",
                                  bottom: "0",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "rgba(35,35,35,0.3)",
                                  zIndex: 22,
                                }}
                              >
                                <img
                                  src={satellite}
                                  alt="satellite-icon"
                                  width={64}
                                  height={64}
                                />
                                <label
                                  style={{
                                    marginTop: "20px",
                                    color: "#FFF",
                                  }}
                                >
                                  Uydu Görüntüsü Beklenmektedir
                                </label>
                              </div>
                            )}
                            <Box>
                              <div
                                ref={node}
                                style={{
                                  height: "500px",
                                  width: mapWidth.toString() + "px",
                                }}
                              ></div>
                            </Box>
                          </div>
                          <Modal
                            opened={dayErrorTitle !== null}
                            onClose={() => setDayErrorTitle(null)}
                            className="custom-modal"
                            title={dayErrorTitle}
                            centered
                            size={"lg"}
                            withCloseButton
                            styles={{
                              content: {
                                borderRadius: "2rem",
                                padding: ".8rem",
                              },
                            }}
                            sx={{
                              ".mantine-Modal-title": {
                                fontSize: "20px !important",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            {dayErrorMessage && <Text>{dayErrorMessage}</Text>}
                          </Modal>
                        </>
                      )
                    )}
                  </Flex>
                  {chartData && (
                    <Flex
                      style={{
                        position: "relative",
                        maxHeight: "550px",
                        marginTop: "50px",
                        maxWidth: "1100px",
                      }}
                    >
                      {/* {proSatelliteExits && (
                        <div
                          style={{
                            position: "absolute",
                            right: "40px",
                            top: "20px",
                          }}
                        >
                          <label htmlFor="imageTypeSelect">
                            Görüntü Tipi Seçin:
                          </label>
                          <Select
                            data={imageTypeNames || []}
                            id="imageTypeSelect"
                            value={selectedImageTypeNameChart}
                            onChange={(value) => {
                              if (value) {
                                setSelectedImageTypeNameChart(value);
                              }
                            }}
                          />
                        </div>
                      )} */}

                      <div
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          top: "10px",
                          right: "10px",
                          zIndex: 10,
                        }}
                        onClick={() => setChartModalOpen(true)}
                      >
                        <IconInfoCircle size={40} color="#0098c6" />
                      </div>
                      <Line
                        data={chartData}
                        options={options}
                        style={{ height: "550px", backgroundColor: "#ddd" }}
                      />
                    </Flex>
                  )}
                </Grid.Col>
              </Grid>
            </Container>
          </Paper>
        </Suspense>
      </MainWrapper>
      {agroNoteTitle && agroNotText && !isClosed && (
        <NotificationPanel
          title={agroNoteTitle}
          message={agroNotText}
          onClose={handleCloseNotification}
        />
      )}{" "}
      <Modal
        title="Harita Hakkında Bilgilendirme"
        size="lg"
        styles={{
          content: {
            borderRadius: "2rem",
            padding: ".8rem",
          },
        }}
        sx={{
          ".mantine-Modal-title": {
            fontSize: "20px !important",
            fontWeight: "bold",
          },
        }}
        opened={agroModalOpen}
        onClose={() => setAgroModalOpen(false)}
      >
        <List>
          <List.Item style={{ fontSize: "12px" }}>
            “Bitki Sağlığı İndeksi” Karşınıza ilk olarak gelen bitki sağlığı
            indeksi bitkinin gelişim ve sağlık durumunu gösteren değerdir.
            Ekimden sonra bitki toprak yüzeyini kapatana kadar bitki sağlığını
            erken dönemden, bitkinizin toprak yüzeyini kapatıp olgunlaşma
            başlangıcına kadar olan dönemdeki bitki sağlığını genel dönemden ve
            bitkinin olgunlaşma başlangıcından hasada kadar olan süreçte bitki
            sağlığını geç dönemden incelemenizi öneririz. Sağlıklı bir bitki
            gelişiminde, parsel içerisinde homojen dağılım beklenmektedir. Bu
            indekste ‘kırmızı’ toprak rengini sembolize etmekteyken, ‘turuncu’
            çok zayıf, ‘sarı’ zayıf, ‘sarımsı yeşil’ ortalama, ‘yeşil’ sağlıklı,
            ‘koyu yeşil’ çok sağlıklı olmak üzere fenolojik sırayla bu şekilde
            seyretmektedir.
          </List.Item>
          <List.Item style={{ fontSize: "12px", marginTop: "20px" }}>
            “Su Stresi İndeksi” Su stresi indeksi, bitki örtüsünün su içeriğini
            belirlemek için kullanılır. Su stresi indeksinin minimum ve maksimum
            değerleri sırasıyla -1 ve 1’dir. Negatif değerlere doğru gidildikçe
            bitkide su stresi artmaktadır. Bu indeksin tarla genelinde homojen
            olarak dağıldığı gözlemlenmelidir. Aksi takdirde tarlada sulama
            kaynaklı veya tarlanın eğimi ile ilgili bir sorun olduğu
            düşünülmelidir.
          </List.Item>
          <List.Item style={{ fontSize: "12px", marginTop: "20px" }}>
            “Azot Durumu İndeksi” Bitkinin bünyesine aldığı azot durumunu
            gösteren değerdir. Sağlıklı bir bitki gelişiminde, parsel içerisinde
            homojen dağılım beklenmektedir. Azot durumu indeksi yaprakların
            bünyesinde bulunan azot içeriğinin belirlenmesinde kullanılır. Azot
            indeksi ile bitkinin bünyesine azot alım sürecini, hangi bölgelerde
            azotu daha iyi aldığını ve hangi bölgelerde daha az aldığını
            görebilirsiniz.
          </List.Item>
        </List>
      </Modal>
      <Modal
        title="Grafik Hakkında Bilgilendirme"
        size="lg"
        styles={{
          content: {
            borderRadius: "2rem",
            padding: ".8rem",
          },
        }}
        sx={{
          ".mantine-Modal-title": {
            fontSize: "20px !important",
            fontWeight: "bold",
          },
        }}
        opened={chartModalOpen}
        onClose={() => setChartModalOpen(false)}
      >
        {" "}
        <List>
          <List.Item style={{ fontSize: "12px" }}>
            “Bitki Gelişimi” butonuna tıkladığınızda tarlanızın bitki gelişim
            grafiğini görebilirsiniz.{" "}
          </List.Item>
          <List.Item style={{ fontSize: "12px", marginTop: "20px" }}>
            “Su Stresi” butonuna tıkladığınızda tarlanızın su stres grafiğini
            görebilirsiniz.
          </List.Item>
          <List.Item style={{ fontSize: "12px", marginTop: "20px" }}>
            “Azot Durumu” butonuna tıkladığınızda tarlanızın azot durumu
            grafiğini görebilirsiniz.
          </List.Item>
        </List>
      </Modal>
    </Suspense>
  );
};

export default Satellite;

// eslint-disable-next-line
import {
  Grid,
  Image,
  LoadingOverlay,
  MediaQuery,
  Paper,
  Skeleton,
  rem,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, Suspense, lazy, useState } from "react";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAppDispatch } from "../../connector/ModuleHook";
import {
  MyFieldService,
  fetchProfileInfo,
} from "../../interactor/ProfileInteractor";
import { GetProfileResponse } from "../../data/request/ProfileRequests";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../../connector/ModuleStore";

interface AccountProps {
  store?: GetProfileResponse;
}

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);
const ProfileInfoField = lazy(() => import("../components/ProfilInfoField"));
const ProfileBox = lazy(() => import("../components/ProfileBox"));

const ProfileBackgroundImg =
  process.env.PUBLIC_URL + "assets/images/profile_background.png";

function ProfileV2({ store }: AccountProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, []);

  interface MyFieldTable {
    fieldName: string;
  }

  const myFieldService = useSelector(
    (state: RootState) => state.getFieldInfoState.value
  );

  useEffect(() => {
    dispatch(MyFieldService());
  }, []);

  const myFieldData = myFieldService?.data?.fields.length;
  const companyName = store?.name ?? "Veri Yok";
  const titleName = store?.title ?? "Veri Yok";
  const taxId = store?.taxId ?? "Veri Yok";
  const phoneNumber = store?.phoneNumber ?? "Veri Yok";
  const email = store?.email ?? "Veri Yok";
  const contactName = store?.contactName ?? "Veri Yok";
  const contactNumber = store?.contactNumber ?? "Veri Yok";
  const contactEmail = store?.contactEmail ?? "Veri Yok";
  const city = store?.city ?? "Veri Yok";
  const district = store?.district ?? "Veri Yok";
  const neighbourhood = store?.neighbourhood ?? "Veri Yok";
  const address = store?.address ?? "Veri Yok";
  const subscriptionType =
    store?.subscriptionType === 1 ? "Standart" : "Premium";
  const quota = store?.quota ?? "Veri Yok";
  const usedQuota = store?.usedQuota ?? "Veri Yok";
  const expirationTime = store?.expirationTime
    ? new Date(store.expirationTime).toLocaleDateString("tr-TR")
    : "Not Available";

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Firma Bilgileri", href: "/profile" }]}
        title="Firma Profili"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
            <Grid align="center">
              <Grid.Col xs={12} md={7}>
                <Grid>
                  <Grid.Col span={12}>
                    <ProfileBox title="Profil">
                      <ProfileInfoField
                        title="Firma Adı"
                        detail={companyName}
                      />
                      <ProfileInfoField title="Tabela Adı" detail={titleName} />
                      <ProfileInfoField title="VKN/TCKN" detail={taxId} />
                      <ProfileInfoField
                        title="Firma Telefonu"
                        detail={phoneNumber}
                      />
                      <ProfileInfoField title="Firma E-Posta" detail={email} />
                      <ProfileInfoField
                        title="Yetkili Ad-Soyad"
                        detail={contactName}
                      />
                      <ProfileInfoField
                        title="Yetkili Cep Telefonu"
                        detail={contactNumber}
                      />
                      <ProfileInfoField
                        title="Yetkili E-Posta"
                        detail={contactEmail}
                      />
                      <ProfileInfoField title="İl" detail={city} />
                      <ProfileInfoField title="İlçe" detail={district} />
                      <ProfileInfoField
                        title="Mahalle/Köy"
                        detail={neighbourhood}
                      />
                      {/*In case foreach last line should reset border bottom*/}
                      <ProfileInfoField
                        title="Açık Adres"
                        detail={address}
                        className="profile-border-bottom-reset"
                      />
                    </ProfileBox>
                  </Grid.Col>
                  <Grid.Col span={12} pt={rem(30)}>
                    <ProfileBox title="Kullanım Bilgileri">
                      <ProfileInfoField
                        title="Paket"
                        detail={subscriptionType}
                      />
                      <ProfileInfoField
                        title="Tanımlanan Kota"
                        detail={quota}
                      />
                      <ProfileInfoField
                        title="Kullanılan Kota"
                        detail={usedQuota}
                      />

                      <ProfileInfoField
                        title="Son Kullanma Tarihi"
                        detail={expirationTime}
                      />
                      <ProfileInfoField
                        title="Toplam Tarla Sayısı"
                        detail={myFieldData ?? ""}
                        className="profile-border-bottom-reset"
                      />
                    </ProfileBox>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <MediaQuery
                query="(max-width: 62em)"
                styles={{ display: "none" }}
              >
                <Grid.Col xs={0} md={5}>
                  <LazyLoadImage
                    src={ProfileBackgroundImg}
                    width={"100%"}
                    alt="Profile Background Image"
                    placeholder={<Skeleton />}
                    effect="blur"
                    style={{ objectFit: "contain" }}
                  />
                </Grid.Col>
              </MediaQuery>
            </Grid>
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
}

export default ProfileV2;

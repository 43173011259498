import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import { getNotificationsSlice, notificationGetCorporateInbox } from "../interactor/NotificationInteractor";
import MyFieldInteractor from "../../myfields/interactor/MyFieldInteractor";
import NotificationIsReadUpdateInteractor from "../interactor/NotificatiınIsReadUpdateInteractor";
import { is } from "date-fns/locale";
const rootReducer = combineReducers({
  getNotificationState: getNotificationsSlice.reducer,
  getNotificationS: notificationGetCorporateInbox,
  myFieldState: MyFieldInteractor,
  notificationIsReadUpdateState: NotificationIsReadUpdateInteractor,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { PlantVariantRequest } from "../data/request/PlantVariantRequest";
import { PlantVariantsResponse } from "../data/response/PlantVariantsResponse";

export const PlantVariantService = createAsyncThunk(
  "plants/getPlantVariants",
  async (plant: PlantVariantRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<PlantVariantsResponse>(
          `parameter/plants/${plant.PlantId}/plantvariants`
        );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const initialState: ServiceState<PlantVariantsResponse> = {};

const PlantVariantSlice = createSlice({
  name: "plantVariants",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PlantVariantService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(PlantVariantService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(PlantVariantService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO: Handle the error
      });
  },
});

export default PlantVariantSlice.reducer;

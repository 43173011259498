import { BaseRequest } from "../../../common/data/BaseRequest";

interface UpdateEmployee {
  employeeId: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    countryCode: string;
    phoneNumber: string;
  };
  authorityType: number;
  fields: {
    id: string;
    name: string;
  }[];
}

export class UpdateEmployeeRequest extends BaseRequest {
  employeeId: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    countryCode: string;
    phoneNumber: string;
  };
  authorityType: number;
  fields: {
    id: string;
    name: string;
  }[];

  constructor({
    employeeId,
    firstName,
    lastName,
    phoneNumber,
    authorityType,
    fields,
  }: UpdateEmployee) {
    super();
    this.employeeId = employeeId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.authorityType = authorityType;
    this.fields = fields;
  }
}

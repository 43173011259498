import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { GetProfileResponse } from "../data/response/GetProfileResponse";

export const UserProfileService = createAsyncThunk(
  "userProfile/getUserProfile",
  async () => {
    try {
      const response = await AxiosHelper.getInstance().get<GetProfileResponse>(
        "corporate/employees/profile"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState: ServiceState<GetProfileResponse> = {};

const UserProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserProfileService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(UserProfileService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(UserProfileService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

export default UserProfileSlice.reducer;

// eslint-disable
import {
  Box,
  Button,
  Checkbox,
  Col,
  Grid,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import "dayjs/locale/tr";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../connector/ModuleHook";
import moment from "moment";
import { FormAddRequest } from "../../data/request/FormAddRequest";
import { FormUpdateRequest } from "../../data/request/FormUpdateRequest";
import {
  FormAddService,
  FormDetailService,
  FormUpdateService,
} from "../../interractor/SurveyInterractor";
import { FormAddResponse } from "../../data/response/FormAddResponse";
import { FormDetailRequest } from "../../data/request/FormDetailRequest";
import { RootState } from "../../connector/ModuleStore";
import { FormDtoAdd } from "../../data/dto/FormDtoAdd";
import { FormDto } from "../../data/dto/FormDto";
import Swal from "sweetalert2";

const AddSurveyCard: React.FC = () => {
  const navigate = useNavigate();

  let { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isConstantForm, setIsConstantForm] = useState(false);

  const dispatch = useAppDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [formData, setFormData] = useState<FormDto | null>(null);

  useEffect(() => {
    if (id) {
      setShouldUpdate(true);
      dispatch(FormDetailService({ formId: id } as FormDetailRequest));
    }
  }, [id]);

  const formDetailData = useSelector(
    (state: RootState) => state.FormDetailState.value
  );

  useEffect(() => {
    if (formDetailData?.data?.form) {
      setFormData(formDetailData.data.form);
    }
  }, [formDetailData]);

  useEffect(() => {
    if (formData) {
      setName(formData.name);
      setDescription(formData.description);
      setIsConstantForm(formData.isConstantForm ?? false);
    }
  }, [formData]);

  const [submitted, setSubmitted] = useState(false);

  const [fetching, setFetching] = useState(false);

  const handleSave = () => {
    setSubmitted(true);
    if (!name || !description) {
      setFetching(false);
      Swal.fire({
        title: "Lütfen Gerekli Alanları Doldurunuz",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#0097c4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tamam",
      });
      return;
    }
    if (!shouldUpdate) {
      const fromAddRequest: FormAddRequest = {
        Form: {
          Name: name,
          Description: description,
          Title: "",
          SubTitle: "",
          IsConstantForm: isConstantForm,
        } as FormDtoAdd,
      };
      dispatch(FormAddService(fromAddRequest)).then((response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as FormAddResponse | undefined;
          if (responsePayload?.success) {
            MultiSelect.defaultProps = {
              value: [],
            };
            form.reset();
            Swal.fire({
              title: "Form başarı ile eklendi",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#0097c4",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
            }).then((result) => {
              if (result.isConfirmed) {
                ClearForm();
                navigate("/survey/list");
              }
            });
            setFetching(false);
          } else {
            Swal.fire({
              title: responsePayload?.userMessage,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#0097c4",
              cancelButtonColor: "#d33",
              confirmButtonText: "Tamam",
            });

            setFetching(false);
          }
        }
      });
    } else {
      const formUpdateRequest: FormUpdateRequest = {
        Form: {
          Id: formData!.id,
          Name: name,
          Description: description,
          Title: formData?.title ?? "",
          SubTitle: formData?.subTitle ?? "",
          IsConstantForm: isConstantForm,
        } as FormDtoAdd,
      };
      dispatch(FormUpdateService(formUpdateRequest)).then((response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as FormAddResponse | undefined;
          if (responsePayload?.success) {
            form.reset();
            toast.success("Form Düzenlendi");
            setFetching(false);
            setTimeout(() => {
              ClearForm();
              navigate("/survey/list");
            }, 2500);
          } else {
            toast.error(responsePayload?.userMessage);
            setFetching(false);
          }
        }
      });
    }
  };

  const ClearForm = () => {
    setName("");
    setDescription("");
    setIsConstantForm(false);
  };

  const form = useForm({
    validate: {
      name: (value: any) => {
        if (name.trim() == "") return "Form adı boş olamaz";
        return null;
      },
      description: (value) =>
        description.trim() == "" ? "Açıklama gririlmelidir" : null,
    },
  });

  return (
    <Paper p={20} className="widgets" radius={rem(12)} bg="white">
      <Space h={"30px"} />

      <Stack
        dir="column"
        p={20}
        justify={"center"}
        align={"center"}
        pos={"relative"}
      >
        <LoadingOverlay
          visible={fetching}
          overlayBlur={2}
          loaderProps={{ size: "md", color: "#0097c4", variant: "oval" }}
          pos={"absolute"}
        />

        <form
          onSubmit={form.onSubmit((values) => {})}
          style={{ width: "100%" }}
        >
          <Grid>
            <Grid.Col sm={12} md={6}>
              <Col span={12}>
                <Text
                  style={{
                    fontStyle: "bold",
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Form Adı
                </Text>
                <TextInput
                  tabIndex={1}
                  placeholder="Form adı giriniz"
                  {...form.getInputProps("name")}
                  onChange={(event) => (
                    form.getInputProps("name").onChange(event.target.value),
                    setName(event.target.value)
                  )}
                  value={name}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </Col>
              <Col span={12} pt={"20px"}>
                <Text
                  style={{
                    fontSize: "16px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                ></Text>
                <Checkbox
                  checked={isConstantForm}
                  label="Tarla Ziyaretçi Formu?"
                  onChange={(event) =>
                    setIsConstantForm(event.currentTarget.checked)
                  }
                />
              </Col>
            </Grid.Col>

            <Grid.Col sm={12} md={6}>
              <Col span={12}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Açıklama
                </Text>
                <TextInput
                  tabIndex={1}
                  placeholder="Açıklama giiriniz"
                  {...form.getInputProps("description")}
                  onChange={(event) => (
                    form
                      .getInputProps("description")
                      .onChange(event.target.value),
                    setDescription(event.target.value)
                  )}
                  value={description}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </Col>

              <Col span={12}></Col>
            </Grid.Col>
          </Grid>

          <Space h={"20px"} />

          <Grid>
            <Grid.Col offsetMd={9} md={3} sm={12}>
              <Button
                style={{
                  paddingBottom: "11.5px",
                  paddingTop: "11.5px",
                  width: "100%",
                  borderRadius: rem(32),
                  backgroundColor: "#0097c4",
                  color: "#FFFFFF",
                  fontFamily: "Poppins-Light",
                  fontSize: rem(12),
                  textAlign: "center",
                }}
                type="submit"
                onClick={() => {
                  handleSave();
                }}
              >
                Kaydet
              </Button>
            </Grid.Col>
          </Grid>
        </form>
      </Stack>
    </Paper>
  );
};

export default AddSurveyCard;

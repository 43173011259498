import React from "react";
import { useAppSelector } from "../../connector/ModuleHook";
import ProfileV2 from "./profileV2";

// import { useAppSelector } from "../../../dashboard/store/dashboardHook";

const Profile: React.FC = () => {
  const getProfileInfoState = useAppSelector(
    (state) => state.getProfileInfoState.value?.data
  );

  return <ProfileV2 store={getProfileInfoState} />;
};

export default Profile;

import React from "react";
import { Button, Flex, Paper, Text } from "@mantine/core";
import { EnvelopeOpen } from "@phosphor-icons/react";

interface NotificationPanelProps {
  title?: string;
  message?: string;
  onClose: () => void;
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({ title, message, onClose }) => {
  return (
    <Paper
      className="notification-panel"
      shadow="md"
      p="lg"
      withBorder
      style={{
        position: "fixed",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        marginBottom: "20px",
        width: "80%",
        maxWidth: "600px",
        zIndex: 9999,
        backgroundColor: "#fff",
        borderRadius: "16px",
        textAlign: "center",
      }}
    >
      <Flex direction="column" align="center" gap="md">
        <EnvelopeOpen size={96} color="#007bff" />
        <Text weight={700}>{title}</Text>
        <Text>{message}</Text>
        <Button onClick={onClose} variant="outline" color="blue">
          Anladım
        </Button>
      </Flex>
    </Paper>
  );
};

export default NotificationPanel;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { FormListResponse } from "../data/response/FormListResponse";
import { FormListRequest } from "../data/request/FormListRequest";
import { FormDetailResponse } from "../data/response/FormDetailResponse";
import { FormDetailRequest } from "../data/request/FormDetailRequest";
import { FormAddResponse } from "../data/response/FormAddResponse";
import { FormAddRequest } from "../data/request/FormAddRequest";
import { FormUpdateResponse } from "../data/response/FormUpdateResponse";
import { FormUpdateRequest } from "../data/request/FormUpdateRequest";
import { FormDeleteResponse } from "../data/response/FormDeleteResponse";
import { FormDeleteRequest } from "../data/request/FormDeleteRequest";
import { FormQuestionAddRequest } from "../data/request/FormQuestionAddRequest";
import { FormQuestionAddResponse } from "../data/response/FormQuestionAddResponse";
import { FormQuestionDetailResponse } from "../data/response/FormQuestionDetailResponse";
import { FormQuestionDetailRequest } from "../data/request/FormQuestionDetailRequest";
import { FormPublishResponse } from "../data/response/FormPublishResponse";
import { FormPublishRequest } from "../data/request/FormPublishRequest";
import { EmployeeResponse } from "../../users/data/response/EmployeeResponse";
import { AssignFormListResponse } from "../data/response/AssignFormListResponse";
import { AssignFormResponse } from "../data/response/AssignFormResponse";
import { AssignFormRequest } from "../data/request/AssignFormRequest";
import { AssignFormDeleteResponse } from "../data/response/AssignFormDeleteResponse";
import { AssignFormDeleteRequest } from "../data/request/AssignFormDeleteRequest";
import { QuestionFormReportRequest } from "../data/request/QuestionFormReportRequest";
import { QuestionFormReportResponse } from "../data/response/QuestionFormReportResponse";
import { FormAnswerAddRequest } from "../data/request/FormAnswerAddRequest";
import { AnswerFormAddResponse } from "../data/response/AnswerFormAddResponse";
import { AnswerListFormResponse } from "../data/response/AnswerListFormResponse";
import { AnswerReportFormResponse } from "../data/response/AnswerReportFormResponse";
import { AnswerReportFormRequest } from "../data/request/AnswerReportFormRequest";
import { AnswerListFormRequest } from "../data/request/AnswerListFormRequest";
import { ClearAnswersResponse } from "../data/response/ClearAnswersResponse";
import { ClearAnswersRequest } from "../data/request/ClearAnswersRequest";
import { AnswerReportExcelForm } from "../data/request/AnswerReportExcelForm";
import { AnswerReportFormExcelResponse } from "../data/response/AnswerReportFormExcelResponse";
import { UserPhoneCheckResponse } from "../data/response/UserPhoneCheckResponse";
import { UserPhoneCheckRequest } from "../data/request/UserPhoneCheckRequest";
import { GetFormTaskListResponse } from "../data/response/GetFormTaskListResponse";
import { DetailFormTaskResponse } from "../data/response/DetailFormTaskResponse";
import { DetailFormTaskRequest } from "../data/request/DetailFormTaskRequest";
import { CreateFormTaskResponse } from "../data/response/CreateFormTaskResponse";
import { CreateFormTaskRequest } from "../data/request/CreateFormTaskRequest";
import { UpdateFormTaskResponse } from "../data/response/UpdateFormTaskResponse";
import { DeleteFormTaskResponse } from "../data/response/DeleteFormTaskResponse";
import { DeleteFormTaskRequest } from "../data/request/DeleteFormTaskRequest";
import { UpdateFormTaskRequest } from "../data/request/UpdateFormTaskRequest";
import { AssignFormListRequest } from "../data/request/AssignFormListRequest";
import { AssignFormDetailResponse } from "../data/response/AssignFormDetailResponse";
import { FormTaskLocationCheckingResponse } from "../data/response/FormTaskLocationCheckingResponse";
import { FormTaskLocationCheckingRequest } from "../data/request/FormTaskLocationCheckingRequest";
import { CompleteFormTaskResponse } from "../data/response/CompleteFormTaskResponse";
import { CompleteFormTaskRequest } from "../data/request/CompleteFormTaskRequest";
import { CompleteStateFormTaskResponse } from "../data/response/CompleteStateFormTaskResponse";
import { CompleteStateFormTaskRequest } from "../data/request/CompleteStateFormTaskRequest";
import { DeleteFormFieldEmployeeImageRequest } from "../data/request/DeleteFormFieldEmployeeImageRequest";
import { DeleteFormFieldEmployeeImageResponse } from "../data/response/DeleteFormFieldEmployeeImageResponse";

const initialFormListState: ServiceState<FormListResponse> = {};

export const FormListService = createAsyncThunk("form/forms", async () => {
  try {
    //
    const response = await AxiosHelper.getInstance().get<FormListResponse>(
      `/form/forms`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const FormListSlice = createSlice({
  name: "form/forms",
  initialState: initialFormListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormListService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormListService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormListService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormDetailState: ServiceState<FormDetailResponse> = {};

export const FormDetailService = createAsyncThunk(
  "form/formdetail",
  async (request: FormDetailRequest) => {
    try {
      //
      const response = await AxiosHelper.getInstance().get<FormDetailResponse>(
        `/form/form?FormId=${request.formId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormDetailSlice = createSlice({
  name: "form/formdetail",
  initialState: initialFormDetailState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormDetailService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormDetailService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormDetailService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormAddState: ServiceState<FormAddResponse> = {};

export const FormAddService = createAsyncThunk(
  "form/formadd",
  async (request: FormAddRequest) => {
    try {
      //
      const response = await AxiosHelper.getInstance().post<FormAddResponse>(
        `/form/form?Form.Name=${request.Form.Name}&Form.Description=${request.Form.Description}&Form.Title=${request.Form.Title}&Form.SubTitle=${request.Form.SubTitle}&Form.IsConstantForm=${request.Form.IsConstantForm}`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormAddSlice = createSlice({
  name: "form/formadd",
  initialState: initialFormAddState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormAddService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormAddService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormAddService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormUpdateState: ServiceState<FormUpdateResponse> = {};

export const FormUpdateService = createAsyncThunk(
  "form/formupdate",
  async (request: FormUpdateRequest) => {
    try {
      //
      const response = await AxiosHelper.getInstance().put<FormUpdateResponse>(
        `/form/form?Form.Id=${request.Form.Id}&Form.Name=${request.Form.Name}&Form.Description=${request.Form.Description}&Form.Title=${request.Form.Title}&Form.SubTitle=${request.Form.SubTitle}&Form.IsConstantForm=${request.Form.IsConstantForm}`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormUpdateSlice = createSlice({
  name: "form/formupdate",
  initialState: initialFormUpdateState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormUpdateService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormUpdateService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormUpdateService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormDeleteState: ServiceState<FormDeleteResponse> = {};

export const FormDeleteService = createAsyncThunk(
  "form/forms",
  async (request: FormDeleteRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().delete<FormDeleteResponse>(
          `/form/forms?FormId=${request.formId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormDeleteSlice = createSlice({
  name: "form/forms",
  initialState: initialFormDeleteState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormDeleteService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormDeleteService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormDeleteService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormQuestionAddState: ServiceState<FormQuestionAddResponse> = {};

export const FormQuestionAddService = createAsyncThunk(
  "form/formquestionadd",
  async (request: FormQuestionAddRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().post<FormQuestionAddResponse>(
          `/form/form/question`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormQuesyionAddSlice = createSlice({
  name: "form/formquestionadd",
  initialState: initialFormQuestionAddState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormQuestionAddService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormQuestionAddService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormQuestionAddService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormQuestionDetailState: ServiceState<FormQuestionDetailResponse> =
  {};

export const FormQuestionDetailService = createAsyncThunk(
  "form/formquestiondetail",
  async (request: FormQuestionDetailRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<FormQuestionDetailResponse>(
          `/form/form/question?FormId=${request.formId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormQuestionAddSlice = createSlice({
  name: "form/formquestiondetail",
  initialState: initialFormQuestionDetailState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormQuestionDetailService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormQuestionDetailService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormQuestionDetailService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormPublishState: ServiceState<FormPublishResponse> = {};

export const FormPublishService = createAsyncThunk(
  "form/pubish",
  async (request: FormPublishRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().post<FormPublishResponse>(
          `/form/form/publish`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormPublishSlice = createSlice({
  name: "form/pubish",
  initialState: initialFormPublishState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormPublishService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormPublishService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormPublishService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAssignFormListState: ServiceState<AssignFormListResponse> = {};

export const AssignFormListService = createAsyncThunk(
  "form/assign/list",
  async (request: AssignFormListRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<AssignFormListResponse>(
          `/form/form/assign?FormTaskId=${request.formTaskId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AssignFormListSlice = createSlice({
  name: "form/assign/list",
  initialState: initialAssignFormListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssignFormListService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AssignFormListService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AssignFormListService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAssignFormState: ServiceState<AssignFormResponse> = {};

export const AssignFormService = createAsyncThunk(
  "form/assign/add",
  async (request: AssignFormRequest) => {
    try {
      //
      const response = await AxiosHelper.getInstance().post<AssignFormResponse>(
        `/form/form/assign`,
        request
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AssignFormSlice = createSlice({
  name: "form/assign/add",
  initialState: initialAssignFormState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssignFormService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AssignFormService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AssignFormService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAssignFormDeleteState: ServiceState<AssignFormDeleteResponse> = {};

export const AssignFormDeleteService = createAsyncThunk(
  "form/assign/delete",
  async (request: AssignFormDeleteRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().delete<AssignFormDeleteResponse>(
          `/form/form/assign?Id=${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AssignFormDeleteSlice = createSlice({
  name: "form/assign/delete",
  initialState: initialAssignFormDeleteState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssignFormDeleteService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AssignFormDeleteService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AssignFormDeleteService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialQuestionFormReportState: ServiceState<QuestionFormReportResponse> =
  {};

export const QuestionFormReportService = createAsyncThunk(
  "form/question/report",
  async (request: QuestionFormReportRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<QuestionFormReportResponse>(
          `/form/form/question/report?FormId=${request.formId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const QuestionFormReportSlice = createSlice({
  name: "form/question/report",
  initialState: initialQuestionFormReportState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(QuestionFormReportService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(QuestionFormReportService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(QuestionFormReportService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAnswerFormAddState: ServiceState<AnswerFormAddResponse> = {};

export const AnswerFormAddService = createAsyncThunk(
  "form/answer/add",
  async (request: FormAnswerAddRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().post<AnswerFormAddResponse>(
          `/form/form/answer`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AnswerFormAddSlice = createSlice({
  name: "form/answer/add",
  initialState: initialAnswerFormAddState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AnswerFormAddService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AnswerFormAddService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AnswerFormAddService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAnswerListFormState: ServiceState<AnswerListFormResponse> = {};

export const AnswerListFormService = createAsyncThunk(
  "form/answer/list",
  async (request: AnswerListFormRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<AnswerListFormResponse>(
          `/form/form/answer?FormId=${request.formId}&UserId=${
            request.userId ?? ""
          }&FormFieldEmployeeId=${
            request.formFieldEmployeeId ?? ""
          }&FormFieldVisitorId=${request.formFieldVisitorId ?? ""}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AnswerListFormSlice = createSlice({
  name: "form/answer/list",
  initialState: initialAnswerListFormState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AnswerListFormService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AnswerListFormService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AnswerListFormService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAnswerReportFormState: ServiceState<AnswerReportFormResponse> = {};

export const AnswerReportFormService = createAsyncThunk(
  "form/answer/report",
  async (request: AnswerReportFormRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<AnswerReportFormResponse>(
          `/form/form/answer/report?FormId=${request.formId}&UserId=${request.userId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AnswerReportFormSlice = createSlice({
  name: "form/answer/report",
  initialState: initialAnswerReportFormState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AnswerReportFormService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AnswerReportFormService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AnswerReportFormService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialClearAnswersState: ServiceState<ClearAnswersResponse> = {};

export const ClearAnswersService = createAsyncThunk(
  "form/clearanswers",
  async (request: ClearAnswersRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().post<ClearAnswersResponse>(
          `/form/form/clearanswers`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const ClearAnswersSlice = createSlice({
  name: "form/clearanswers",
  initialState: initialClearAnswersState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ClearAnswersService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(ClearAnswersService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(ClearAnswersService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAnswerReportFormExcelState: ServiceState<AnswerReportFormExcelResponse> =
  {};

export const AnswerReportFormExcelService = createAsyncThunk(
  "form/answersexcel",
  async (request: AnswerReportExcelForm) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().get<AnswerReportFormExcelResponse>(
          `/form/form/answer/excel?FormTaskId=${request.formTaskId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AnswerReportFormExcelSlice = createSlice({
  name: "form/answersexcel",
  initialState: initialAnswerReportFormExcelState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AnswerReportFormExcelService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AnswerReportFormExcelService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AnswerReportFormExcelService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialUserPhoneCheckState: ServiceState<UserPhoneCheckResponse> = {};

export const UserPhoneCheckService = createAsyncThunk(
  "/admin/userPhoneCheck",
  async (request: UserPhoneCheckRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<UserPhoneCheckResponse>(
          `/admin/user/find?countryCode=${request.countryCode}&phoneNumber=${request.phoneNumber}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const UserPhoneCheckSlice = createSlice({
  name: "/admin/userPhoneCheck",
  initialState: initialUserPhoneCheckState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserPhoneCheckService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(UserPhoneCheckService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(UserPhoneCheckService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialGetFormTaskList: ServiceState<GetFormTaskListResponse> = {};

export const GetFormTaskListService = createAsyncThunk(
  "/form/formtasks",
  async () => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetFormTaskListResponse>(
          `/form/formtasks`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetFormTaskListSlice = createSlice({
  name: "/formform/formtasks",
  initialState: initialGetFormTaskList,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetFormTaskListService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(GetFormTaskListService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(GetFormTaskListService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialDetailFormTask: ServiceState<DetailFormTaskResponse> = {};

export const DetailFormTaskService = createAsyncThunk(
  "/form/formtask/detail",
  async (request: DetailFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<DetailFormTaskResponse>(
          `/form/formtask?FormTaskId=${request.formTaskId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const DetailFormTaskSlice = createSlice({
  name: "/formform/formtasks",
  initialState: initialDetailFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DetailFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DetailFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DetailFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialCreateFormTask: ServiceState<CreateFormTaskResponse> = {};

export const CreateFormTaskService = createAsyncThunk(
  "/form/formtask/Create",
  async (request: CreateFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<CreateFormTaskResponse>(
          `/form/formtask`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateFormTaskSlice = createSlice({
  name: "/form/formtask/Create",
  initialState: initialCreateFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CreateFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CreateFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialUpdateFormTask: ServiceState<UpdateFormTaskResponse> = {};

export const UpdateFormTaskService = createAsyncThunk(
  "/form/formtask/update",
  async (request: UpdateFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<UpdateFormTaskResponse>(
          `/form/formtask`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateFormTaskSlice = createSlice({
  name: "/form/formtask/update",
  initialState: initialUpdateFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(UpdateFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(UpdateFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialDeleteFormTask: ServiceState<DeleteFormTaskResponse> = {};

export const DeleteFormTaskService = createAsyncThunk(
  "/form/formtask/delete",
  async (request: DeleteFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().delete<DeleteFormTaskResponse>(
          `/form/formtask?FormTaskId=${request.formTaskId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteFormTaskSlice = createSlice({
  name: "/form/formtask/delete",
  initialState: initialDeleteFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DeleteFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DeleteFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAssingForm: ServiceState<AssignFormDetailResponse> = {};

export const AssingFormService = createAsyncThunk(
  "/form/assign/detail",
  async (id: string) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<AssignFormDetailResponse>(
          `/form/form/assign/1234567?id=${id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AssingFormDetailSlice = createSlice({
  name: "/form/assign/detail",
  initialState: initialAssingForm,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssingFormService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AssingFormService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AssingFormService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialFormTaskLocationChecking: ServiceState<FormTaskLocationCheckingResponse> =
  {};

export const FormTaskLocationCheckingService = createAsyncThunk(
  "/form/formtask/locationcheck",
  async (request: FormTaskLocationCheckingRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<FormTaskLocationCheckingResponse>(
          `/form/formtask/locationcheck`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const FormTaskLocationCheckingSlice = createSlice({
  name: "/form/formtask/locationcheck",
  initialState: initialFormTaskLocationChecking,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FormTaskLocationCheckingService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(FormTaskLocationCheckingService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(FormTaskLocationCheckingService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialCompleteFormTaskCompleteFormTask: ServiceState<CompleteFormTaskResponse> =
  {};

export const CompleteFormTaskService = createAsyncThunk(
  "/form/formtask/complete",
  async (request: CompleteFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<CompleteFormTaskResponse>(
          `/form/formtask/complete`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CompleteFormTaskSlice = createSlice({
  name: "/form/formtask/complete",
  initialState: initialCompleteFormTaskCompleteFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CompleteFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CompleteFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CompleteFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialCompleteStateFormTask: ServiceState<CompleteStateFormTaskResponse> =
  {};

export const CompleteStateFormTaskService = createAsyncThunk(
  "/form/formtask/statecomplete",
  async (request: CompleteStateFormTaskRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<CompleteStateFormTaskResponse>(
          `/form/formtask/statecomplete`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CompleteStateFormTaskSlice = createSlice({
  name: "/form/formtask/statecomplete",
  initialState: initialCompleteStateFormTask,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CompleteStateFormTaskService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CompleteStateFormTaskService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CompleteStateFormTaskService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialDeleteFormFieldEmployeeImage: ServiceState<DeleteFormFieldEmployeeImageResponse> =
  {};

export const DeleteFormFieldEmployeeImageService = createAsyncThunk(
  "formtask/formTaskImage",
  async (request: DeleteFormFieldEmployeeImageRequest) => {
    try {
      //
      const response =
        await AxiosHelper.getInstance().delete<DeleteFormFieldEmployeeImageResponse>(
          `/form/formtask/formTaskImage?FormFieldEmployeeImageId=${request.formFieldEmployeeImageId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteFormFieldEmployeeImageSlice = createSlice({
  name: "formtask/formTaskImage",
  initialState: initialDeleteFormFieldEmployeeImage,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteFormFieldEmployeeImageService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        DeleteFormFieldEmployeeImageService.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.value = action.payload;
        }
      )
      .addCase(
        DeleteFormFieldEmployeeImageService.rejected,
        (state, action) => {
          state.isLoading = false;
          // TODO : error
        }
      );
  },
});

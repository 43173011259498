import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import EmployeeInteractor from "../interactor/EmployeeInteractor";
import FieldInteractor from "../../myfields/interactor/MyFieldInteractor";
import UpdateEmployeeInteractor from "../interactor/UpdateEmployeeInteractor";
import DeleteEmployeeInteractor from "../interactor/DeleteEmployeeInteractor";
import AddEmployeeInteractor from "../interactor/AddEmployeeInteractor";
const rootReducer = combineReducers({
  employeeState: EmployeeInteractor,
  fieldState: FieldInteractor,
  updateState: UpdateEmployeeInteractor,
  deleteState: DeleteEmployeeInteractor,
  addEmployeeState: AddEmployeeInteractor,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;

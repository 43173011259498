export class ServiceState<T> {
  value?: T;
  isLoading?: boolean;
  error?: ServiceError;
}

export class ServiceError {
  message?: string;
  userMessage?: string;
  code?: number;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ServiceState } from "../../common/data/ServiceState";
import AxiosHelper from "../../common/util/AxiosHelper";
import { DistrictRequest } from "../data/request/DistrictRequest";
import { DistrictResponse } from "../data/response/DistrictResponse";

export const DistrictService = createAsyncThunk(
  "districts/getDistricts",
  async (district: DistrictRequest) => {
    try {
      const response = await AxiosHelper.getInstance().get<DistrictResponse>(
        `parameter/districts?CityId=${district.CityId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const initialState: ServiceState<DistrictResponse> = {};

const DistrictsSlice = createSlice({
  name: "districts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DistrictService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DistrictService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DistrictService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO: Handle the error
      });
  },
});

export default DistrictsSlice.reducer;

import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  TextInput,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../connector/ModuleHook";
import { RootState } from "./../../connector/ModuleStore";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import {
  IconArrowLeft,
  IconList,
  IconSearch,
  IconSortDescending,
} from "@tabler/icons-react";
import { AssignFormListService } from "../../interractor/SurveyInterractor";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import { EmployeeService } from "../../../users/interactor/EmployeeInteractor";
import { FormAssingDto } from "../../data/dto/FormAssingDto";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { useNavigate, useParams } from "react-router-dom";
import { employeeAuthorityType } from "../../data/dto/AuthorityType";
import { formFieldEmployeeState } from "../../data/dto/FormFieldEmployeeState";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);

interface DetailMySurveysProps {}

const DetailMySurveys: React.FC<DetailMySurveysProps> = ({}) => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const navigate = useNavigate();

  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "createdDate",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [fetching, setFetching] = useState(true);

  const [nameQuery, setNameQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
  const [fieldNameQuery, setFieldNameQuery] = useState("");
  const [debouncedFieldNameQuery] = useDebouncedValue(fieldNameQuery, 200);
  const [employeeNameQuery, setEmployeeQuery] = useState("");
  const [debouncedEmployeeQuery] = useDebouncedValue(employeeNameQuery, 200);
  const [employeeAuthorityTypeQuery, setEmployeeAuthorityTypeNameQuery] =
    useState("");
  const [debouncedEmployeeAuthorityType] = useDebouncedValue(
    employeeAuthorityTypeQuery,
    200
  );
  const [formFieldEmployeeStateQuery, setFormFieldEmployeeStateQuery] =
    useState("");
  const [debouncedEFormFieldEmployeeStateQuery] = useDebouncedValue(
    formFieldEmployeeStateQuery,
    200
  );

  const [createdDateQuery, setCreatedDateQuery] = useState("");
  const [debouncedCreatedDateQuery] = useDebouncedValue(createdDateQuery, 200);

  const [formTaskStateQuery, setFormTaskStateQuery] = useState("");
  const [debouncedFormTaskStateQuery] = useDebouncedValue(
    formTaskStateQuery,
    200
  );

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    setFetching(true);
    if (id) {
      dispatch(MyFieldService());
      dispatch(EmployeeService());
      dispatch(
        AssignFormListService({
          formTaskId: id,
        })
      );
    }
  }, [id]);

  const employeeService = useSelector(
    (state: RootState) => state.EmployeeState.value
  );

  const employeeData = employeeService?.data?.employees || [];

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const assignFormListService = useSelector(
    (state: RootState) => state.AssignFormListState.value
  );
  const assignFormListData = assignFormListService?.data?.assingDtos;

  useEffect(() => {
    if (assignFormListData && employeeData && myFieldData) {
      let assignList = assignFormListData.map((item: FormAssingDto) => {
        let employee = employeeData.find(
          (employee: any) => employee.id === item.employeeId
        );
        return {
          id: item.id,
          name: item.formName,
          fieldName: item.fieldId
            ? myFieldData.find((field) => field.id === item.fieldId)?.fieldName
            : "-",
          employee:
            (localStorage.getItem("authorityType") ?? "") != "1"
              ? localStorage.getItem("employeeName") ?? ""
              : employee
              ? `${employee?.firstName} ${employee?.lastName}`
              : "-",
          employeeAuthorityType: employeeAuthorityType.find(
            (authorityType) =>
              authorityType.value === item.employeeAuthorityType
          )?.text,
          formFieldEmployeeState: formFieldEmployeeState.find(
            (employeeState) =>
              employeeState.value === item.formFieldEmployeeState
          )?.text,
          taskFieldEmployeeState: formFieldEmployeeState.find(
            (employeeState) =>
              employeeState.value === item.taskFieldEmployeeState
          )?.text,
          createdDate: item.createdDate,
        };
      });
      setPageData(assignList);
      setInitialRecords(assignList);
      setFetching(false);
    }
  }, [assignFormListData, employeeData, myFieldData]);

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({
          name,
          fieldName,
          employee,
          employeeAuthorityType,
          taskFieldEmployeeState,
          createdDate,
        }) => {
          if (
            debouncedNameQuery !== "" &&
            nameQuery &&
            !`${name}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedFieldNameQuery !== "" &&
            fieldNameQuery &&
            !`${fieldName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFieldNameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedEmployeeQuery !== "" &&
            employeeNameQuery &&
            !`${employee}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedEmployeeQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedEmployeeAuthorityType !== "" &&
            employeeAuthorityTypeQuery &&
            !`${employeeAuthorityType}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedEmployeeAuthorityType.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedEFormFieldEmployeeStateQuery !== "" &&
            formFieldEmployeeStateQuery &&
            !`${taskFieldEmployeeState}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(
                debouncedEFormFieldEmployeeStateQuery.trim().toLowerCase()
              )
          ) {
            return false;
          }
          if (
            debouncedCreatedDateQuery !== "" &&
            createdDateQuery &&
            moment(createdDate).format("DD.MM.YYYY") !==
              moment(debouncedCreatedDateQuery).format("DD.MM.YYYY")
          ) {
            return false;
          }

          return true;
        }
      );
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "fieldName") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldName ? a.fieldName.toLowerCase() : "";
          const valueB = b.fieldName ? b.fieldName.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "employee") {
        sortedData.sort((a, b) => {
          const valueA = a.employee ? a.employee.toLowerCase() : "";
          const valueB = b.employee ? b.employee.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "employeeAuthorityType") {
        sortedData.sort((a, b) => {
          const valueA = a.employeeAuthorityType
            ? a.employeeAuthorityType.toLowerCase()
            : "";
          const valueB = b.employeeAuthorityType
            ? b.employeeAuthorityType.toLowerCase()
            : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "taskFieldEmployeeState") {
        sortedData.sort((a, b) => {
          const valueA = a.taskFieldEmployeeState
            ? a.taskFieldEmployeeState.toLowerCase()
            : "";
          const valueB = b.taskFieldEmployeeState
            ? b.taskFieldEmployeeState.toLowerCase()
            : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
        // } else if (sortStatus.columnAccessor === "formFieldEmployeeState") {
        //   sortedData.sort((a, b) => {
        //     const dateA = a.createdDate ? moment(a.createdDate) : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
        //     const dateB = b.createdDate ? moment(b.createdDate) : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

        //     return sortStatus.direction === "asc"
        //       ? dateA.diff(dateB)
        //       : dateB.diff(dateA);
        //   });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus]);

  const columns = [
    // {
    //   accessor: "name",
    //   title: "Form Adı",
    //   textAlignment: "center" as DataTableColumnTextAlignment,
    //   sortable: true,
    //   ellipsis: true,
    //   filter: (
    //     <TextInput
    //       label="İsim"
    //       description="Belirtilen isimdeki formları ara."
    //       placeholder="Form adı ara..."
    //       icon={<IconSearch size={16} />}
    //       value={nameQuery}
    //       onChange={(e) => setNameQuery(e.currentTarget.value)}
    //     />
    //   ),
    //   filtering: nameQuery !== "",
    // },
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen açıklamayı içeren tarlaları ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={fieldNameQuery}
          onChange={(e) => setFieldNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldNameQuery !== "",
    },
    {
      accessor: "employee",
      title: "Çalışan",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="İsim"
          description="Çalışan içeren formları ara."
          placeholder="Çalışan adı ara..."
          icon={<IconSearch size={16} />}
          value={employeeNameQuery}
          onChange={(e) => setEmployeeQuery(e.currentTarget.value)}
        />
      ),
      filtering: employeeNameQuery !== "",
    },

    {
      accessor: "employeeAuthorityType",
      title: "Yetki Tipi",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Yetki Tipi"
          description="Yetki tipi içeren formları ara."
          placeholder="Yetki tipi ara..."
          icon={<IconSearch size={16} />}
          value={employeeAuthorityTypeQuery}
          onChange={(e) =>
            setEmployeeAuthorityTypeNameQuery(e.currentTarget.value)
          }
        />
      ),
      filtering: employeeAuthorityTypeQuery !== "",
    },
    {
      accessor: "taskFieldEmployeeState",
      title: "Durumu",
      textAlignment: "center" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Durummu"
          description="Durumu formları ara."
          placeholder="Durumu ile ara..."
          icon={<IconSearch size={16} />}
          value={formFieldEmployeeStateQuery}
          onChange={(e) =>
            setFormFieldEmployeeStateQuery(e.currentTarget.value)
          }
        />
      ),
      filtering: formFieldEmployeeStateQuery !== "",
    },
    // {
    //   accessor: "createdDate",
    //   title: "Atama Tarihi",
    //   textAlignment: "center" as DataTableColumnTextAlignment,
    //   sortable: true,
    //   ellipsis: true,
    //   render: ({ fieldDate }: { fieldDate: Date }) => {
    //     const localDate = moment(fieldDate).add(3, "hours");
    //     return localDate.format("DD.MM.YYYY HH:mm");
    //   },
    //   filter: ({ close }: { close: any }) => (
    //     <Stack>
    //       <DatePicker
    //         maxDate={new Date()}
    //         value={
    //           createdDateQuery == ""
    //             ? null
    //             : moment(createdDateQuery, "DD.MM.YYYY").toDate()
    //         }
    //         onChange={(date) =>
    //           setCreatedDateQuery(moment(date).format("DD.MM.YYYY"))
    //         }
    //       />
    //       <Button
    //         disabled={createdDateQuery == ""}
    //         color="red"
    //         onClick={() => {
    //           setCreatedDateQuery("");
    //           close();
    //         }}
    //       >
    //         Reset
    //       </Button>
    //     </Stack>
    //   ),
    //   filtering: createdDateQuery !== "",
    // },
    {
      accessor: "isRead",
      title: "Aksiyon",
      textAlignment: "center" as DataTableColumnTextAlignment,

      render: (record: any, index: number) => (
        <Group spacing={4} position="center" noWrap>
          {true && (
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              // disabled={
              //   record.formFieldEmployeeState !== "Aktif" &&
              //   record.formFieldEmployeeState !== "Devam Ediyor"
              // }
              style={{
                color: "#FFF",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "100%",
                fontWeight: 300,
                backgroundColor: "#7169d2",
              }}
              onClick={() => {
                navigate("/mytask/detail/" + record.id);
              }}
            >
              <IconList /> Detay
            </Button>
          )}
        </Group>
      ),
    },
  ];

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[
          { title: "Görevlerim", href: "/mytask/list" },
          { title: "Görev Listesi", href: "#" },
        ]}
        title="Görevlerim"
      >
        <>
          <Box
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#FFF",
                borderColor: "#000000",
                borderRadius: "2rem",
                fontWeight: 300,
                width: "100px",
                backgroundColor: "red",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconArrowLeft /> Geri
            </Button>
          </Box>
          <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
            <Suspense fallback={<CustomLoadingOverlay />}>
              <Paper p="xl" className="profile-paper" pos={"relative"}>
                {/* <LoadingOverlay
                visible={fetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              /> */}

                <Space h="lg" />
                <Grid>
                  <Grid.Col sm={12} md={12} style={{ marginTop: "20px" }}>
                    <DataTable
                      withColumnBorders
                      highlightOnHover
                      minHeight={250}
                      noRecordsText="Form Bulunamadı..."
                      className="datatable"
                      records={pageData}
                      columns={columns}
                      totalRecords={sortedData.length}
                      recordsPerPage={PAGE_SIZE}
                      page={page}
                      onPageChange={(p) => setPage(p)}
                      fetching={fetching}
                      loaderColor="#0097c4"
                      sortStatus={sortStatus}
                      onSortStatusChange={handleSortStatusChange}
                      sortIcons={{
                        sorted: <IconSortDescending size={14} />,
                        unsorted: <IconSortDescending size={14} />,
                      }}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
            </Suspense>
          </Paper>
        </>
      </MainWrapper>
    </Suspense>
  );
};

export default DetailMySurveys;
